import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { DealsController } from '../../controllers'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { AppState } from '../../store'
import { showConfirmModal, showDealModal } from '../../store/modals/actions'
import { CurrentUser, Deal, ResourceListFilterType } from '../../types'
import Notification from '../../utilities/Notification'
import ButtonPanel from '../Button/ButtonPanel'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import ResourceTable, { ResourceTableAction } from '../Resource/ResourceTable'
import ResourceTableRow from '../Resource/ResourceTableRow'
import ResourceTableRowActions from '../Resource/ResourceTableRowActions'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import AvatarStack from '../Avatar/AvatarStack'
import Avatar from '../Avatar/Avatar'
import ReactTooltip from 'react-tooltip'
import DealHelper from '../../helpers/DealHelper'

interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showDealModal: typeof showDealModal
	showConfirmModal: typeof showConfirmModal
}

type IProps = {
	contactId?: string
	projectId?: string
	primaryActionEnabled?: boolean
} & WithTranslation & IStateToProps & IDispatchToProps & RouteComponentProps

interface IState {
	deals: Deal[],
	selectedDealIds: string[]
	currentPage: number,
	totalpages: number
	didInitialLoad: boolean
	isFetching: boolean
	sortValue: string
	tableFilter: any
	searchValue: string
}

class DealsTable extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			deals: [],
			selectedDealIds: [],
			currentPage: 0,
			totalpages: 0,
			didInitialLoad: false,
			isFetching: false,
			sortValue: LocalStorage.get(LocalStorageKey.DEAL_SORT_VALUE, '-'),
			tableFilter: {},
			searchValue: '',
		}

		this.onNewDealClick = this.onNewDealClick.bind(this)
		this.onResourceBulkDeleteActionClick = this.onResourceBulkDeleteActionClick.bind(this)
		this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
		this.onTableRowDealClick = this.onTableRowDealClick.bind(this)
		this.onTableShowMoreClick = this.onTableShowMoreClick.bind(this)
		this.onTableActionClick = this.onTableActionClick.bind(this)
		this.onTableDealUpdateClick = this.onTableDealUpdateClick.bind(this)
		this.onTableDealDeleteClick = this.onTableDealDeleteClick.bind(this)
		this.onDealFormSubmit = this.onDealFormSubmit.bind(this)
		this.onDealUpdateFormSubmit = this.onDealUpdateFormSubmit.bind(this)
		this.onDealFiltersChange = this.onDealFiltersChange.bind(this)
		this.onDealSortValueChange = this.onDealSortValueChange.bind(this)
		this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
		this.onDealSearchChange = this.onDealSearchChange.bind(this)
		this.onDealSearchSubmit = this.onDealSearchSubmit.bind(this)
		this.onDealClearFilters = this.onDealClearFilters.bind(this)
	}

	fetchDeals(page: number) {
		const { contactId, projectId } = this.props
		const { searchValue, sortValue, tableFilter: tableFilters } = this.state

		let parameters: any = {
			page: page,
			search: searchValue,
			order: `${sortValue}`,
			...tableFilters
		}

		if (contactId) {
			parameters = {
				...parameters,
				contact_id: contactId,
			}
		}

		if (projectId) {
			parameters = {
				...parameters,
				project_id: projectId,
			}
		}

		this.setState({
			isFetching: true
		}, async () => {
			try {
				const response = await DealsController.getDeals(parameters)
				const { deals, current_page, total_pages, total_entries } = response;

				this.setState({
					deals: [...deals],
					currentPage: current_page,
					totalpages: total_pages,
					didInitialLoad: true,
					isFetching: false
				});
			} catch (ex) {
				console.error(ex)
			}
		})
	}

	componentDidMount(): void {
		this.fetchDeals(1)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		ReactTooltip.rebuild()
	}

	onNewDealClick() {
		const { contactId, projectId } = this.props

		const { showDealModal } = this.props

		showDealModal({
			deal: {
				contact_id: contactId,
				project_id: projectId,
			},
			contactDisabled: Boolean(contactId),
			projectDisabled: Boolean(projectId),
			onSubmit: this.onDealFormSubmit,
		})
	}

	onDealFormSubmit(deal: Deal) {
		const { deals } = this.state;

		const newDeals = [deal, ...deals];

		this.setState({ deals: newDeals });
	}

	onDealUpdateFormSubmit(deal: Deal) {
		const { deals } = this.state

		const dealIndex = deals.findIndex(c => c.id === deal.id);

		if (dealIndex !== -1) {
			deals[dealIndex] = deal
		}

		this.setState({
			deals: [
				...deals,
			]
		})
	}

	onResourceBulkDeleteActionClick() {
		const { t } = this.props
		const { selectedDealIds, deals } = this.state

		this.props.showConfirmModal({
			title: t('Deals::Bulk deal deletion'),
			description: t('Deals::You are about to delete these deals. By deleting these deals you are also deleting all its associated data. Are you sure?'),
			action: { label: t('Deals::Delete'), isDestructive: true },
			onConfirm: async () => {
				try {
					const response = await DealsController.deleteAll(selectedDealIds)
					if (response.errors) { }
					else {
						const newDeals = deals.filter(p => !selectedDealIds.includes(p.id));

						this.setState({ deals: [...newDeals], selectedDealIds: [] });

						Notification.notifySuccess(t('Deals::Deals successfully deleted'))
					}

				} catch (ex) {
					console.error(ex)
				}
			}
		})
	}

	onTableRowDealClick(deal: Deal) {
		this.props.history.push(RouteHelper.process(ERoute.PATH_DEAL, { id: deal.id }))
	}

	onTableRowSelectionChange(selected: boolean, dealId: string) {
		const { selectedDealIds } = this.state

		if (selected) {
			this.setState({ selectedDealIds: [...selectedDealIds, dealId] })
		} else {
			this.setState({ selectedDealIds: selectedDealIds.filter(selectedDealId => selectedDealId !== dealId) })
		}
	}

	onTableShowMoreClick() {
		const { currentPage } = this.state;

		this.fetchDeals(currentPage + 1);
	}

	onTableSelectionChange(selectedDealIds: string[]) {
		this.setState({ selectedDealIds: selectedDealIds })
	}

	onDealSearchChange(searchValue) {
		this.setState({ searchValue: searchValue })
	}

	onDealSearchSubmit(searchValue) {
		this.setState({ searchValue: searchValue }, () => this.fetchDeals(1))
	}

	onDealClearFilters() {
		this.setState({
			searchValue: '',
			tableFilter: {}
		}, () => this.fetchDeals(1))
	}

	onTableActionClick(key: string, deal: Deal) {
		switch (key) {
			case 'update': this.onTableDealUpdateClick(deal)
				break
			case 'delete': this.onTableDealDeleteClick(deal)
				break
			default:
				throw Error('[Deals] Unimplemented onTableActionClick')
		}
	}

	onTableDealUpdateClick(deal: Deal) {
		const { showDealModal } = this.props

		showDealModal({
			deal: { id: deal.id },
			onSubmit: this.onDealUpdateFormSubmit,
		})
	}

	onTableDealDeleteClick(deal: Deal) {
		const { showConfirmModal, t } = this.props

		showConfirmModal({
			title: t('Deals::Delete deal'),
			description: t('Deals::You are about to delete this deal. By deleting this deal you are also deleting all its associated data. Are you sure?'),
			action: { label: t('Deals::Delete'), isDestructive: true },
			onConfirm: async () => {
				try {
					const response = await DealsController.delete(deal.id)
					if (response.errors) {

					} else {
						const { deals } = this.state;

						const dealIndex = deals.findIndex(c => c.id === deal.id);

						deals.splice(dealIndex, 1);

						this.setState({
							deals: deals
						});

						Notification.notifySuccess(t('Deals::Deal successfully deleted'))
					}
				} catch (ex) {
					console.error(ex)
				}
			}
		})
	}

	onDealFiltersChange(tableFilters: any) {
		this.setState({ tableFilter: tableFilters }, () => {
			this.fetchDeals(1)
		})
	}

	onDealSortValueChange(value: string) {
		LocalStorage.set(LocalStorageKey.DEAL_SORT_VALUE, value)
		this.setState({
			sortValue: value
		}, () => {
			this.fetchDeals(1)
		})
	}

	render() {
		const { t, contactId, primaryActionEnabled } = this.props
		const { deals, selectedDealIds, isFetching, tableFilter: tableFilters, sortValue, searchValue, currentPage, totalpages } = this.state

		const tableFiltersActive = searchValue?.length > 0 || Object.keys(tableFilters).length > 0

		const promotedBulkActions: ResourceTableAction[] = [
			{ icon: 'trash-alt-solid', content: t('Deals::Delete'), onAction: this.onResourceBulkDeleteActionClick }
		]

		return (
			<ResourceTable
				data={deals}
				actionsLeft={primaryActionEnabled ? [
					<ButtonPanel
						icon='plus'
						text={t('DealsTable::New deal')}
						onClick={this.onNewDealClick}
					/>
				] : null}
				headers={[
					{ title: t('Deals::Name'), },
					{ title: t('Deals::Contact') },
					{ title: t('Deals::Assignee') },
					{ title: t('Deals::Stage'), align: 'right' },
					{ title: '', stickyRight: '0px' },
				]}
				renderRow={(deal: Deal) => {
					return (
						<ResourceTableRow
							key={deal.id}
							selected={selectedDealIds.includes(deal.id)}
							onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, deal.id)}
						>
							<ResourceTableRowData onClick={() => this.onTableRowDealClick(deal)} maxWidth='150px' ellipse>
								{deal.name}
							</ResourceTableRowData>

							<ResourceTableRowData onClick={() => this.onTableRowDealClick(deal)} maxWidth='150px' ellipse>
								{deal?.contact ? deal.contact.name : '-'}
							</ResourceTableRowData>

							<ResourceTableRowData onClick={() => this.onTableRowDealClick(deal)} textAlign='right'>
								{deal.assignee && <AvatarStack width={30}>
									<Avatar name={deal?.assignee?.name} width={30} rounded />
								</AvatarStack>}
							</ResourceTableRowData>

							<ResourceTableRowData onClick={() => this.onTableRowDealClick(deal)} textAlign='right'>
								{deal?.deal_stage?.name}
							</ResourceTableRowData>
							<ResourceTableRowActions
								actions={[
									{ key: 'update', icon: 'edit-solid', content: t('Deals::Edit') },
									{ key: 'delete', icon: 'trash-alt-solid', content: t('Deals::Delete'), destructive: true }
								]}
								onActionClick={(key) => this.onTableActionClick(key, deal)}
								sticky={true}
								stickyRight='0px'
							/>
						</ResourceTableRow>
					)
				}}
				renderEmpty={<CardEmptyInfo
					icon={tableFiltersActive ? 'search' : 'check-circle'}
					description={tableFiltersActive ? t('Deals::No deals found') : t('Deals::No deals have been created yet')}
					descriptionActionText={tableFiltersActive ? t('Deals::Clear filters') : t('Deals::Add new deal')}
					onDescriptionActionClick={tableFiltersActive ? () => this.onDealFiltersChange({}) : this.onNewDealClick}
				/>}
				filters={[
					{ name: 'name', label: t('Deals::Name'), type: ResourceListFilterType.STRING },
					{ name: 'status', label: t('Deals::Status'), type: ResourceListFilterType.SINGLE_OPTION, options: DealHelper.getStatusOptions() },
					{ name: 'contact_id', label: t('Deals::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
					{ name: 'project_id', label: t('Deals::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(contactId) },
					{ name: 'deal_stage_id', label: t('Deals::Stage'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'deal_stage', isValidNewOption: () => false },
					{ name: 'amount', label: t('Deals::Amount'), type: ResourceListFilterType.NUMBER },
					{ name: 'origin', label: t('Deals::Origin'), type: ResourceListFilterType.STRING },
					{ name: 'due_on', label: t('Deals::Due on'), type: ResourceListFilterType.DATE },
					{ name: 'created_at', label: t('Deals::Created date'), type: ResourceListFilterType.DATE },
				]}
				onFiltersChange={this.onDealFiltersChange}
				sortOptions={[
					{ label: '-', value: '-' },
					{ label: t('Deals::Name (A-Z)'), value: 'name_asc' },
					{ label: t('Deals::Name (Z-A)'), value: 'name_desc' },
					{ label: t('Deals::Created at ↑'), value: 'created_at_asc' },
					{ label: t('Deals::Created at ↓'), value: 'created_at_desc' },
				]}
				promotedBulkActions={promotedBulkActions}
				sortValue={sortValue}
				onSortChange={this.onDealSortValueChange}
				pagination={{ page: currentPage, pageCount: totalpages }}
				onPageChange={(page) => this.fetchDeals(page)}
				isLoading={isFetching}
				stickyHeader={true}
				selectedItems={selectedDealIds}
				onSelectionChange={this.onTableSelectionChange}
				searchValue={searchValue}
				onSearchChange={this.onDealSearchChange}
				onSearchSubmit={this.onDealSearchSubmit}
				maxHeight='65vh'
			/>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showDealModal,
	showConfirmModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(DealsTable)))