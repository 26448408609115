import Api from "./Api";
import Utils from "../utilities/Utils";
import { BlobResponse, Product, SpreadsheetExportType } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class ProductsController extends Api {
  static getProducts(params: any) {
    return fetch(`/api/products?${SearchHelper.getQueryString(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static get(id: string) {
    return fetch(`/api/products/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(product: Product) {
    return fetch(`/api/products`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ product: product }),
    }).then((res) => res.json());
  }

  static update(product: Product) {
    return fetch(`/api/products/${product.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ product: product }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/products/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/products/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static import(blobId: string) {
    return fetch(`/api/products/import`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ id: blobId }),
    }).then((res) => res.json());
  }

  static export(queryData: {
    spreadsheet_type: SpreadsheetExportType;
  }): Promise<BlobResponse> {
    return fetch(`/api/products/export?${Utils.encodeQueryData(queryData)}`, {
      method: "GET",
      headers: {
        Authorization: this.token,
      },
    }).then((res) => {
      return new Promise((resolve, reject) => {
        res.blob().then((blob) => {
          resolve({
            filename: res.headers
              .get("content-disposition")
              .match(/filename="(.+)"/)[1],
            blob: blob,
          });
        });
      });
    });
  }

  static bulkDelete(productIds: string[]) {
    return fetch(`/api/products/bulk_delete`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ product_ids: productIds }),
    }).then((res) => res.json());
  }
}
