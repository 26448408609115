import Api from "./Api";
import { Activity } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class ActivitiesController extends Api {
  static getActivities(params: object) {
    return fetch(`/api/activities?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(activity: Activity) {
    return fetch("/api/activities/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ activity: activity }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/activities/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
