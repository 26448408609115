import Api from "./Api";
import Utils from "../utilities/Utils";
import { Payment } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class PaymentsController extends Api {
  static getPayment(id: string) {
    return fetch(`/api/payments/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getPayments(params: object) {
    return fetch(`/api/payments?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(payment: Payment) {
    return fetch("/api/payments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ payment: payment }),
    }).then((res) => res.json());
  }

  static update(payment: Payment) {
    return fetch(`/api/payments/${payment.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ payment: payment }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/payments/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static deleteAll(paymentIds: string[]) {
    return fetch(`/api/payments/destroy_all`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ payment_ids: paymentIds }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/payments/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getSummary(): Promise<{
    draft_amount: number;
    unsigned_amount: number;
    month_amount: number;
  }> {
    return fetch(`/api/payments/summary`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
