import * as React from 'react'
import Datetime from 'react-datetime'
import styled from 'styled-components'
import { Style } from '../../styles'
import { Moment } from 'moment'
import moment from '../../utilities/Moment'
import { withTranslation, WithTranslation } from 'react-i18next'
import { t } from 'i18next'

type IProps = {
	name: string
	onChange: (date: any) => void
	defaultValue?: any
	ref?: React.RefObject<DateInput>
	shortcutsEnabled?: boolean
	minWidth?: string
} & WithTranslation & Datetime.DatetimepickerProps


const Container = styled.div<{ minWidth?: string }>`
	position: relative;
	min-width: 150px;

	.rdtPicker {
		min-width: 300px;
	}
`

const InputContainer = styled.div`
	display: flex;
	position: absolute;
	top: 50%;
	right: 10px;
	text-align: center;
	border-radius: 0.4em;
	transform: translateY(-50%);
	cursor: pointer;

	i, svg {
		color: ${Style.color.brandWarning};
		width: 20px;
		height: 20px;
		fill: currentColor;

		path {
			fill: currentColor;
		}

		text {
			fill: #222;
			font-size: 10px;
			text-anchor: middle;
		}
	}

	.is-disabled & {
		pointer-events: none;
		color: #ccc;
		border-color: #ccc;
	}
`

const InputWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	border: 2px solid ${Style.color.brandWarning};
	border-radius: 4px;
	font-size: 10px;
	border-top-width: 6px;
`

const CalendarHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	border-top: 1px solid #f9f9f9;
`

const CalendarButton = styled.div`
	margin-right: 4px;
	margin-top: 4px;
	border: 0;
	background-color: #eaebec;
	border-radius: 10px;
	padding: 6px 9px;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	width: fit-content;

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		background-color: ${Style.color.brandPrimary};
		color: ${Style.color.white};
	}
`

class DateInput extends React.PureComponent<IProps> {
	public datetime = React.createRef<Datetime>()
	public input = React.createRef<HTMLInputElement>()

	constructor(props: IProps) {
		super(props)

		this.onTodayClick = this.onTodayClick.bind(this)
		this.onTomorrowClick = this.onTomorrowClick.bind(this)
		this.onNextWeekClick = this.onNextWeekClick.bind(this)
		this.onNextMonthClick = this.onNextMonthClick.bind(this)
		this.onEndOfMonthClick = this.onEndOfMonthClick.bind(this)
		this.onCalendarIconClick = this.onCalendarIconClick.bind(this)
		this.setSelectedDate = this.setSelectedDate.bind(this)
		this.renderView = this.renderView.bind(this)
	}

	onTodayClick() {
		const now = moment()
		this.setSelectedDate(now)
	}

	onTomorrowClick() {
		const tomorrow = moment().add(1, 'day')
		this.setSelectedDate(tomorrow)
	}

	onNextWeekClick() {
		const nextWeek = moment().add(7, 'days')
		this.setSelectedDate(nextWeek)
	}

	onNextMonthClick() {
		const nextMonth = moment().add(1, 'month')
		this.setSelectedDate(nextMonth)
	}

	onEndOfMonthClick() {
		const endOfMonth = moment().endOf('month')
		this.setSelectedDate(endOfMonth)
	}

	setSelectedDate(date: Moment) {
		const { closeOnSelect, onChange } = this.props
		if (this.datetime.current) {
			this.datetime.current.setState({ selectedDate: date, open: !closeOnSelect })
			onChange(date)
		}
	}

	onCalendarIconClick() {
		if (this.datetime.current) {
			this.datetime.current.setState({ open: true })
		}
	}

	renderView(viewMode: string, renderDefault: Function) {
		const { t, shortcutsEnabled = true } = this.props

		// Only for years, months and days view
		if (viewMode === 'time') return renderDefault();

		return (
			<Container>
				{renderDefault()}
				{shortcutsEnabled && <CalendarHeader>
					<CalendarButton onClick={this.onTodayClick}>
						{t('DateInput::Today')}
					</CalendarButton>
					<CalendarButton onClick={this.onTomorrowClick}>
						{t('DateInput::Tomorrow')}
					</CalendarButton>
					<CalendarButton onClick={this.onNextWeekClick}>
						{t('DateInput::Next week')}
					</CalendarButton>
					<CalendarButton onClick={this.onNextMonthClick}>
						{t('DateInput::Next month')}
					</CalendarButton>
					<CalendarButton onClick={this.onEndOfMonthClick}>
						{t('DateInput::End of month')}
					</CalendarButton>
				</CalendarHeader>}
			</Container>
		)
	}

	render() {
		const { initialValue, value, inputProps } = this.props
		let valueMoment: Moment = null

		if (value || initialValue) {
			const parsedMoment = moment(value || initialValue)

			if (parsedMoment.isValid()) {
				valueMoment = parsedMoment
			}
		}

		return (
			<Container>
				<Datetime
					{...this.props}
					ref={this.datetime}
					inputProps={{ ...(inputProps || {}), ref: this.input }}
					renderView={this.renderView}
				/>

				{!this.props.inputProps?.disabled && <InputContainer>
					<InputWrapper onClick={this.onCalendarIconClick}>
						{valueMoment ? valueMoment.format('D') : '?'}
					</InputWrapper>
				</InputContainer>}
			</Container>
		);
	}
}

export { DateInput }
export default withTranslation(null, { withRef: true })(DateInput)

