export const SHOW_NAVIGATION = "SHOW_NAVIGATION";
export const HIDE_NAVIGATION = "HIDE_NAVIGATION";
export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";
export const SET_HISTORY = "SET_HISTORY";
import { History } from "history";

export interface NavigationState {
  active: boolean;
  history: History<unknown>;
}

export interface ShowNavigationAction {
  type: typeof SHOW_NAVIGATION;
}

export interface HideNavigationAction {
  type: typeof HIDE_NAVIGATION;
}

export interface ToggleNavigationAction {
  type: typeof TOGGLE_NAVIGATION;
}

export interface SetHistoryAction {
  type: typeof SET_HISTORY;
  history: History<any>;
}

export type NavigationActionTypes =
  | ShowNavigationAction
  | HideNavigationAction
  | ToggleNavigationAction
  | SetHistoryAction;
