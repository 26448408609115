import * as React from 'react'
import * as Sentry from "@sentry/browser";
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import ResourceHelper from '../../helpers/ResourceHelper'
import { AppState } from '../../store'
import { Style } from '../../styles'
import { ResourceFilter, ResourceListFilterPredicate, ResourceListFilterType, Settings } from '../../types'
import moment from '../../utilities/Moment'
import CheckboxInput from '../Form/CheckboxInput'
import DateInput from '../Form/DateInput'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div<{ displayArrow?: boolean }>`
  background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDAxIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c3ZnanM9Imh0dHA6Ly9zdmdqcy5jb20vc3ZnanMiPjxkZWZzIGlkPSJTdmdqc0RlZnMxMDAyIj48L2RlZnM+PGcgaWQ9IlN2Z2pzRzEwMDgiIHRyYW5zZm9ybT0ibWF0cml4KDEsMCwwLDEsMCwwKSI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyODgiIGhlaWdodD0iMjg4Ij48cGF0aCBmaWxsPSIjNDM4OWZjIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjggOS42NDZhNC4wMDkgNC4wMDkgMCAwMS0zLjgtNEMwIDQuMDI0Ljk2NiAyLjYyNiAyLjM1NCAyTDQgMy42NDZhMiAyIDAgMTAwIDRoNy41Mkw4LjI5MyA0LjM1M2wxLjQxNC0xLjQxNCA1LjcwNyA1LjcwNy01LjcwNyA1LjcwNy0xLjQxNC0xLjQxNCAzLjIyNy0zLjI5M0gzLjh6IiBjbGFzcz0iY29sb3I2NjZFRTggc3ZnU2hhcGUiPjwvcGF0aD48L3N2Zz48L2c+PC9zdmc+') no-repeat;
  background-position: 5px 10px;
  background-size: 23px;
  padding-left: 28px;
  margin-top: ${Style.spacing.x1_25};
  width: 100%;

  ${props => props.displayArrow === false && css`
    background: initial;
    padding-left: 0;
  `}
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    color: #697386;
  }
`

interface IStateToProps {
  setting: Settings
}

type IProps<T> = {
  filter: ResourceFilter<T>
  value?: any
  onFilterValueChange: (name: string, value: any) => void
} & IStateToProps

const ResourceFilterInput = (props: IProps<any>) => {
  const { t } = useTranslation()
  const [predicate, setPredicate] = React.useState<ResourceListFilterPredicate | null>(null)
  const { filter, onFilterValueChange, setting, value } = props
  const { name } = filter

  React.useEffect(() => {
    if (value) {
      switch (filter.type) {
        case ResourceListFilterType.STRING:
          if (typeof value === 'object') {
            if (value.cont) {
              setPredicate(ResourceListFilterPredicate.CONTAINS)
            }
          } else if (typeof value === 'string') {
            setPredicate(ResourceListFilterPredicate.EQUALS)
          } else {
            Sentry.captureException(new Error(`[ResourceFilterInput - ${filter.type}] Unable to determine predicate from value`))
          }
          break
        case ResourceListFilterType.NUMBER:
          if (typeof value === 'object') {
            if (value.gte || value.lte) {
              setPredicate(ResourceListFilterPredicate.BETWEEN)
            } else if (value.gt) {
              setPredicate(ResourceListFilterPredicate.GREATER_THAN)
            } else if (value.lt) {
              setPredicate(ResourceListFilterPredicate.LESS_THAN)
            } else {
              Sentry.captureException(new Error(`[ResourceFilterInput - ${filter.type}] Unable to determine predicate from value`))
            }
          } else if (typeof value === 'string') {
            setPredicate(ResourceListFilterPredicate.EQUALS)
          } else {
            Sentry.captureException(new Error(`[ResourceFilterInput - ${filter.type}] Unable to determine predicate from value`))
          }
          break
        case ResourceListFilterType.DATE:
          if (typeof value === 'object') {
            if (value.gte && value.lte) {
              setPredicate(ResourceListFilterPredicate.BETWEEN)
            } else if (value.gte) {
              setPredicate(ResourceListFilterPredicate.IS_AFTER_OR_ON)
            } else if (value.lte) {
              setPredicate(ResourceListFilterPredicate.IS_BEFORE_OR_ON)
            } else if (value.gt) {
              setPredicate(ResourceListFilterPredicate.IS_AFTER)
            } else if (value.lt) {
              setPredicate(ResourceListFilterPredicate.IS_BEFORE)
            } else if (value.present) {
              setPredicate(ResourceListFilterPredicate.PRESENT)
            } else {
              Sentry.captureException(new Error(`[ResourceFilterInput - ${filter.type}] Unable to determine predicate from value`))
            }
          } else if (typeof value === 'string') {
            setPredicate(ResourceListFilterPredicate.EQUALS)
          } else {
            Sentry.captureException(new Error(`[ResourceFilterInput - ${filter.type}] Unable to determine predicate from value`))
          }
          break
      }
    } else {
      switch (filter.type) {
        case ResourceListFilterType.STRING:
          setPredicate(ResourceListFilterPredicate.CONTAINS)
          break
        case ResourceListFilterType.SINGLE_OPTION:
          break
        case ResourceListFilterType.MULTIPLE_OPTION:
          break
        case ResourceListFilterType.DATE:
          setPredicate(ResourceListFilterPredicate.EQUALS)
          break
        case ResourceListFilterType.NUMBER:
          setPredicate(ResourceListFilterPredicate.EQUALS)
          break
        case ResourceListFilterType.BOOLEAN:
          break
      }
    }
  }, [])

  const onPredicateChange = (predicate: string) => {
    const newPredicate = predicate as ResourceListFilterPredicate

    // Set new predicate
    setPredicate(newPredicate)

    let newValue = value
    const predicateAbbreviation = ResourceHelper.getPredicateAbbreviation(newPredicate)

    switch (newPredicate) {
      case ResourceListFilterPredicate.CONTAINS:
        newValue = { [predicateAbbreviation]: null }
        break
      case ResourceListFilterPredicate.EQUALS:
        newValue = ''
        break
      case ResourceListFilterPredicate.BETWEEN:
        newValue = {}
        break
      case ResourceListFilterPredicate.GREATER_THAN:
        newValue = { [predicateAbbreviation]: null }
        break
      case ResourceListFilterPredicate.LESS_THAN:
        newValue = { [predicateAbbreviation]: null }
        break
      case ResourceListFilterPredicate.IS_AFTER:
        newValue = { [predicateAbbreviation]: null }
        break
      case ResourceListFilterPredicate.IS_AFTER_OR_ON:
        newValue = { [predicateAbbreviation]: null }
        break
      case ResourceListFilterPredicate.IS_BEFORE:
        newValue = { [predicateAbbreviation]: null }
        break
      case ResourceListFilterPredicate.IS_BEFORE_OR_ON:
        newValue = { [predicateAbbreviation]: null }
        break
      case ResourceListFilterPredicate.PRESENT:
        newValue = { [predicateAbbreviation]: String(true) }
        break
      default: throw new Error('[onPredicateChange] Invalid predicate')
    }

    onFilterValueChange(name, newValue)
  }

  switch (filter.type) {
    case ResourceListFilterType.STRING:
      const stringPredicateOptions = [
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.CONTAINS}`), value: String(ResourceListFilterPredicate.CONTAINS) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.EQUALS}`), value: String(ResourceListFilterPredicate.EQUALS) },
      ]

      const selectedStringPredicateOption = stringPredicateOptions.find(predicateOption => predicateOption.value === predicate)
      const inputValue = typeof value === 'object' ? value[ResourceHelper.getPredicateAbbreviation(predicate)] : value

      return (
        <Container>
          <PowerSelect
            options={stringPredicateOptions}
            value={selectedStringPredicateOption}
            onChange={(option) => onPredicateChange(option.value)}
          />
          <InputContainer>
            <input
              type='text'
              placeholder={filter.label}
              onChange={(e) => {
                let newValue: any = e.currentTarget.value

                switch (predicate) {
                  case ResourceListFilterPredicate.CONTAINS:
                    newValue = { [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.CONTAINS)]: newValue }
                    break
                  case ResourceListFilterPredicate.EQUALS:
                    newValue = newValue
                    break
                }
                if (onFilterValueChange) onFilterValueChange(name, newValue)
              }}
              value={inputValue || ''}
            />
          </InputContainer>
        </Container>
      )
    case ResourceListFilterType.SINGLE_OPTION:
      const singleOptions = filter.options || []
      let selectedSingleOption = null

      if (value) {
        const valueOption = singleOptions.find(o => o.value === value)

        if (valueOption) {
          selectedSingleOption = valueOption
        }
      }

      return (
        <PowerSelect
          options={singleOptions}
          value={selectedSingleOption}
          placeholder={filter.label}
          theme={ReactSelectTheme}
          onChange={(option) => {
            const value = option ? option.value : []

            if (onFilterValueChange) onFilterValueChange(name, value)
          }}
        />
      )
    case ResourceListFilterType.SINGLE_RESOURCE:
      return (
        <ResourceCreatablePowerSelect
          type={filter.resourceType}
          value={value}
          placeholder={filter.label}
          isClearable={true}
          isValidNewOption={filter.isValidNewOption}
          params={filter.params}
          createParams={filter.createParams}
          onChange={(value?: string) => {
            if (onFilterValueChange) onFilterValueChange(name, value)
          }}
        />
      )
    case ResourceListFilterType.MULTI_RESOURCE:
      return (
        <ResourceCreatablePowerSelect
          type={filter.resourceType}
          value={value}
          placeholder={filter.label}
          isClearable={true}
          isValidNewOption={filter.isValidNewOption}
          params={filter.params}
          createParams={filter.createParams}
          onChange={(value?: string) => {
            if (onFilterValueChange) onFilterValueChange(name, value)
          }}
          isMulti={true}
        />
      )
    case ResourceListFilterType.MULTIPLE_OPTION:
      const multipleOptions = filter.options || []
      let selectedMultipleOption = null

      if (value) {
        const selectedValues = value.in
        const valueOption = multipleOptions.filter(o => selectedValues.includes(o.value))

        if (valueOption) {
          selectedMultipleOption = valueOption
        }
      }

      return (
        <PowerSelect
          options={multipleOptions}
          value={selectedMultipleOption}
          // @ts-ignore
          isMulti={true}
          placeholder={filter.label}
          theme={ReactSelectTheme}
          onChange={(selectedOptions) => {
            // @ts-ignore
            const value = selectedOptions ? selectedOptions.map(option => option.value) : []

            if (onFilterValueChange) onFilterValueChange(name, { in: value })
          }}
        />
      )
    case ResourceListFilterType.DATE:
      const datePredicateOptions = [
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.EQUALS}`), value: String(ResourceListFilterPredicate.EQUALS) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.BETWEEN}`), value: String(ResourceListFilterPredicate.BETWEEN) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_AFTER}`), value: String(ResourceListFilterPredicate.IS_AFTER) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_AFTER_OR_ON}`), value: String(ResourceListFilterPredicate.IS_AFTER_OR_ON) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_BEFORE}`), value: String(ResourceListFilterPredicate.IS_BEFORE) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_BEFORE_OR_ON}`), value: String(ResourceListFilterPredicate.IS_BEFORE_OR_ON) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.PRESENT}`), value: String(ResourceListFilterPredicate.PRESENT) },
      ]
      const selectedDatePredicateOption = datePredicateOptions.find(predicateOption => predicateOption.value === predicate)
      const displayDateArrow = predicate !== ResourceListFilterPredicate.BETWEEN
      const isAfterOrOnDateValue = typeof value === 'object' ? value[ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_AFTER_OR_ON)] : null
      const isBeforeOrOnDateValue = typeof value === 'object' ? value[ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_BEFORE_OR_ON)] : null

      const nullPredicateOptions = [
        { label: t(`NullPredicate::Yes`), value: String(true) },
        { label: t(`NullPredicate::No`), value: String(false) },
      ]

      let selectedNullPredicateValue = null

      if (typeof value === 'object') {
        selectedNullPredicateValue = nullPredicateOptions.find(option => option.value === String(value[ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.PRESENT)]))
      }

      const getSingleDateInputValue = () => {
        if (!predicate) return null

        switch (predicate) {
          case ResourceListFilterPredicate.EQUALS:
            return value ? moment(value) : null
          case ResourceListFilterPredicate.IS_AFTER:
          case ResourceListFilterPredicate.IS_AFTER_OR_ON:
          case ResourceListFilterPredicate.IS_BEFORE:
          case ResourceListFilterPredicate.IS_BEFORE_OR_ON:
            const predicateValue = value[ResourceHelper.getPredicateAbbreviation(predicate)]
            return predicateValue ? moment(predicateValue) : null
          default:
            return null
        }
      }

      return (
        <Container>
          <PowerSelect
            options={datePredicateOptions}
            value={selectedDatePredicateOption}
            onChange={(option) => onPredicateChange(option.value)}
          />
          <InputContainer displayArrow={displayDateArrow}>
            {predicate !== ResourceListFilterPredicate.BETWEEN && predicate !== ResourceListFilterPredicate.PRESENT && <DateInput
              key={predicate}
              name={filter.name}
              dateFormat={setting.date_format}
              timeFormat={false}
              initialValue={getSingleDateInputValue()}
              inputProps={{ placeholder: setting.date_format }}
              onChange={(newValue) => {
                const propagateValue = (value: any) => {
                  switch (predicate) {
                    case ResourceListFilterPredicate.EQUALS:
                      if (onFilterValueChange) onFilterValueChange(name, value)
                      break
                    case ResourceListFilterPredicate.IS_AFTER:
                    case ResourceListFilterPredicate.IS_AFTER_OR_ON:
                    case ResourceListFilterPredicate.IS_BEFORE:
                    case ResourceListFilterPredicate.IS_BEFORE_OR_ON:
                      if (onFilterValueChange) onFilterValueChange(name, { [ResourceHelper.getPredicateAbbreviation(predicate)]: value })
                      break
                    default: throw new Error('[ResourceFilterInput] Invalid predicate')
                  }
                }

                if (newValue === '') {
                  propagateValue('')
                } else {
                  const newDate = moment(newValue)

                  if (newDate.isValid()) {
                    propagateValue(newDate.format('YYYY-MM-DD'))
                  }
                }
              }}
              closeOnSelect
            />}

            {predicate === ResourceListFilterPredicate.BETWEEN && <RowContainer>
              <DateInput
                name='isAfterOrOnDate'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={isAfterOrOnDateValue ? moment(isAfterOrOnDateValue) : null}
                inputProps={{ placeholder: setting.date_format }}
                onChange={(newValue) => {
                  if (newValue === '' && onFilterValueChange) {
                    onFilterValueChange(name, { ...value, [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_AFTER_OR_ON)]: null })
                  } else {
                    const newDate = moment(newValue)

                    if (newDate.isValid() && onFilterValueChange) {
                      onFilterValueChange(name, { ...value, [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_AFTER_OR_ON)]: newDate.format('YYYY-MM-DD') })
                    }
                  }
                }}
                closeOnSelect
              />

              <span style={{ marginLeft: 8, marginRight: 8 }}>{t('ResourceListFilterInput::and')}</span>

              <DateInput
                name='isBeforeOrOnDate'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={isBeforeOrOnDateValue ? moment(isBeforeOrOnDateValue) : null}
                inputProps={{ placeholder: setting.date_format }}
                onChange={(newValue) => {
                  if (newValue === '' && onFilterValueChange) {
                    onFilterValueChange(name, { ...value, [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_BEFORE_OR_ON)]: null })
                  } else {
                    const newDate = moment(newValue)

                    if (newDate.isValid() && onFilterValueChange) {
                      onFilterValueChange(name, { ...value, [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_BEFORE_OR_ON)]: newDate.format('YYYY-MM-DD') })
                    }
                  }
                }}
                closeOnSelect
              />

            </RowContainer>}
            {predicate === ResourceListFilterPredicate.PRESENT && <RowContainer>
              <PowerSelect
                options={nullPredicateOptions}
                value={selectedNullPredicateValue}
                styles={{
                  container: (base) => {
                    return {
                      ...base,
                      width: '100%',
                    }
                  }
                }}
                onChange={(option) => {
                  const value = option ? option.value : []

                  if (onFilterValueChange) onFilterValueChange(name, { [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.PRESENT)]: value })
                }}
              />
            </RowContainer>}
          </InputContainer>
        </Container>
      )
    case ResourceListFilterType.NUMBER:
      const numberPredicateOptions = [
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.EQUALS}`), value: String(ResourceListFilterPredicate.EQUALS) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.BETWEEN}`), value: String(ResourceListFilterPredicate.BETWEEN) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.GREATER_THAN}`), value: String(ResourceListFilterPredicate.GREATER_THAN) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.LESS_THAN}`), value: String(ResourceListFilterPredicate.LESS_THAN) },
      ]
      const selectedNumberPredicateOptions = numberPredicateOptions.find(predicateOption => predicateOption.value === predicate)
      const displayNumberArrow = predicate !== ResourceListFilterPredicate.BETWEEN
      const isAfterOrOnNumberValue = typeof value === 'object' ? value[ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_AFTER_OR_ON)] : null
      const isBeforeOrOnNumberValue = typeof value === 'object' ? value[ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_BEFORE_OR_ON)] : null

      const getSingleInputValue = () => {
        if (!predicate) return null

        switch (predicate) {
          case ResourceListFilterPredicate.EQUALS:
            return value === undefined ? '' : value
          case ResourceListFilterPredicate.GREATER_THAN:
          case ResourceListFilterPredicate.LESS_THAN:
          case ResourceListFilterPredicate.IS_AFTER:
          case ResourceListFilterPredicate.IS_AFTER_OR_ON:
          case ResourceListFilterPredicate.IS_BEFORE:
          case ResourceListFilterPredicate.IS_BEFORE_OR_ON:
            const predicateValue = value[ResourceHelper.getPredicateAbbreviation(predicate)]
            return predicateValue ? predicateValue : null
          default:
            return null
        }
      }

      return (
        <Container>
          <PowerSelect
            options={numberPredicateOptions}
            value={selectedNumberPredicateOptions}
            onChange={(option) => onPredicateChange(option.value)}
          />
          <InputContainer displayArrow={displayNumberArrow}>
            {predicate !== ResourceListFilterPredicate.BETWEEN && <input
              key={predicate}
              name={filter.name}
              type='number'
              value={getSingleInputValue()}
              onChange={(e) => {
                const newValue = e.currentTarget.value

                const propagateValue = (value: any) => {
                  switch (predicate) {
                    case ResourceListFilterPredicate.EQUALS:
                      if (onFilterValueChange) onFilterValueChange(name, value)
                      break
                    case ResourceListFilterPredicate.GREATER_THAN:
                    case ResourceListFilterPredicate.LESS_THAN:
                    case ResourceListFilterPredicate.IS_AFTER:
                    case ResourceListFilterPredicate.IS_AFTER_OR_ON:
                    case ResourceListFilterPredicate.IS_BEFORE:
                    case ResourceListFilterPredicate.IS_BEFORE_OR_ON:
                      if (onFilterValueChange) onFilterValueChange(name, { [ResourceHelper.getPredicateAbbreviation(predicate)]: value })
                      break
                    default: throw new Error('[ResourceFilterInput] Invalid predicate')
                  }
                }

                if (newValue === '') {
                  propagateValue('')
                } else {
                  propagateValue(newValue)
                }
              }}
              placeholder='10'
              step={.01}
            />}
            {predicate === ResourceListFilterPredicate.BETWEEN && <RowContainer>
              <input
                name='isAfterOrOnNumber'
                type='number'
                value={isAfterOrOnNumberValue === undefined ? '' : isAfterOrOnNumberValue}
                onChange={(e) => {
                  const newValue = e.currentTarget.value

                  if (onFilterValueChange) onFilterValueChange(name, { ...value, [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_AFTER_OR_ON)]: newValue })
                }}
                placeholder='10'
                step={.01}
              />

              <span style={{ marginLeft: 8, marginRight: 8 }}>{t('ResourceListFilterInput::and')}</span>

              <input
                name='isBeforeOrOnNumber'
                type='number'
                value={isBeforeOrOnNumberValue === undefined ? '' : isBeforeOrOnNumberValue}
                onChange={(e) => {
                  const newValue = e.currentTarget.value

                  if (onFilterValueChange) onFilterValueChange(name, { ...value, [ResourceHelper.getPredicateAbbreviation(ResourceListFilterPredicate.IS_BEFORE_OR_ON)]: newValue })
                }}
                placeholder='10'
                step={.01}
              />
            </RowContainer>}
          </InputContainer>
        </Container>
      )
    case ResourceListFilterType.BOOLEAN:
      return (
        <CheckboxInput
          checked={value}
          label={filter.label}
          onChange={(checked) => {
            if (onFilterValueChange) onFilterValueChange(name, checked)
          }}
        />
      )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser: {
        workspace: {
          setting
        }
      }
    }
  } = state

  return {
    setting: setting,
  }
}

export default connect(mapStateToProps)(ResourceFilterInput)