import * as React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { closeContactModal, showPeppolParticipantModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import VATHelper from '../../helpers/VatHelper'
import Notification from '../../utilities/Notification'
import { ContactsController, VATController } from '../../controllers'
import SwatchPicker from '../Swatch/SwatchPicker'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import PowerSelect from '../Form/PowerSelect'
import CreatablePowerSelect from '../Form/CreatablePowerSelect'
import ReferralSourcesController from '../../controllers/ReferralSourcesController'
import { components } from 'react-select'
import Tooltip from '../Tooltips/Tooltip'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import ContactHelper from '../../helpers/ContactHelper'
import { withTranslation, WithTranslation } from 'react-i18next'
import MoneyInput from '../Form/MoneyInput'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { NOTES_EDITOR_CONFIG } from '../Editor/Editor'
import LocaleHelper from '../../helpers/LocaleHelper'
import { Contact, ContactAddress, ContactEmailType, ContactTelephoneNumberType, ContactType, CurrentUser, CustomField, RateType, ReferralSource, UserWorkspaceSettingScope } from '../../types'
import SettingHelper from '../../helpers/SettingHelper'
import styled from 'styled-components'
import { Style } from '../../styles'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import ButtonLink from '../Button/ButtonLink'
import Select from '../Form/Select'
import { ContactAvatarContainer } from '../Contact/ContactAvatar'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import MenuEditorItemDrag from '../MenuEditor/MenuEditorItemDrag';
import DroppableHelper from '../../helpers/DroppableHelper';
import ModalFooter from './Parts/ModalFooter';
import Switch from '../Form/Switch';
import Alert from '../Alert/Alert';
import RateHelper from '../../helpers/RateHelper';
import IntlTelInput from 'intl-tel-input/react';

enum ContactDroppableType {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  ACCOUNT_NUMBER = 'account_number',
  WEBSITE = 'website'
}

const ClientModalWindow = styled(ModalWindow)`
  height: 100vh;
  max-width: 900px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }

  .select-wrapper {
    min-width: 115px;
  }

  ${ModalMiddle} {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const AvatarWithNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-item {
    width: 100%;
  }

  ${ContactAvatarContainer} {
    margin-right: ${Style.spacing.x2};
    margin-top: 3px;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      margin-right: 0;
      margin-top: 0;
    }

  }
`

const PeppolContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input:first-child {
    max-width: 70px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  input:nth-child(2) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important
  }
`

const PeppolAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Style.color.border};
  border-top-right-radius: ${Style.variables.baseBorderRadius};
  border-bottom-right-radius: ${Style.variables.baseBorderRadius};
  height: 38px;
  min-width: 38px;
  width: 38px;
  cursor: pointer;
`

const PeppolActions = styled.div`
  display: flex;
  flex-direction: row;

  ${PeppolAction} {
    border-radius: 0;
    border-right: none;

    &:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-child {
      border-right: 1px solid ${Style.color.border};
      border-top-right-radius: ${Style.variables.baseBorderRadius};
      border-bottom-right-radius: ${Style.variables.baseBorderRadius};
    }
  }
`


const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > *:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
  }
`

const GroupedFormItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  position: relative;

  > *:first-child {
    width: 100%;
  }
  > *:not(:last-child) {
    margin-right: 4px;
  }

  ${MenuEditorItemDrag} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    width: fit-content;
    margin-top: 0;
  }

  .select-wrapper {
    width: 100%;
  }
`

const DeleteIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    color: #BA2525;
    background: #FACDCD;
    svg {
      fill: #BA2525;
    }
  }
`

const AddressNumber = styled.input`
  max-width: calc(33% - 2px);
`

const SpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 2px;
  }
`

const SelectContainer = styled.div`
  .select-wrapper {
    min-width: 110px;
    max-width: 110px;
  }
`

const TelephoneInputContainer = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }

  input {
    width: 100%;
    
    &:not(.iti__search-input) {
      padding-left: 48px !important;
    }
  }
`

interface IStateToProps {
  contact: Contact
  activeTab?: ContactModalTab
  onSubmit?: (contact: Contact) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeContactModal
  showPeppolParticipantModal: typeof showPeppolParticipantModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export type ContactModalTab = 'details' | 'custom_fields' | 'notes' | 'invoice'

interface IState {
  didInitialLoad: boolean
  activeTab: ContactModalTab
  contact: Contact | null
  currencies: { label: string, value: string }[]
  customFields: CustomField[]
  locales: string[]
  countries: { label: string, value: string }[]
  referralSources: ReferralSource[]
  errors: any
}

class ContactModal extends React.Component<IProps, IState> {
  private telephoneInputKey = 'telephone-input-'
  static defaultProps = {
    contact: {}
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: 'details',
      contact: null,
      currencies: [],
      locales: [],
      referralSources: [],
      customFields: [],
      countries: [],
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.fetchVATDetails = this.fetchVATDetails.bind(this)

    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onFirstNameChange = this.onFirstNameChange.bind(this)
    this.onLastNameChange = this.onLastNameChange.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onAliasChange = this.onAliasChange.bind(this)
    this.onVatNumberChange = this.onVatNumberChange.bind(this)
    this.onReferralChange = this.onReferralChange.bind(this)
    this.onReferralCreate = this.onReferralCreate.bind(this)
    this.onCompanyChange = this.onCompanyChange.bind(this)
    this.onGroupsChange = this.onGroupsChange.bind(this)
    this.onMarketingConsentChange = this.onMarketingConsentChange.bind(this)
    this.onJobTitleChange = this.onJobTitleChange.bind(this)
    this.onBirthdateChange = this.onBirthdateChange.bind(this)
    this.onBackgroundInfoChange = this.onBackgroundInfoChange.bind(this)
    this.onColorChange = this.onColorChange.bind(this)
    this.onNotesChange = this.onNotesChange.bind(this)
    this.onVATLiabilityChange = this.onVATLiabilityChange.bind(this)
    this.onCurrencyChange = this.onCurrencyChange.bind(this)
    this.onLocaleChange = this.onLocaleChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onModalCloseClick = this.onModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onReferralSourceDelete = this.onReferralSourceDelete.bind(this)
    this.onHourlyRateChange = this.onHourlyRateChange.bind(this)
    this.onDayRateChange = this.onDayRateChange.bind(this)
    this.onRateTypeChange = this.onRateTypeChange.bind(this)
    this.onSearchPeppolNetworkClick = this.onSearchPeppolNetworkClick.bind(this)
    this.onClearPeppolIdClick = this.onClearPeppolIdClick.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onEmailTypeChange = this.onEmailTypeChange.bind(this)
    this.onEmailDeleteClick = this.onEmailDeleteClick.bind(this)
    this.onEmailAddClick = this.onEmailAddClick.bind(this)
    this.onPhoneNumberBlur = this.onPhoneNumberBlur.bind(this)
    this.onPhoneNumberTypeChange = this.onPhoneNumberTypeChange.bind(this)
    this.onTelephoneNumberDeleteClick = this.onTelephoneNumberDeleteClick.bind(this)
    this.onPhoneNumberAddClick = this.onPhoneNumberAddClick.bind(this)
    this.onAccountNumberChange = this.onAccountNumberChange.bind(this)
    this.onAccountNumberDeleteClick = this.onAccountNumberDeleteClick.bind(this)
    this.onAccountNumberAddClick = this.onAccountNumberAddClick.bind(this)
    this.onWebsiteChange = this.onWebsiteChange.bind(this)
    this.onWebsiteDeleteClick = this.onWebsiteDeleteClick.bind(this)
    this.onWebsiteAddClick = this.onWebsiteAddClick.bind(this)
    this.onAdressStreetChange = this.onAdressStreetChange.bind(this)
    this.onAdressStreetNumberChange = this.onAdressStreetNumberChange.bind(this)
    this.onAddressCityChange = this.onAddressCityChange.bind(this)
    this.onAddressStateChange = this.onAddressStateChange.bind(this)
    this.onAddressZipChange = this.onAddressZipChange.bind(this)
    this.onAddressCountryChange = this.onAddressCountryChange.bind(this)
    this.onDeliverySameAsInvoiceAddressChange = this.onDeliverySameAsInvoiceAddressChange.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { contact: propsContact, activeTab: propActiveTab, currentUser: { workspace: { country_code } } } = this.props

    try {
      const response = await ContactsController.getForm({ id: propsContact.id })
      const { activeTab } = this.state
      const { currencies, locales, contact, referral_sources, countries, custom_fields } = response

      const stateContact: Contact = {
        ...contact,
        ...propsContact,
      }

      if (propsContact?.emails?.length === 0) stateContact.emails = [...contact.emails, ...propsContact.emails]
      if (propsContact?.telephone_numbers?.length === 0) stateContact.telephone_numbers = [...contact.telephone_numbers, ...propsContact.telephone_numbers]
      if (propsContact?.account_numbers?.length > 0) stateContact.account_numbers = [...contact.account_numbers, ...propsContact.account_numbers]
      if (propsContact?.websites?.length === 0) stateContact.websites = [...contact.websites, ...propsContact.websites]


      if (stateContact.emails?.length === 0) stateContact.emails = [{ type: ContactEmailType.WORK, value: '', position: ContactHelper.POSITION_STEP_INDEX }]
      if (stateContact.telephone_numbers?.length === 0) stateContact.telephone_numbers = [{ type: ContactTelephoneNumberType.WORK, value: '', position: ContactHelper.POSITION_STEP_INDEX }]
      if (stateContact.account_numbers?.length === 0) stateContact.account_numbers = [{ value: '', position: ContactHelper.POSITION_STEP_INDEX }]
      if (stateContact.websites?.length === 0) stateContact.websites = [{ url: '', position: ContactHelper.POSITION_STEP_INDEX }]

      stateContact.emails?.forEach((_, index) => {
        stateContact.emails[index].position = ContactHelper.POSITION_STEP_INDEX * (index + 1)
      })

      stateContact.telephone_numbers?.forEach((_, index) => {
        stateContact.telephone_numbers[index].position = ContactHelper.POSITION_STEP_INDEX * (index + 1)
      })

      stateContact.account_numbers?.forEach((_, index) => {
        stateContact.account_numbers[index].position = ContactHelper.POSITION_STEP_INDEX * (index + 1)
      })

      stateContact.websites?.forEach((_, index) => {
        stateContact.websites[index].position = ContactHelper.POSITION_STEP_INDEX * (index + 1)
      })

      this.setState({
        currencies: currencies,
        locales: locales,
        referralSources: referral_sources,
        contact: stateContact,
        countries: countries,
        customFields: custom_fields,
        didInitialLoad: true,
        activeTab: propActiveTab ? propActiveTab : activeTab,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async fetchVATDetails() {
    const { contact } = this.state

    try {
      const response = await VATController.getDetails(contact.vat_number)

      if (!response.errors) {
        const { contact } = this.state;
        const {
          name,
          street,
          city,
          country,
          postal,
          street_number
        } = response;

        this.setState({
          contact: {
            ...contact,
            name: name,
            billing_address: {
              street: street,
              street_number: street_number,
              city: city,
              zip: postal,
              country: country,
            }
          }
        })
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    });
  }

  onFirstNameChange(e) {
    const { contact } = this.state;
    const firstName = e.currentTarget.value;

    if (contact.type === ContactType.PERSON) {
      this.setState({
        contact: {
          ...contact,
          first_name: firstName
        },
      })
    }
  }


  onLastNameChange(e) {
    const { contact } = this.state;
    const lastName = e.currentTarget.value;

    if (contact.type === ContactType.PERSON) {
      this.setState({
        contact: {
          ...contact,
          last_name: lastName
        },
      })
    }
  }

  onNameChange(e) {
    const { contact } = this.state;
    const name = e.currentTarget.value;

    this.setState({
      contact: {
        ...contact,
        name: name
      },
    })
  }

  onAliasChange(e) {
    const { contact } = this.state;
    const alias = e.currentTarget.value;

    this.setState({
      contact: {
        ...contact,
        alias: alias,
      }
    });
  }

  onVatNumberChange(e) {
    const { contact } = this.state
    const number = e.currentTarget.value;

    this.setState({
      contact: Object.assign({}, contact, { vat_number: number })
    }, () => {
      if (VATHelper.isValidEUVAT(number)) {
        this.fetchVATDetails()
      }
    })
  }

  onWebsiteChange(index, website) {
    const { contact } = this.state

    const websites = contact.websites || []

    websites[index].url = website

    this.setState({
      contact: {
        ...contact,
        websites: [...websites]
      }
    })
  }

  onWebsiteDeleteClick(index) {
    const { contact } = this.state

    const websites = contact.websites || []
    const website = websites[index]

    if (website.id) {
      websites[index]._destroy = true
    } else {
      websites.splice(index, 1)
    }

    this.setState({
      contact: {
        ...contact,
        websites: [...websites]
      }
    })
  }

  onWebsiteAddClick() {
    const { contact } = this.state

    const websites = contact.websites || []

    this.setState({
      contact: {
        ...contact,
        websites: [...websites, { url: '', position: ContactHelper.POSITION_STEP_INDEX * (websites.length + 1) }]
      }
    })
  }

  onAdressStreetChange(property: keyof Contact, street) {
    const { contact } = this.state

    const address: ContactAddress = (contact[property] || {}) as ContactAddress

    address['street'] = street

    this.setState({
      contact: {
        ...contact,
        [property]: address
      }
    })
  }

  onAdressStreetNumberChange(property: keyof Contact, number) {
    const { contact } = this.state

    const address: ContactAddress = (contact[property] || {}) as ContactAddress

    address['street_number'] = number

    this.setState({
      contact: {
        ...contact,
        [property]: address
      }
    })
  }

  onAddressCityChange(property: keyof Contact, city) {
    const { contact } = this.state

    const address: ContactAddress = (contact[property] || {}) as ContactAddress

    address['city'] = city

    this.setState({
      contact: {
        ...contact,
        [property]: address
      }
    })
  }

  onAddressStateChange(property: keyof Contact, state) {
    const { contact } = this.state

    const address: ContactAddress = (contact[property] || {}) as ContactAddress

    address['state'] = state

    this.setState({
      contact: {
        ...contact,
        [property]: address
      }
    })
  }


  onAddressZipChange(property: keyof Contact, zip) {
    const { contact } = this.state

    const address: ContactAddress = (contact[property] || {}) as ContactAddress

    address['zip'] = zip

    this.setState({
      contact: {
        ...contact,
        [property]: address
      }
    })
  }

  onAddressCountryChange(property: keyof Contact, country) {
    const { contact } = this.state

    const address: ContactAddress = (contact[property] || {}) as ContactAddress

    address['country'] = country

    this.setState({
      contact: {
        ...contact,
        [property]: address
      }
    })
  }

  onDeliverySameAsInvoiceAddressChange() {
    const { contact } = this.state

    this.setState({
      contact: {
        ...contact,
        delivery_same_as_invoice_address: !contact.delivery_same_as_invoice_address
      }
    })
  }

  onReferralChange(referralSourceOption) {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        referral_source_id: referralSourceOption ? referralSourceOption.value : null
      }
    });
  }

  onReferralCreate(name: string) {
    const { t } = this.props
    ReferralSourcesController
      .create({ name: name })
      .then(response => {
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('ContactModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('ContactModal::Referral source successfully created'))
          const { referralSources, contact } = this.state
          const referralSource: ReferralSource = response

          this.setState({
            referralSources: [
              referralSource,
              ...referralSources,
            ],
            contact: {
              ...contact,
              referral_source_id: referralSource.id,
            }
          })
        }
      })
      .catch(console.error)
  }

  onCompanyChange(option) {
    const { contact } = this.state;

    if (contact.type === ContactType.PERSON) {
      this.setState({
        contact: {
          ...contact,
          contact_id: option ? option : null
        }
      })
    }
  }

  onGroupsChange(groups) {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        group_ids: groups
      }
    })
  }

  onMarketingConsentChange() {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        marketing_consent: !contact.marketing_consent
      }
    })
  }

  onJobTitleChange(e) {
    const { contact } = this.state;
    const jobTitle = e.currentTarget.value

    if (contact.type === ContactType.PERSON) {
      this.setState({
        contact: {
          ...contact,
          job_title: jobTitle
        }
      })
    }
  }

  onBirthdateChange(value) {
    const { contact } = this.state;

    if (contact.type === ContactType.PERSON) {
      const newStartDate = moment(value)

      if (newStartDate.isValid()) {
        this.setState({
          contact: {
            ...contact,
            birthdate: newStartDate.format('YYYY-MM-DD'),
          }
        })
      }
    }
  }

  onBackgroundInfoChange(e) {
    const { contact } = this.state;
    const backgroundInfo = e.currentTarget.value

    this.setState({
      contact: {
        ...contact,
        background_info: backgroundInfo
      }
    })
  }

  onColorChange(color) {
    const { contact } = this.state;

    this.setState({
      contact: Object.assign({}, contact, { color: color })
    });
  }

  onNotesChange(notes: string) {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        notes: notes,
      }
    });
  }

  onVATLiabilityChange(option) {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        vat_liability: option.value
      }
    })
  }

  onCurrencyChange(option) {
    const { contact } = this.state;

    this.setState({
      contact: Object.assign({}, contact, { currency: option.value })
    });
  }

  onLocaleChange(option) {
    const { contact } = this.state;

    this.setState({
      contact: Object.assign({}, contact, { locale: option.value })
    });
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { contact } = this.state;
    const { close, onSubmit, t } = this.props

    // Big hack to make telephone numbers work with react-intl-tel-input (onChange is triggered in an endless loop)

    const telephoneNumbers = (contact.telephone_numbers || [])
      .map((telephoneNumber, index) => {
        const inputReference = this[`${this.telephoneInputKey}${index}`]

        if (!inputReference) return telephoneNumber

        const value = inputReference.getInstance().getNumber()

        return {
          ...telephoneNumber,
          value: value,
          _destroy: telephoneNumber.id && value === ''
        }
      })

    const updatedContact: Contact = {
      ...contact,
      emails: contact.emails?.filter(email => Boolean(email.value) || email._destroy),
      telephone_numbers: telephoneNumbers?.filter(telephoneNumber => Boolean(telephoneNumber.value) || telephoneNumber._destroy),
      account_numbers: contact.account_numbers?.filter(accountNumber => Boolean(accountNumber.value) || accountNumber._destroy),
      websites: contact.websites?.filter(website => Boolean(website.url) || website._destroy),
    }

    try {
      if (contact.id) { // Do update
        const response = await ContactsController.update(updatedContact)
        const { errors, error_full_messages } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('ContactModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('ContactModal::{{type}} successfully updated.', { type: ContactHelper.getContactLabelType(contact.type) }))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
      else {
        const response = await ContactsController.create(updatedContact)
        const { errors } = response;

        if (errors) {
          this.setState({ errors: errors });
          Notification.notifyError(t('ContactModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('ContactModal::{{type}} successfully created.', { type: ContactHelper.getContactLabelType(contact.type) }))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onReferralSourceDelete(id: string) {
    const { t } = this.props
    ReferralSourcesController
      .delete(id)
      .then(response => {
        if (response.success) {
          const { referralSources } = this.state

          const referralSourceIndex = referralSources.findIndex(r => r.id === id)

          referralSources.splice(referralSourceIndex, 1);

          this.setState({
            referralSources: referralSources
          });

          Notification.notifySuccess(t('ContactModal::Source successfully deleted'))
        }
      })
      .catch(console.error)
  }

  onHourlyRateChange(hourlyRate) {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        hourly_rate: hourlyRate
      },
    })
  }

  onDayRateChange(dayRate) {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        day_rate: dayRate,
      },
    })
  }

  onRateTypeChange(option) {
    const { contact } = this.state;

    this.setState({
      contact: {
        ...contact,
        rate_type: option.value
      }
    });
  }

  onSearchPeppolNetworkClick() {
    const { contact } = this.state
    this.props.showPeppolParticipantModal({
      query: contact.vat_number || contact.name,
      onSubmit: (peppolParticipant) => {
        this.setState({
          contact: {
            ...contact,
            peppol_id: peppolParticipant.participantID.value,
          }
        })
      }
    })
  }

  onClearPeppolIdClick() {
    const { contact } = this.state

    this.setState({
      contact: {
        ...contact,
        peppol_id: null
      }
    })
  }

  onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) return;

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const { contact } = this.state

    let key: keyof Contact = null
    switch (result.type) {
      case ContactDroppableType.EMAIL:
        key = 'emails'
        break
      case ContactDroppableType.PHONE_NUMBER:
        key = 'telephone_numbers'
        break
      case ContactDroppableType.ACCOUNT_NUMBER:
        key = 'account_numbers'
        break
      case ContactDroppableType.WEBSITE:
        key = 'websites'
        break
    }

    const resources = contact[key] || []

    // @ts-ignore
    const newPosition = DroppableHelper.getNewPositionBasedOnDropResult(resources, result)

    // @ts-ignore
    const updatedResources: any[] = [...resources]

    updatedResources[source.index].position = newPosition

    this.setState({
      contact: { ...contact, [key]: DroppableHelper.sort([...updatedResources], 'ascending') }
    })
  }

  onEmailChange(index, email) {
    const { contact } = this.state

    const emails = contact.emails || []

    emails[index].value = email

    this.setState({
      contact: {
        ...contact,
        emails: [...emails]
      }
    })
  }

  onEmailTypeChange(index, type) {
    const { contact } = this.state

    const emails = contact.emails || []

    emails[index].type = type

    this.setState({
      contact: {
        ...contact,
        emails: [...emails]
      }
    })
  }

  onEmailDeleteClick(index) {
    const { contact } = this.state

    const emails = contact.emails || []
    const email = emails[index]

    if (email.id) {
      emails[index]._destroy = true
    } else {
      emails.splice(index, 1)
    }

    this.setState({
      contact: {
        ...contact,
        emails: [...emails]
      }
    })
  }

  onEmailAddClick() {
    const { contact } = this.state

    const emails = contact.emails || []

    this.setState({
      contact: {
        ...contact,
        emails: [
          ...emails,
          { value: '', type: ContactEmailType.WORK, position: ContactHelper.POSITION_STEP_INDEX * (emails.length + 1) }
        ]
      }
    })
  }

  onPhoneNumberBlur(index) {
    const { contact } = this.state
    const inputReference = this[`${this.telephoneInputKey}${index}`]

    if (!inputReference) return

    const phoneNumber = inputReference.getInstance().getNumber()
    const telephoneNumbers = contact.telephone_numbers || []

    const currentTelephoneNumber = telephoneNumbers[index]

    currentTelephoneNumber.value = phoneNumber

    this.setState({
      contact: {
        ...contact,
        telephone_numbers: [...telephoneNumbers]
      }
    })
  }

  onPhoneNumberTypeChange(index, type) {
    const { contact } = this.state

    const telephoneNumbers = contact.telephone_numbers || []

    telephoneNumbers[index].type = type

    this.setState({
      contact: {
        ...contact,
        telephone_numbers: [...telephoneNumbers]
      }
    })
  }

  onTelephoneNumberDeleteClick(index) {
    const { contact } = this.state

    const telephoneNumbers = contact.telephone_numbers || []
    const telephoneNumber = telephoneNumbers[index]

    if (telephoneNumber.id) {
      telephoneNumbers[index]._destroy = true
    } else {
      telephoneNumbers.splice(index, 1)
    }

    this.setState({
      contact: {
        ...contact,
        telephone_numbers: [...telephoneNumbers]
      }
    })
  }

  onPhoneNumberAddClick() {
    const { contact } = this.state

    const telephoneNumbers = contact.telephone_numbers || []

    this.setState({
      contact: {
        ...contact,
        telephone_numbers: [
          ...telephoneNumbers,
          { value: '', type: ContactTelephoneNumberType.WORK, position: ContactHelper.POSITION_STEP_INDEX * (telephoneNumbers.length + 1) }
        ]
      }
    })
  }

  onAccountNumberChange(index, accountNumber) {
    const { contact } = this.state

    const accountNumbers = contact.account_numbers || []

    accountNumbers[index].value = accountNumber

    this.setState({
      contact: {
        ...contact,
        account_numbers: [...accountNumbers]
      }
    })
  }

  onAccountNumberDeleteClick(index) {
    const { contact } = this.state

    const accountNumbers = contact.account_numbers || []
    const accountNumber = accountNumbers[index]

    if (accountNumber.id) {
      accountNumbers[index]._destroy = true
    } else {
      accountNumbers.splice(index, 1)
    }

    this.setState({
      contact: {
        ...contact,
        account_numbers: [...accountNumbers]
      }
    })
  }

  onAccountNumberAddClick() {
    const { contact } = this.state

    const accountNumbers = contact.account_numbers || []

    this.setState({
      contact: {
        ...contact,
        account_numbers: [
          ...accountNumbers,
          { value: '', position: ContactHelper.POSITION_STEP_INDEX * (accountNumbers.length + 1) }
        ]
      }
    })
  }

  renderNavigation(): JSX.Element {
    const { t } = this.props
    const { activeTab, customFields } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === 'details'} data-tab='details' onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            {t('ContactModal::Details')}
          </span>
        </ModalNavigationItem>

        {customFields.length > 0 && <ModalNavigationItem active={activeTab === 'custom_fields'} data-tab='custom_fields' onClick={this.onNavigationItemClick}>
          <Icon icon='magic' />
          <span>
            {t('ContactModal::Custom fields')}
          </span>
        </ModalNavigationItem>}

        <ModalNavigationItem active={activeTab === 'notes'} data-tab='notes' onClick={this.onNavigationItemClick}>
          <Icon icon='pencil' />
          <span>
            {t('ContactModal::Notes')}
          </span>
        </ModalNavigationItem>

        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <ModalNavigationItem active={activeTab === 'invoice'} data-tab='invoice' onClick={this.onNavigationItemClick}>
          <Icon icon='banknote' />
          <span>
            {t('ContactModal::Invoicing')}
          </span>
        </ModalNavigationItem>}
      </ModalNavigation>
    )
  }

  renderDetails() {
    const { t, currentUser: { workspace } } = this.props
    const { setting } = workspace
    const { activeTab, contact, referralSources, currencies, countries } = this.state;

    if (activeTab !== 'details') return null;

    const referralSourceOptions = referralSources.map(referralSource => ({ label: referralSource.name, value: referralSource.id }))
    const selectedReferralSourceOption = referralSourceOptions.find(option => option.value === contact.referral_source_id)
    const emailTypeOptions = ContactHelper.getEmailTypeOptions()
    const telephoneNumberTypeOptions = ContactHelper.getPhonenumberTypeOptions()
    const countryOptions = countries

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                {/* <ContactAvatar /> */}
                {contact.type === ContactType.PERSON && <div className='grid'>
                  <div className='grid-cell with-6col'>
                    <div className='form-item'>
                      <label>
                        {t('ContactModal::First name')}<span>*</span>
                      </label>
                      <input
                        type='text'
                        name='name'
                        onChange={this.onFirstNameChange}
                        value={contact.first_name}
                        placeholder={t('ContactModal::First name')}
                        required
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-6col'>
                    <div className='form-item'>
                      <label>
                        {t('ContactModal::Last name')}
                      </label>
                      <input
                        type='text'
                        name='name'
                        onChange={this.onLastNameChange}
                        value={contact.last_name}
                        placeholder={t('ContactModal::Last name')}
                      />
                    </div>
                  </div>
                </div>}

                {contact.type === ContactType.COMPANY && <>
                  <div className='form-item'>
                    <label>
                      {t('ContactModal::Name')}<span>*</span>
                    </label>
                    <input
                      type='text'
                      name='name'
                      onChange={this.onNameChange}
                      value={contact.name}
                      placeholder={t('ContactModal::Name')}
                      required
                    />
                  </div>
                </>}
              </div>
              <div className='grid-cell with-4col'>
                <div className='form-item'>
                  <label>
                    {t('ContactModal::Alias')}
                  </label>
                  <input type='text' name='alias' onChange={this.onAliasChange} value={contact.alias} placeholder='Jane' />
                </div>
              </div>

              <div className='grid-cell with-2col'>
                <div className='form-item'>
                  <label>{t('ContactModal::Color')}</label>
                  <SwatchPicker
                    color={contact.color}
                    onChange={this.onColorChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {contact.type === ContactType.COMPANY && <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ContactModal::VAT number')}
                <Tooltip
                  content={t('ContactModal::Fill in company information automatically by providing the VAT number.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <input
                type='text'
                name='vat_number'
                onChange={this.onVatNumberChange}
                value={contact.vat_number}
                placeholder={`BE001234567B01`}
              />
            </div>
          </div>}

          {contact.type === ContactType.PERSON && <>
            <div className='grid-cell with-6col'>
              <div className='form-item'>
                <label>{t('ContactModal::Job title')}</label>
                <input
                  type='text'
                  value={contact.job_title}
                  onChange={this.onJobTitleChange}
                  placeholder={t('ContactModal::Job title')}
                />
              </div>
            </div>

            <div className='grid-cell with-6col'>
              <div className='form-item'>
                <label>{t('ContactModal::Works for company')}</label>
                <ResourceCreatablePowerSelect
                  type='company'
                  value={contact.contact_id}
                  onChange={this.onCompanyChange}
                  isClearable={true}
                  placeholder={t('ContactModal::Select a company...')}
                  isValidNewOption={() => false}
                />
              </div>
            </div>

            <div className='grid-cell with-6col'>
              <div className='form-item'>
                <label>{t('ContactModal::Birthdate')}</label>
                <DateInput
                  name='start_date'
                  dateFormat={setting.date_format}
                  timeFormat={false}
                  inputProps={{ placeholder: setting.date_format }}
                  onChange={this.onBirthdateChange}
                  value={contact.birthdate}
                  closeOnSelect
                />
              </div>
            </div>
          </>}

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ContactModal::Groups')}

                <Tooltip
                  content={t('ContactModal::Groups allow you to tag and categorize your contacts/companies however you like. They\'re an easy and flexible way for you to pull up specific sets of contacts.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <ResourceCreatablePowerSelect
                type='contact_group'
                value={contact.group_ids}
                onChange={this.onGroupsChange}
                isClearable={true}
                placeholder={t('ContactModal::Select contact groups...')}
                isMulti
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <SpaceBetweenContainer>
                <label>
                  {t('ContactModal::Emails')}
                </label>

                <div style={{ marginBottom: 4 }}>
                  <Switch
                    name='marketing_consent'
                    label={t('ContactModal::Marketing consent')}
                    checked={contact.marketing_consent}
                    onClick={this.onMarketingConsentChange}
                  />
                </div>
              </SpaceBetweenContainer>

              <Droppable droppableId='emails' type={ContactDroppableType.EMAIL}>
                {(provided) => {
                  return (
                    <InputWrapper ref={provided.innerRef} {...provided.droppableProps}>
                      {contact.emails?.map((email, index) => {
                        if (email._destroy) return null
                        return (
                          <Draggable key={index} draggableId={`email-${index}`} index={index}>
                            {provided => (
                              <GroupedFormItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <MenuEditorItemDrag {...provided.dragHandleProps}>
                                  <Icon icon='grip-vertical' />
                                </MenuEditorItemDrag>
                                <input
                                  type='email'
                                  placeholder='email@domain.com'
                                  onChange={(e) => this.onEmailChange(index, e.currentTarget.value)}
                                  value={email.value}
                                />
                                <SelectContainer>
                                  <Select
                                    name='email_type'
                                    options={emailTypeOptions}
                                    onChange={(e) => this.onEmailTypeChange(index, e.currentTarget.value)}
                                    value={email.type}
                                  />
                                </SelectContainer>
                                <DeleteIcon onClick={() => this.onEmailDeleteClick(index)}>
                                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="24" width="24" aria-hidden="true"><path d="m17.25 6.75-10.5 10.5M6.75 6.75l10.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </DeleteIcon>
                              </GroupedFormItem>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                      <ButtonLink
                        icon='plus'
                        text={contact?.emails?.length > 0 ? t('ContactModal::Add another') : t('ContactModal::Add email')}
                        onClick={this.onEmailAddClick}
                      />
                    </InputWrapper>
                  )
                }}
              </Droppable>
            </div>
          </div>



          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ContactModal::Telephone numbers')}</label>
              <Droppable droppableId='telephone_numbers' type={ContactDroppableType.PHONE_NUMBER}>
                {(provided) => {
                  return (
                    <InputWrapper ref={provided.innerRef} {...provided.droppableProps}>
                      {contact?.telephone_numbers?.map((telephoneNumber, index) => {
                        if (telephoneNumber._destroy) return null

                        return (
                          <Draggable key={`${telephoneNumber.id}-${index}`} draggableId={`phone-number-${index}`} index={index}>
                            {provided => (
                              <GroupedFormItem ref={provided.innerRef} {...provided.draggableProps}>
                                <MenuEditorItemDrag {...provided.dragHandleProps}>
                                  <Icon icon='grip-vertical' />
                                </MenuEditorItemDrag>
                                <TelephoneInputContainer>
                                  <IntlTelInput
                                    ref={ref => this[`${this.telephoneInputKey}${index}`] = ref}
                                    initialValue={telephoneNumber.value}
                                    inputProps={{ onBlur: (e) => this.onPhoneNumberBlur(index) }}
                                    initOptions={{
                                      initialCountry: contact?.billing_address?.country || workspace.country_code,
                                      formatOnDisplay: true,
                                      formatAsYouType: true,
                                      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.0/build/js/utils.js",
                                    }}
                                  />
                                </TelephoneInputContainer>
                                <SelectContainer>
                                  <Select
                                    name='telephone_number_type'
                                    options={telephoneNumberTypeOptions}
                                    onChange={(e) => this.onPhoneNumberTypeChange(index, e.currentTarget.value)}
                                    value={telephoneNumber.type}
                                  />
                                </SelectContainer>
                                <DeleteIcon onClick={() => this.onTelephoneNumberDeleteClick(index)}>
                                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="24" width="24" aria-hidden="true"><path d="m17.25 6.75-10.5 10.5M6.75 6.75l10.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </DeleteIcon>
                              </GroupedFormItem>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}

                      <ButtonLink
                        icon='plus'
                        text={contact?.telephone_numbers?.length > 0 ? t('ContactModal::Add another') : t('ContactModal::Add telephone number')}
                        onClick={this.onPhoneNumberAddClick}
                      />
                    </InputWrapper>
                  )
                }}
              </Droppable>
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ContactModal::Account numbers')}</label>
              <Droppable droppableId='account_numbers' type={ContactDroppableType.ACCOUNT_NUMBER}>
                {(provided) => {
                  return (
                    <InputWrapper ref={provided.innerRef} {...provided.droppableProps}>
                      {contact?.account_numbers?.map((accountNumber, index) => {
                        if (accountNumber._destroy) return null

                        return (
                          <Draggable key={index} draggableId={`account-number-${index}`} index={index}>
                            {provided => (
                              <GroupedFormItem ref={provided.innerRef} {...provided.draggableProps}>
                                <MenuEditorItemDrag {...provided.dragHandleProps}>
                                  <Icon icon='grip-vertical' />
                                </MenuEditorItemDrag>
                                <input
                                  type='text'
                                  placeholder={t('ContactModal::BE00 0000 0000 0000')}
                                  onChange={(e) => this.onAccountNumberChange(index, e.currentTarget.value)}
                                  value={accountNumber.value}
                                />
                                <DeleteIcon onClick={() => this.onAccountNumberDeleteClick(index)}>
                                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="24" width="24" aria-hidden="true"><path d="m17.25 6.75-10.5 10.5M6.75 6.75l10.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </DeleteIcon>
                              </GroupedFormItem>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                      <ButtonLink
                        icon='plus'
                        text={contact?.account_numbers?.length > 0 ? t('ContactModal::Add another') : t('ContactModal::Add account number')}
                        onClick={this.onAccountNumberAddClick}
                      />
                    </InputWrapper>
                  )
                }}
              </Droppable>
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ContactModal::Websites')}</label>

              <Droppable droppableId='websites' type={ContactDroppableType.WEBSITE}>
                {(provided) => {
                  return (
                    <InputWrapper ref={provided.innerRef} {...provided.droppableProps}>
                      {contact?.websites?.map((website, index) => {
                        if (website._destroy) return null

                        return (
                          <Draggable key={index} draggableId={`websites-${index}`} index={index}>
                            {provided => (
                              <GroupedFormItem ref={provided.innerRef} {...provided.draggableProps}>
                                <MenuEditorItemDrag {...provided.dragHandleProps}>
                                  <Icon icon='grip-vertical' />
                                </MenuEditorItemDrag>
                                <input
                                  type='website'
                                  placeholder='https://www.mydomain.com'
                                  onChange={(e) => this.onWebsiteChange(index, e.currentTarget.value)}
                                  value={website.url}
                                />
                                <DeleteIcon onClick={() => this.onWebsiteDeleteClick(index)}>
                                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="24" width="24" aria-hidden="true"><path d="m17.25 6.75-10.5 10.5M6.75 6.75l10.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </DeleteIcon>
                              </GroupedFormItem>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                      <ButtonLink
                        icon='plus'
                        text={contact?.websites?.length > 0 ? t('ContactModal::Add another') : t('ContactModal::Add website')}
                        onClick={this.onWebsiteAddClick}
                      />
                    </InputWrapper>
                  )
                }}
              </Droppable>
            </div>
          </div>


          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ContactModal::Referral source')}
              </label>
              <CreatablePowerSelect
                options={referralSourceOptions}
                value={selectedReferralSourceOption}
                onChange={this.onReferralChange}
                onCreateOption={this.onReferralCreate}
                isClearable={true}
                formatCreateLabel={(inputValue) => t('ContactModal::Create referral source "{{input}}"', { input: inputValue })}
                components={{
                  Option: (props) => {
                    const { data: { value, __isNew__ } } = props

                    return (
                      <div className='powerselect-source-option-container'>
                        <components.Option {...props} />

                        {!__isNew__ && <a href='javascript://' className='powerselect-source-option-delete' onClick={() => this.onReferralSourceDelete(value)}>
                          <Icon icon='circle-xmark' />
                        </a>}
                      </div>
                    )
                  }
                }}
                theme={ReactSelectTheme}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ContactModal::Background info')}</label>
              <textarea
                onChange={this.onBackgroundInfoChange}
                value={contact.background_info}
                placeholder={t('ContactModal::Enter background information here...')}
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ContactModal::Billing address')}</label>

              <InputWrapper>
                <GroupedFormItem>
                  <input
                    type='text'
                    placeholder={t('ContactModal::Street')}
                    value={contact?.billing_address?.street}
                    onChange={e => this.onAdressStreetChange('billing_address', e.currentTarget.value)}
                  />

                  <AddressNumber
                    type='text'
                    placeholder={t('ContactModal::Number')}
                    value={contact?.billing_address?.street_number}
                    onChange={e => this.onAdressStreetNumberChange('billing_address', e.currentTarget.value)}
                  />
                </GroupedFormItem>

                <GroupedFormItem>
                  <input
                    type='text'
                    placeholder={t('ContactModal::City')}
                    value={contact?.billing_address?.city}
                    onChange={e => this.onAddressCityChange('billing_address', e.currentTarget.value)}
                  />
                  <input
                    type='text'
                    placeholder={t('ContactModal::State')}
                    value={contact?.billing_address?.state}
                    onChange={e => this.onAddressStateChange('billing_address', e.currentTarget.value)}
                  />
                  <input
                    type='text'
                    placeholder={t('ContactModal::Zip')}
                    value={contact?.billing_address?.zip}
                    onChange={e => this.onAddressZipChange('billing_address', e.currentTarget.value)}
                  />
                </GroupedFormItem>

                <GroupedFormItem>
                  <Select
                    name='countries'
                    options={countryOptions}
                    value={contact?.billing_address?.country}
                    onChange={e => this.onAddressCountryChange('billing_address', e.currentTarget.value)}
                  />
                </GroupedFormItem>
              </InputWrapper>
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <SpaceBetweenContainer>
                <label>
                  {t('ContactModal::Delivery address')}
                </label>

                <div style={{ marginBottom: 4 }}>
                  <Switch
                    name='delivery_address_same'
                    label={t('ContactModal::Same as billing')}
                    checked={contact.delivery_same_as_invoice_address}
                    onClick={this.onDeliverySameAsInvoiceAddressChange}
                  />
                </div>
              </SpaceBetweenContainer>


              {!contact?.delivery_same_as_invoice_address && <>
                <InputWrapper>
                  <GroupedFormItem>
                    <input
                      type='text'
                      placeholder={t('ContactModal::Street')}
                      value={contact?.delivery_address?.street}
                      onChange={e => this.onAdressStreetChange('delivery_address', e.currentTarget.value)}
                    />

                    <AddressNumber
                      type='text'
                      placeholder={t('ContactModal::Number')}
                      value={contact?.delivery_address?.street_number}
                      onChange={e => this.onAdressStreetNumberChange('delivery_address', e.currentTarget.value)}
                    />
                  </GroupedFormItem>

                  <GroupedFormItem>
                    <input
                      type='text'
                      placeholder={t('ContactModal::City')}
                      value={contact?.delivery_address?.city}
                      onChange={e => this.onAddressCityChange('delivery_address', e.currentTarget.value)}
                    />
                    <input
                      type='text'
                      placeholder={t('ContactModal::State')}
                      value={contact?.delivery_address?.state}
                      onChange={e => this.onAddressStateChange('delivery_address', e.currentTarget.value)}
                    />
                    <input
                      type='text'
                      placeholder={t('ContactModal::Zip')}
                      value={contact?.delivery_address?.zip}
                      onChange={e => this.onAddressZipChange('delivery_address', e.currentTarget.value)}
                    />
                  </GroupedFormItem>

                  <GroupedFormItem>
                    <Select
                      name='countries'
                      options={countryOptions}
                      value={contact?.delivery_address?.country}
                      onChange={e => this.onAddressCountryChange('delivery_address', e.currentTarget.value)}
                    />
                  </GroupedFormItem>
                </InputWrapper>
              </>}
            </div>
          </div>
        </div>
      </div >
    );
  }

  renderNotes() {
    const { t } = this.props
    const { activeTab, contact } = this.state;

    if (activeTab !== 'notes') return null;

    return (
      <div data-tab='notes'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item is-last'>
              <EditorContainer>
                <Editor
                  model={contact.notes}
                  onModelChange={this.onNotesChange}
                  config={{
                    ...NOTES_EDITOR_CONFIG,
                    placeholderText: t('ContactModal::Add notes (only visible for you)'),
                    heightMin: 120,
                    heightMax: 265
                  }}
                />
              </EditorContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInvoice() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { contact, currencies, activeTab } = this.state;

    if (activeTab !== 'invoice') return null;

    const vatLiabilityOptions = VATHelper.getVATLiabilityOptions()
    const selectedVatLiabilityOption = vatLiabilityOptions.find(option => option.value === contact.vat_liability)
    const localeOptions = LocaleHelper.getLocaleOptions()
    const selectedLocaleOption = localeOptions.find(option => option.value === contact.locale)
    const currencyOptions = currencies.map(c => ({ label: c.label, value: c.value }))
    const selectedCurrencyOption = currencies.find(option => option.value === contact.currency)
    const rateTypeOptions = SettingHelper.getRateTypeOptions()
    const selectedRateTypeOption = rateTypeOptions.find(option => option.value === contact.rate_type)

    return <div data-tab='invoice'>
      <div className='grid'>
        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>
              {t('ContactModal::VAT liability')}

              <Tooltip
                content={t('ContactModal::The selected VAT liability will determine which VAT rate applies on documents created for this contact.')}
                containerStyle={{ marginLeft: 8 }}
              />
            </label>
            <PowerSelect
              options={vatLiabilityOptions}
              value={selectedVatLiabilityOption}
              onChange={this.onVATLiabilityChange}
              noOptionsMessage={(value) => String(t('ContactModal::No VAT liability option found'))}
              theme={ReactSelectTheme}
            />
          </div>
        </div>
        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('ContactModal::Currency')}</label>
            <PowerSelect
              options={currencyOptions}
              value={selectedCurrencyOption}
              onChange={this.onCurrencyChange}
              noOptionsMessage={(value) => String(t('ContactModal::No currency option found'))}
              theme={ReactSelectTheme}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('ContactModal::Language')}</label>
            <PowerSelect
              options={localeOptions}
              value={selectedLocaleOption}
              onChange={this.onLocaleChange}
              noOptionsMessage={(value) => t('ContactModal::No language found.')}
              theme={ReactSelectTheme}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>
              {t('ContactModal::Rate determined by')}
              <Tooltip
                content={t('ContactModal::Determines what rate will be applied when logging time.')}
                containerStyle={{ marginLeft: 8 }}
              />
            </label>
            <PowerSelect
              options={rateTypeOptions}
              value={selectedRateTypeOption}
              onChange={this.onRateTypeChange}
              noOptionsMessage={(value) => t('ContactModal::No invoice pricing found.')}
              theme={ReactSelectTheme}
            />
          </div>
        </div>

        {contact?.rate_type === RateType.FIXED && <>
          <div className='grid-cell with-12col'>
            <div className='grid'>

              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>
                    {t('ContactModal::Hourly rate')}

                    {contact?.hourly_rate === null && <Tooltip
                      content={t('ContactModal::The hourly rate is automatically inherited from your settings.')}
                      containerStyle={{ marginLeft: 8 }}
                    />}
                  </label>
                  <MoneyInput
                    name='hourly_rate'
                    currency={setting.default_currency}
                    numberFormat={setting.number_format}
                    placeholderValue={RateHelper.getRateForContact('hourly', contact)}
                    value={contact.hourly_rate}
                    onBlur={this.onHourlyRateChange}
                    optional
                  />
                </div>
              </div>

              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>
                    {t('ContactModal::Day rate')}

                    {contact?.day_rate === null && <Tooltip
                      content={t('ContactModal::The day rate is automatically inherited from your settings.')}
                      containerStyle={{ marginLeft: 8 }}
                    />}
                  </label>
                  <MoneyInput
                    name='day_rate'
                    currency={setting.default_currency}
                    numberFormat={setting.number_format}
                    placeholderValue={RateHelper.getRateForContact('day', contact)}
                    value={contact.day_rate}
                    onBlur={this.onDayRateChange}
                    optional
                  />
                </div>
              </div>
            </div>
          </div>
        </>}


        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>
              {t('ContactModal::Peppol ID')}
              <Tooltip
                content={t('ContactModal::Address of the contact on the Peppol network.')}
                containerStyle={{ marginLeft: 8 }}
              />
            </label>

            {!Boolean(contact.peppol_id) && <div style={{ marginBottom: 10 }}>
              <Alert
                type='warning'
                text={t('ContactModal::Peppol ID is required to send e-invoices to this contact.')}
              />
            </div>}

            <PeppolContainer>
              <input
                type='text'
                name='peppol_scheme'
                value={contact?.peppol_id?.split(':')[0] || ''}
                placeholder={t('ContactModal::Scheme')}
                readOnly
              />

              <input
                type='text'
                name='peppol_id'
                value={contact?.peppol_id?.split(':')[1] || ''}
                placeholder={t('ContactModal::Identifier')}
                readOnly
              />
              <PeppolActions>
                <PeppolAction data-tip={t('ContactModal::Find Peppol ID')} onClick={this.onSearchPeppolNetworkClick}>
                  <Icon icon='search' />
                </PeppolAction>
                {contact?.peppol_id && <PeppolAction data-tip={t('ContactModal::Clear')} onClick={this.onClearPeppolIdClick}>
                  <Icon icon='close' />
                </PeppolAction>}
              </PeppolActions>
            </PeppolContainer>
          </div>
        </div>
      </div>
    </div>
  }

  render() {
    const { contact, t } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <ClientModalWindow>
          <ModalHeader
            title={contact?.id ? t('ContactModal::Edit contact') : t('ContactModal::Create contact')}
            onCloseClick={this.onModalCloseClick}
            navigation={this.renderNavigation()}
          />

          {!didInitialLoad && <ModalLoader />}
          {didInitialLoad && <ModalMiddle>
            <ModalContent style={{ paddingLeft: 30, paddingRight: 30 }}>
              <form onSubmit={this.onFormSubmit}>
                {this.renderDetails()}
                {this.renderNotes()}
                {this.renderInvoice()}

                <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
              </form>
            </ModalContent>
          </ModalMiddle>}

          <ModalFooter>
            <div className='modal-footer'>
              <div />
              <div className='modal-footer-actions'>
                <div key='main-action' className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={this.onErrorsDismiss}
                  />
                  <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                    {t('ContactModal::Save')}
                  </a>
                </div>
              </div>
            </div>
          </ModalFooter>
        </ClientModalWindow>
      </DragDropContext>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      contactModal: {
        contact,
        activeTab,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    activeTab: activeTab,
    contact: contact,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeContactModal()),
    showPeppolParticipantModal: (options) => dispatch(showPeppolParticipantModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactModal))