import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { useTranslation } from 'react-i18next'
import ResourceListFilterComponent from './ResourceFilter'
import { ResourceFilter } from '../../types'
import { ResourceTableAction } from './ResourceTable'
import ResourceCheckbox from './ResourceCheckbox'
import Popover from '../Popover/Popover'
import ActionList from '../ActionList/ActionList'
import Icon from '../Icons/Icon'
import { useDebouncedCallback } from 'use-debounce'

const HeaderOuterWrapper = styled.div<{ hasBorder?: boolean }>`
	position: relative;
	border-bottom: 1px solid ${Style.color.border};
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`

const HeaderWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	padding: ${Style.spacing.x1} ${Style.spacing.x1_5};
	background-color: white;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`

const HeaderContentWrapper = styled.div`
	display: flex;
	width: 100%;
	opacity: 1;
	transition: opacity .2s cubic-bezier(.64,0,.35,1);
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: row;
		align-items: center;
	}
`

const ResourceLabelWrapper = styled.div`
	flex: 1 1 0%;
	display: block;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&:only-child {
		align-self: flex-start;
	}
`

const ActionsLeftContainer = styled.div`
	margin-right: ${Style.spacing.x0_5};
	min-width: fit-content;
`

const ResourceLabel = styled.div`
	margin-left: 20px;
	flex: 1 1 0%;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
	padding: 1px 0;
	font-size: 14px;
`

const HeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
	flex-wrap: wrap;
	margin-right: ${Style.spacing.x1};

	> * {
		margin-top: 4px;
		margin-bottom: 4px;
		margin-right: 4px;
	}
`

const HeaderRightContainer = styled.div`
	> * {
		margin-top: 4px;
		margin-bottom: 4px;
	}
`

const Actions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	flex-wrap: wrap;
	grid-gap: 4px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		align-items: initial;
	}
`

const Action = styled.div`
	display: flex;

	&:first-child {
		margin-left: 0;
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin: 0;
	}
`

const SortWrapper = styled.div`
	position: relative;
	display: flex;
	flex: 1 1 0%;
	align-items: center;
	left: auto;
	flex: 0 1 auto;
	margin-left: 16px;

	&:only-child {
		margin-left: 0;
		flex: 1;
		display: flex;
    justify-content: flex-end;
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-left: 0;
	}
`

const SortSelect = styled.div`
	position: relative;
`

const SortSelectInput = styled.select`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;
	letter-spacing: normal;
	position: absolute;
	text-rendering: auto;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	height: 100%;
	margin: 0;
	opacity: .001;
	appearance: none;
`

const SortContent = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;
	letter-spacing: normal;
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 5px 8px 5px 12px;
	min-height: 38px;
`

const SortInlineLabel = styled.div`
    color: #637381;
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
`

const SortSelectedOption = styled.div`
	flex: 1 1 0%;
	white-space: nowrap;
	overflow: hidden;
`

const SortSelectIcon = styled.div`
	display: block;
	height: 20px;
	width: 20px;
	max-height: 100%;
	max-width: 100%;
	margin: auto;

	svg {
		fill: #637381;
	}
`

const SortSelectBackdrop = styled.div`
	background-color: #c4cdd5;
	border: 1px solid transparent;
	box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
	border-radius: 3px;
	transition-property: box-shadow,background-color;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.64,0,.35,1);
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&::after {
		content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background: linear-gradient(180deg, #fff, #f9fafb);
    border-radius: 2px;
	}
`

const BulkActionsContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	margin-top: 4px;
`

const BulkActionsWrapper = styled.div`
	width: 100%;
`

const BulkActionsButtonGroupWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: auto;
	justify-content: flex-start;
	margin-right: ${Style.spacing.x1};
	max-width: 100%;
	flex-wrap: wrap;
	width: 100%;
`

const BulkActionButtonGroupItem = styled.div<{ hideOnMobile?: boolean, hideOnDesktop?: boolean, checkbox?: boolean }>`
	font-size: 15px;
	line-height: 15px;
	text-transform: initial;
	letter-spacing: initial;
	display: flex;
	align-items: center;
	min-height: 36px;
	min-width: 36px;
	margin: 0;
	padding: ${Style.spacing.x1} ${Style.spacing.x2};
	line-height: 1;
	cursor: pointer;
	text-decoration: none;
	text-align: left;
	background: white;
	box-shadow: rgba(0, 0, 0, .05) 0px 1px 0px 0px;
	border: 1px solid ${Style.color.border};
	border-top-color: ${Style.color.border};
	border-bottom-color: ${Style.color.border};

	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	&:not(:first-child):not(:last-child) {
		border-left: none;
	}

	&:not([style*="display: none"]):last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&:hover {
		background: #f6f6f7;
	}

	svg {
		width: 15px;
		height: 15px;
	}

	i {
		font-size: 15px;
	}

	svg, i {
		margin-right: 4px;
	}

	${props => props.checkbox && css`
		svg {
			width: 20px;
			height: 20px;
			margin-right: 0;
		}
	`}

	${props => props.hideOnMobile && css`
		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			display: none;
		}
	`}

	${props => props.hideOnDesktop && css`
		@media screen and (min-width: ${Style.breakpoints.SMALL}) {
			display: none;
		}
	`}
`

export const ResourceSearch = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	
	input {
		padding-left: 28px !important;
		padding-right: 32px !important;
		min-width: 160px;
	}
`

export const ResourceSearchIcon = styled.div`
	position: absolute;
	left: 8px;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
`

export const ResourceSearchCloseIcon = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	cursor: pointer;
	padding-left: 4px;
	padding-right: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
`

interface IProps<T> {
	data: T[]
	resourceLabel?: () => string | JSX.Element
	resourceLabelStyle?: React.CSSProperties
	actionsLeft?: JSX.Element[]
	actionsRight?: JSX.Element[]
	filters: ResourceFilter<T>[]
	activeFilters?: any
	sortValue?: string
	sortOptions?: { label: string, value: string }[]
	selectedItems?: string[]
	promotedBulkActions?: ResourceTableAction[]
	bulkActions?: ResourceTableAction[]
	searchValue?: string
	onSelectionChange?: (selectedItems: string[]) => void
	onSortChange?: (value: string) => void
	onFiltersChange?: (filters: any) => void
	onSearchChange?: (searchValue: string) => void
	onSearchSubmit?: (searchValue: string) => void
}

interface IState {
	bulkActionPopoverActive: boolean
	mobileActionsPopoverActive: boolean
}

const ResourceHeader = (props: IProps<any>) => {
	const { t } = useTranslation()
	const [state, setState] = React.useState<IState>({
		bulkActionPopoverActive: false,
		mobileActionsPopoverActive: false,
	})
	const {
		data,
		resourceLabel,
		resourceLabelStyle,
		actionsLeft,
		actionsRight,
		filters,
		activeFilters,
		onFiltersChange,
		sortOptions,
		sortValue,
		onSortChange,
		selectedItems,
		promotedBulkActions,
		bulkActions,
		searchValue,
		onSearchChange,
		onSearchSubmit,
	} = props
	const { bulkActionPopoverActive, mobileActionsPopoverActive } = state
	const itemsCount = data.length
	const fullySelected = selectedItems?.length === data?.length
	const partiallySelected = selectedItems?.length !== 0 && selectedItems?.length < data?.length
	const noneSelected = selectedItems?.length === 0
	const actions = [...(promotedBulkActions || []), ...(bulkActions || [])]

	const debouncedSearchValueChange = useDebouncedCallback(
		(searchValue: string) => props.onSearchChange(searchValue), 250
	);

	const onResourcesSelectionChange = () => {
		if (props.onSelectionChange) {
			if (noneSelected || partiallySelected) {
				props.onSelectionChange(data.map(item => item.id))
			} else {
				props.onSelectionChange([])
			}
		}
	}

	const onResourceSearchChange = (searchValue) => {
		if (onSearchChange) onSearchChange(searchValue)
	}

	const onResourceSearchKeyUp = (e) => {
		if (e.key === 'Enter' && onSearchSubmit) onSearchSubmit(e.currentTarget.value)
	}

	const onResourceSearchClear = (searchValue) => {
		onSearchSubmit(searchValue)
	}

	const onToggleBulkActionsPopover = () => {
		setState({ ...state, bulkActionPopoverActive: !bulkActionPopoverActive })
	}

	const onBulkActionsPopoverClose = () => {
		setState({ ...state, bulkActionPopoverActive: false })
	}

	const onToggleMobileActionsPopover = () => {
		setState({ ...state, mobileActionsPopoverActive: !mobileActionsPopoverActive })
	}

	const onMobileActionsPopoverClose = () => {
		setState({ ...state, mobileActionsPopoverActive: false })
	}

	let selectedOption = null

	if (sortOptions && sortValue) {
		selectedOption = sortOptions.find(sortOption => sortOption.value === sortValue)
	}

	return (
		<HeaderOuterWrapper>
			<HeaderWrapper>
				<HeaderContentWrapper>
					<HeaderLeftContainer>
						{resourceLabel && <ResourceLabelWrapper>
							{resourceLabel && <ResourceLabel
								style={{ marginLeft: 0, ...resourceLabelStyle }}
							>
								{typeof resourceLabel === 'function' ? resourceLabel() : resourceLabel}
							</ResourceLabel>}
						</ResourceLabelWrapper>}
						{actionsLeft &&
							<ActionsLeftContainer>
								<Actions>
									{actionsLeft.map(action => {
										return (
											<Action key={action.key}>
												{action}
											</Action>
										)
									})}
								</Actions>
							</ActionsLeftContainer>}
						{onSearchChange && <ResourceSearch>
							<ResourceSearchIcon>
								<Icon icon='search' />
							</ResourceSearchIcon>
							<input
								type='text'
								placeholder={t('ResourceHeader::Search...')}
								value={searchValue}
								onChange={(e) => onResourceSearchChange(e.currentTarget.value)}
								onKeyUp={onResourceSearchKeyUp}
							/>
							{searchValue?.length > 0 && <ResourceSearchCloseIcon onClick={() => onResourceSearchClear('')}>
								<Icon icon='close' />
							</ResourceSearchCloseIcon>}
						</ResourceSearch>}
						{filters && filters.length > 0 && <ResourceListFilterComponent
							filters={filters}
							activeFilters={activeFilters}
							onSubmit={onFiltersChange}
						/>}
					</HeaderLeftContainer>
					<HeaderRightContainer>
						{actionsRight && <Actions>
							{actionsRight.map(action => {
								return (
									<Action key={action.key}>
										{action}
									</Action>
								)
							})}
						</Actions>}
						{sortOptions && sortOptions.length > 0 && <SortWrapper>
							<SortSelect>
								<SortSelectInput onChange={(e) => onSortChange(e.currentTarget.value)} value={sortValue}>
									{sortOptions.map(option => {
										return (
											<option key={option.value} value={option.value} dangerouslySetInnerHTML={{ __html: option.label }} />
										);
									})}
								</SortSelectInput>
								<SortContent>
									<SortInlineLabel>{t('ResourceListHeader::Sort by')}</SortInlineLabel>
									<SortSelectedOption dangerouslySetInnerHTML={{ __html: selectedOption ? selectedOption.label : '-' }} />
									<SortSelectIcon>
										<svg viewBox='0 0 20 20' focusable='false' aria-hidden='true'><path d='M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z' fillRule='evenodd'></path></svg>
									</SortSelectIcon>
								</SortContent>
								<SortSelectBackdrop />
							</SortSelect>
						</SortWrapper>}
					</HeaderRightContainer>
				</HeaderContentWrapper>

				{selectedItems?.length > 0 && < BulkActionsContainer>
					<BulkActionsWrapper>
						<BulkActionsButtonGroupWrapper>
							<BulkActionButtonGroupItem onClick={onResourcesSelectionChange} checkbox={true}>
								<div style={{ marginLeft: -8 }}>
									<ResourceCheckbox
										checked={fullySelected || partiallySelected}
										partial={partiallySelected}
									/>
								</div>
								<span style={{ marginLeft: 12 }}>
									{t('ResourceHeader::{{count}} selected', { count: selectedItems?.length || 0 })}
								</span>
							</BulkActionButtonGroupItem>
							{promotedBulkActions && promotedBulkActions.map((promotedBulkAction, index) => {
								return (
									<BulkActionButtonGroupItem key={index} onClick={promotedBulkAction.onAction} hideOnMobile={true}>
										{promotedBulkAction.icon && <Icon icon={promotedBulkAction.icon} />}
										{promotedBulkAction.content}
									</BulkActionButtonGroupItem>
								)
							})}
							{bulkActions?.length > 0 && <Popover
								active={bulkActionPopoverActive}
								activator={
									<BulkActionButtonGroupItem onClick={onToggleBulkActionsPopover} hideOnMobile={true} style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
										{t('ResourceHeader::More actions')}
										<svg style={{ marginLeft: 4 }} viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path></svg>
									</BulkActionButtonGroupItem>
								}
								onClose={onBulkActionsPopoverClose}
								placement='bottom'
							>
								<ActionList
									actions={bulkActions.map((bulkAction, index) => ({ key: String(index), icon: bulkAction.icon, content: bulkAction.content, destructive: true }))}
									onClick={(key: string) => {
										onBulkActionsPopoverClose()
										bulkActions[Number(key)].onAction()
									}}
									hasIndicator
								/>
							</Popover>}
							{actions?.length > 0 && <Popover
								active={mobileActionsPopoverActive}
								activator={
									<BulkActionButtonGroupItem onClick={onToggleMobileActionsPopover} hideOnDesktop={true} style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
										{t('ResourceHeader::Actions')}
										<svg style={{ marginLeft: 4 }} viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path></svg>
									</BulkActionButtonGroupItem>
								}
								onClose={onMobileActionsPopoverClose}
								placement='bottom'
							>
								<ActionList
									actions={actions.map((action, index) => ({ key: String(index), icon: action.icon, content: action.content, destructive: action.destructive }))}
									onClick={(key: string) => {
										onMobileActionsPopoverClose()
										actions[Number(key)].onAction()
									}}
									hasIndicator
								/>
							</Popover>}
						</BulkActionsButtonGroupWrapper>
					</BulkActionsWrapper>
				</BulkActionsContainer>}
			</HeaderWrapper>
		</HeaderOuterWrapper >
	)
}

export default ResourceHeader