import Api from "./Api";
import Utils from "../utilities/Utils";
import { CustomField } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class CustomFieldsController extends Api {
  static getCustomFields(params: object) {
    return fetch(`/api/custom_fields?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(customField: CustomField) {
    return fetch("/api/custom_fields", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ custom_field: customField }),
    }).then((res) => res.json());
  }

  static update(customField: CustomField) {
    return fetch(`/api/custom_fields/${customField.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ custom_field: customField }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/custom_fields/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string; model?: string }) {
    return fetch(`/api/custom_fields/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
