import Api from "./Api";
import Utils from "../utilities/Utils";
import { Call } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class CallsController extends Api {
  static getCall(id: string) {
    return fetch(`/api/calls/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getCalls(params: object) {
    return fetch(`/api/calls?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(call: Call) {
    return fetch("/api/calls", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ call: call }),
    }).then((res) => res.json());
  }

  static update(call: Call) {
    return fetch(`/api/calls/${call.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ call: call }),
    }).then((res) => res.json());
  }

  static getForm(id?: string) {
    const params = { id: id };

    return fetch(`/api/calls/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/calls/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
