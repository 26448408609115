import * as React from 'react'
import Card from '../Card/Card'
import styled, { css } from 'styled-components'
import ResourceHeader from './ResourceHeader'
import ResourceFooter from './ResourceFooter'
import ResourceSpinner from './ResourceSpinner'
import ResourceTableRowHeader from './ResourceTableRowHeader'
import ResourceTableRow from './ResourceTableRow'
import { Pagination, ProjectProperty, ResourceFilter } from '../../types'
import ResourceCheckbox from './ResourceCheckbox'
import { IconTypes } from '../Icons/Icon'

const TableContainer = styled.div<{ maxHeight?: string }>`
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${props => props.maxHeight && css`
    max-height: ${props.maxHeight};
  `}
`

const Table = styled.table`
  min-width: 100%;
  width: 100%;
  border-collapse: collapse;
`

export interface ResourceTableHeader {
  title?: string,
  align?: 'left' | 'right' | 'center',
  colSpan?: number,
  sticky?: boolean,
  stickyTop?: number | string
  stickyRight?: number | string
  stickyBottom?: number | string
  stickyLeft?: number | string
  visible?: boolean
}

export interface ResourceTableAction {
  icon?: IconTypes
  content: string
  onAction: () => void
  destructive?: boolean
}

interface IProps<T> {
  style?: React.CSSProperties
  data: T[]
  resourceLabel?: () => string | string | JSX.Element
  resourceLabelStyle?: React.CSSProperties
  renderRow: (item: T, index: number) => JSX.Element
  renderEmpty?: any
  filters?: ResourceFilter<keyof T>[]
  activeFilters?: any
  actionsLeft?: JSX.Element[]
  actionsRight?: JSX.Element[]
  sortValue?: string
  sortOptions?: { label: string, value: string }[]
  pagination?: Pagination
  isLoading?: boolean

  headers?: ResourceTableHeader[]
  stickyHeader?: boolean
  maxHeight?: string
  selectedItems?: string[]
  promotedBulkActions?: ResourceTableAction[]
  bulkActions?: ResourceTableAction[]
  searchValue?: string
  onSelectionChange?: (selectedItems: string[]) => void
  onSortChange?: (value: string) => void
  onFiltersChange?: (filters: any) => void
  onSearchChange?: (value: string) => void
  onSearchSubmit?: (value: string) => void
  onPageChange?: (page: number) => void
}

const ResourceTable = (props: IProps<any>) => {
  const {
    data,
    resourceLabel,
    resourceLabelStyle,
    headers,
    renderRow,
    renderEmpty,
    filters,
    activeFilters,
    onFiltersChange,
    actionsLeft,
    actionsRight,
    sortOptions,
    sortValue,
    onSortChange,
    pagination,
    isLoading,
    style,
    stickyHeader,
    maxHeight,
    selectedItems,
    onSelectionChange,
    promotedBulkActions,
    bulkActions,
    searchValue,
    onSearchChange,
    onSearchSubmit,
    onPageChange
  } = props
  const itemsCount = data.length
  const fullySelected = selectedItems?.length === data?.length
  const partiallySelected = selectedItems?.length !== 0 && selectedItems?.length < data?.length
  const noneSelected = selectedItems?.length === 0

  const onResourcesSelectionChange = () => {
    if (props.onSelectionChange) {
      if (noneSelected || partiallySelected) {
        props.onSelectionChange(data.map(item => item.id))
      } else {
        props.onSelectionChange([])
      }
    }
  }

  return (
    <Card style={style}>
      <ResourceHeader
        filters={filters || []}
        activeFilters={activeFilters}
        onFiltersChange={onFiltersChange}
        actionsLeft={actionsLeft}
        actionsRight={actionsRight}
        sortOptions={sortOptions}
        sortValue={sortValue}
        resourceLabel={resourceLabel}
        resourceLabelStyle={resourceLabelStyle}
        data={data}
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
        promotedBulkActions={promotedBulkActions}
        bulkActions={bulkActions}
        onSortChange={onSortChange}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        onSearchSubmit={onSearchSubmit}
      />

      {itemsCount === 0 && renderEmpty}
      {itemsCount > 0 && <TableContainer maxHeight={maxHeight}>
        <Table>
          {headers && <ResourceTableRow header={true}>
            {onSelectionChange && <ResourceTableRowHeader key='header-checkbox' sticky={true} maxWidth='30px'>
              <ResourceCheckbox
                checked={fullySelected || partiallySelected}
                onCheckedChange={onResourcesSelectionChange}
                partial={partiallySelected}
              />
            </ResourceTableRowHeader>}
            {headers
              .filter(header => header.visible !== false)
              .map((header, index) => {
                const {
                  title,
                  align,
                  colSpan,
                  stickyTop,
                  stickyRight,
                  stickyBottom,
                  stickyLeft,
                  sticky,
                } = header
                return (
                  <ResourceTableRowHeader
                    key={index}
                    style={{ textAlign: align }}
                    colSpan={colSpan}
                    sticky={stickyHeader}
                    stickyTop={stickyTop}
                    stickyRight={stickyRight}
                    stickyBottom={stickyBottom}
                    stickyLeft={stickyLeft}
                  >
                    {title}
                  </ResourceTableRowHeader>
                )
              })}
          </ResourceTableRow>}

          {data && data.length > 0 && <tbody>
            {data.map((item, index) => renderRow(item, index))}
          </tbody>}
        </Table>
      </TableContainer>}

      <ResourceFooter
        itemCount={data.length}
        pagination={pagination}
        onPageChange={onPageChange}
      />

      {isLoading && <ResourceSpinner />}
    </Card >
  )
}

export default ResourceTable