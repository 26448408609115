import * as React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import Icon from '../components/Icons/Icon'
import ExpensesController from '../controllers/ExpensesController'
import { AppState } from '../store';
import { connect } from 'react-redux';
import Notification from '../utilities/Notification'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import moment from '../utilities/Moment'
import { Dispatch } from 'redux'
import { showExpenseModal, showConfirmModal, showExportExpensesModal, showShareLinksModal, showPaymentModal } from '../store/modals/actions'
import { RouteComponentProps } from 'react-router'
import UrlHelper from '../helpers/UrlHelper'
import ExpensesLastUpdated from '../components/Expenses/ExpensesLastUpdated'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import ExpenseYearTimeline from '../components/Expenses/ExpenseYearTimeline'
import ExpenseMonthlyTimeline from '../components/Expenses/ExpenseMonthlyTimeline/ExpenseMonthlyTimeline'
import ListLoader from '../components/Loaders/ListLoader'
import { Moment } from 'moment';
import LocalStorage, { LocalStorageKey } from '../LocalStorage';
import PageContent from '../components/Page/PageContent';
import { Helmet } from 'react-helmet';
import { WithTranslation, withTranslation } from 'react-i18next';
import PageHeader from '../components/Page/PageHeader';
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper';
import ExpenseHelper from '../helpers/ExpenseHelper';
import { ActiveStorageController } from '../controllers';
import ResourceTable from '../components/Resource/ResourceTable';
import ExpenseResourceItemRow from '../components/Expenses/ExpenseResourceItemRow';
import { CurrentUser, Expense, ExpenseFilter, ExpenseFilters, ExpenseStatus, Payment, ResourceListFilterType, UserWorkspaceSettingScope } from '../types';
import RouteHelper from '../helpers/RouteHelper';
import ERoute from '../ERoute';
import NavigationDetection from '../components/Effects/NavigationDetection';
import SearchHelper from '../helpers/SearchHelper';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showPaymentModal: typeof showPaymentModal
  showExpenseModal: typeof showExpenseModal
  showConfirmModal: typeof showConfirmModal
  showExportExpensesModal: typeof showExportExpensesModal
  showShareLinksModal: typeof showShareLinksModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation
interface IState {
  expenses: Expense[]
  page: number
  totalPages: number
  didInitialLoad: boolean
  sortValue: string
  search: string
  activeFilters: any
  isFetching: boolean
  graphMode: 'yearly' | 'monthly'
}


class Expenses extends React.Component<IProps, IState> {
  private yearTimeline = React.createRef<ExpenseYearTimeline>()
  private monthlyTimeline = React.createRef<ExpenseMonthlyTimeline>()
  private lastUpdatedExpenses = React.createRef<ExpensesLastUpdated>()

  constructor(props: IProps) {
    super(props);

    const { search, page, filters } = SearchHelper.getSearch(this.props.location.search, ExpenseFilters)

    this.state = {
      expenses: [],
      page: page,
      totalPages: 1,
      didInitialLoad: false,
      sortValue: LocalStorage.get(LocalStorageKey.EXPENSE_SORT_VALUE, 'invoiced_on_desc'),
      search: search,
      activeFilters: filters,
      isFetching: false,
      graphMode: 'monthly'
    }

    this.onNewExpensesClick = this.onNewExpensesClick.bind(this);
    this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)
    this.onTableExpenseClick = this.onTableExpenseClick.bind(this);
    this.onTableExpensePayClick = this.onTableExpensePayClick.bind(this);
    this.onTableExpenseEditClick = this.onTableExpenseEditClick.bind(this);
    this.onTableExpenseDeleteClick = this.onTableExpenseDeleteClick.bind(this);
    this.onTableExpenseActionClick = this.onTableExpenseActionClick.bind(this)
    this.onTableExpenseUpdate = this.onTableExpenseUpdate.bind(this)
    this.onFilesDropped = this.onFilesDropped.bind(this)
    this.onExpenseFiltersChange = this.onExpenseFiltersChange.bind(this)
    this.onExpenseSortValueChange = this.onExpenseSortValueChange.bind(this)
    this.onExpenseSearchValueChange = this.onExpenseSearchValueChange.bind(this)
    this.onExpenseSearchSubmit = this.onExpenseSearchSubmit.bind(this)
    this.onExpenseClearFiltersChange = this.onExpenseClearFiltersChange.bind(this)
    this.onExportExpensesClick = this.onExportExpensesClick.bind(this)
    this.onDeliverByEmailClick = this.onDeliverByEmailClick.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onRouteChange = this.onRouteChange.bind(this)

    this.onExpenseFormSubmit = this.onExpenseFormSubmit.bind(this)
    this.onAddExpenseTimelineClick = this.onAddExpenseTimelineClick.bind(this)
  }

  componentDidMount() {
    const { page } = this.state
    this.fetchExpenses(page);
    this.parseQueryParams()
  }

  onRouteChange() {
    const { search, page, filters } = SearchHelper.getSearch(this.props.location.search, ExpenseFilters)

    this.setState({
      search: search,
      page: page,
      activeFilters: filters
    }, () => {
      this.fetchExpenses(page)
    })
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.location.search !== this.props.location.search) {
      this.parseQueryParams()
    }
  }

  parseQueryParams() {
    const { showExpenseModal, location: { search } } = this.props
    const params = UrlHelper.getParams(search)

    if (params.id) {
      showExpenseModal({
        expense: {
          id: params.id
        },
        onSubmit: this.onExpenseFormSubmit
      })
    }
  }

  fetchExpenses(page = 1) {
    const { sortValue, activeFilters, search } = this.state

    this.setState({ isFetching: true })

    ExpensesController
      .getExpenses({ page: page, search: search, order: sortValue, ...activeFilters })
      .then(response => {
        const { expenses, current_page, total_pages } = response

        this.setState({
          expenses: [...expenses],
          didInitialLoad: true,
          page: current_page,
          totalPages: total_pages,
          isFetching: false,
        });
      })
      .catch(console.error)
  }

  updateExpense(expense: Expense, callback) {
    const { t } = this.props

    ExpensesController
      .update(expense)
      .then(response => {
        const { errors } = response;

        if (errors) {
          Notification.notifyError(t('Expenses::Oops something went wrong.'))
        }
        else {
          Notification.notifySuccess(t('Expenses::Expense successfully updated.'))
          callback(response);
        }

      })
      .catch(console.error)
  }

  deleteExpense(expense: Expense) {
    const { t } = this.props

    ExpensesController
      .delete(expense.id)
      .then(_response => {
        const { expenses } = this.state;

        const expenseIndex = expenses.findIndex(i => i.id === expense.id);

        expenses.splice(expenseIndex, 1);

        this.setState({ expenses: expenses });

        Notification.notifySuccess(t('Expenses::Expense successfully removed.'))

        if (this.yearTimeline && this.yearTimeline.current) this.yearTimeline.current.refresh()
        if (this.monthlyTimeline && this.monthlyTimeline.current) this.monthlyTimeline.current.refresh()
        if (this.lastUpdatedExpenses && this.lastUpdatedExpenses.current) this.lastUpdatedExpenses.current.refresh()
      })
  }

  onNewExpensesClick(e) {
    e.preventDefault();

    const { showExpenseModal } = this.props

    showExpenseModal({ onSubmit: this.onExpenseFormSubmit })
  }

  onPageHeaderActionClick(key: string) {
    switch (key) {
      case 'export_expenses': this.onExportExpensesClick()
        break
      case 'deliver_by_email': this.onDeliverByEmailClick()
        break
    }
  }

  onTableExpenseClick(expense: Expense) {
    const { showExpenseModal } = this.props

    showExpenseModal({
      expense: {
        id: expense.id
      },
      onSubmit: this.onExpenseFormSubmit
    })
  }

  onTableExpensePayClick(expense: Expense) {
    const { t } = this.props

    let paymentProps: Payment = {}

    if (!expense.payment_id) paymentProps = ExpenseHelper.buildPaymentFromExpense(expense)

    this.props.showPaymentModal({
      payment: { id: expense.payment_id, ...paymentProps },
      onSubmit: async (payment) => {
        try {
          const expenseWithPayment = { ...expense, payment_id: payment.id }
          this.onExpenseFormSubmit(expenseWithPayment)
          await ExpensesController.update(expenseWithPayment)
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onTableExpenseEditClick(expense: Expense) {
    const { showExpenseModal } = this.props

    showExpenseModal({
      expense: {
        id: expense.id
      },
      onSubmit: this.onExpenseFormSubmit
    })
  }

  onTableExpenseDeleteClick(expense: Expense) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Expenses::Delete expense'),
      description: t('Expenses::You are about to delete an expense. This expense will be permanently deleted. Are you sure?'),
      action: { label: t('Expenses::Delete'), isDestructive: true },
      onConfirm: () => {
        this.deleteExpense(expense)
      }
    })
  }

  onTableExpenseActionClick(key: string, expense: Expense) {
    switch (key) {
      case 'pay': this.onTableExpensePayClick(expense)
        break
      case 'edit': this.onTableExpenseEditClick(expense)
        break
      case 'delete': this.onTableExpenseDeleteClick(expense)
        break
      default:
        throw Error('[Track] Unimplemented onTableEntryActionClick')
    }
  }

  onTableExpenseUpdate(expense: Expense, index: number) {
    const { expenses } = this.state

    expenses[index] = expense

    this.setState({ expenses: [...expenses] })
  }

  onFilesDropped(files) {
    const { t } = this.props

    if (this.lastUpdatedExpenses && this.lastUpdatedExpenses.current) this.lastUpdatedExpenses.current.setUploading(true)

    const promises = files.map(file => {
      return new Promise((resolve, reject) => {
        ActiveStorageController.upload(file, async (error, blob) => {
          if (error) { console.error(error) }
          if (blob) {
            const { url } = await ActiveStorageController.getBlobUrl(blob)

            try {
              const response = await ExpensesController.create({
                name: blob.filename,
                net_total: 0,
                invoiced_on: moment(),
                attachment: blob.signed_id,
                attachment_file_name: blob.filename,
                attachment_content_type: blob.content_type,
                attachment_file_size: blob.byte_size,
                attachment_url: url,
              })
              resolve(response)
            } catch (ex) {
              console.error(ex)
              reject(ex)
            }
          }
        })
      })
    })

    Promise.all(promises).then(expenses => {
      const unauthorized = expenses.some((expense: any) => expense.type === 'unauthorized')

      if (unauthorized) {
        const index = expenses.findIndex((expense: any) => expense.type === 'unauthorized')

        const expense: any = expenses[index]
        if (expense.errors && expense.errors.quota) {
          Notification.notifyError(t('Expenses::Monthly limit reached. Upgrade your plan to continue'))
        }
      } else {
        expenses.forEach(expense => {
          const { expenses } = this.state
          this.setState({
            expenses: [expense, ...expenses],
          });
        })
        Notification.notifySuccess(t('Expenses::Expense successfully created.'))
      }
      if (this.lastUpdatedExpenses && this.lastUpdatedExpenses.current) {
        this.lastUpdatedExpenses.current.setUploading(false)
      }
    }).catch((error) => {
      console.error(error)
      Notification.notifyError(t('Expenses::Oops something went wrong.'))
    })
      .finally(() => {
        if (this.yearTimeline && this.yearTimeline.current) this.yearTimeline.current.refresh()
        if (this.monthlyTimeline && this.monthlyTimeline.current) this.monthlyTimeline.current.refresh()
        if (this.lastUpdatedExpenses && this.lastUpdatedExpenses.current) {
          this.lastUpdatedExpenses.current.refresh()
          this.lastUpdatedExpenses.current.setUploading(false)
        }
      })
  }

  onExpenseClearFiltersChange() {
    this.setState({
      page: 1,
      activeFilters: {},
      search: ''
    }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onExpenseFiltersChange(activeFilters: any) {
    this.setState({ page: 1, activeFilters: activeFilters }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onExpenseSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.EXPENSE_SORT_VALUE, value)
    this.setState({
      page: 1,
      sortValue: value
    }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onExpenseSearchValueChange(value: string) {
    this.setState({ search: value })
  }

  onExpenseSearchSubmit(value: string) {
    this.setState({ page: 1, search: value }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onExportExpensesClick() {
    this.props.showExportExpensesModal({})
  }

  onDeliverByEmailClick() {
    const { t } = this.props
    const email = ExpenseHelper.getExpenseMailboxEmail()

    this.props.showShareLinksModal({
      title: t('Expenses::Deliver by email'),
      shareableLinks: [
        { content: email, url: email }
      ]
    })
  }

  onPageChange(page: number) {
    this.setState({ page: page }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onExpenseFormSubmit(expense: Expense) {
    const { expenses } = this.state

    const expenseIndex = expenses.findIndex(e => e.id === expense.id)

    if (expenseIndex !== -1) {
      expenses[expenseIndex] = expense
      this.setState({
        expenses: [
          ...expenses,
        ]
      })
    } else {
      this.setState({
        expenses: [
          expense,
          ...expenses,
        ]
      })
    }

    if (this.yearTimeline && this.yearTimeline.current) this.yearTimeline.current.refresh()
    if (this.monthlyTimeline && this.monthlyTimeline.current) this.monthlyTimeline.current.refresh()
    if (this.lastUpdatedExpenses && this.lastUpdatedExpenses.current) this.lastUpdatedExpenses.current.refresh()
  }

  onAddExpenseTimelineClick(date: Moment) {
    const { showExpenseModal } = this.props

    showExpenseModal({
      expense: { invoiced_on: date },
      onSubmit: this.onExpenseFormSubmit
    })
  }

  renderExpenseGraph() {
    const { graphMode } = this.state
    const { currentUser: { workspace: { setting } } } = this.props

    if (graphMode === 'yearly') {
      return (
        <ExpenseYearTimeline
          ref={this.yearTimeline}
          setting={setting}
        />
      )
    } else if (graphMode === 'monthly') {
      return (
        <ExpenseMonthlyTimeline
          ref={this.monthlyTimeline}
          setting={setting}
          onAddExpenseClick={this.onAddExpenseTimelineClick}
        />
      )
    }
  }


  render(): JSX.Element {
    const { expenses, didInitialLoad, search, activeFilters, sortValue, isFetching, page, totalPages } = this.state;
    const { currentUser: { workspace: { setting } }, t } = this.props

    const filtersActive = (search.length > 0 || Object.keys(activeFilters).length > 0)
    const filters: ExpenseFilter[] = [
      { name: 'name', label: t('Expenses::Name'), type: ResourceListFilterType.STRING },
      {
        name: 'status',
        label: t('Expenses::Status'),
        type: ResourceListFilterType.SINGLE_OPTION,
        options: [
          { label: t('Expenses::Outstanding'), value: ExpenseStatus.OUTSTANDING },
          { label: t('Expenses::Overdue'), value: ExpenseStatus.OVERDUE },
          { label: t('Expenses::Paid'), value: ExpenseStatus.PAID },
        ]
      },
      { name: 'invoiced_on', label: t('Expenses::Invoice date'), type: ResourceListFilterType.DATE },
      { name: 'due_on', label: t('Expenses::Due date'), type: ResourceListFilterType.DATE },
      { name: 'net_total', label: t('Expenses::Amount (excl. VAT)'), type: ResourceListFilterType.NUMBER },
      { name: 'amount', label: t('Expenses::Amount'), type: ResourceListFilterType.NUMBER },
      { name: 'currency', label: t('Expenses::Currency'), type: ResourceListFilterType.STRING },
      { name: 'contact_id', label: t('Expenses::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
      { name: 'project_id', label: t('Expenses::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
      { name: 'supplier_id', label: t('Expenses::Supplier'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'company', isValidNewOption: () => false },
      { name: 'recurring', label: t('Expenses::Recurring'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Expenses::Yes'), value: String(true) }, { label: t('Expenses::No'), value: String(false) }] },
      { name: 'booked_on', label: t('Expenses::Booked on'), type: ResourceListFilterType.DATE },
      { name: 'paid_on', label: t('Expenses::Paid on'), type: ResourceListFilterType.DATE },
      { name: 'billable', label: t('Expenses::Billable'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Expenses::Yes'), value: String(true) }, { label: t('Expenses::No'), value: String(false) }] },
      { name: 'billed', label: t('Expenses::Billed'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Expenses::Yes'), value: String(true) }, { label: t('Expenses::No'), value: String(false) }] },
      { name: 'created_at', label: t('Expenses::Created date'), type: ResourceListFilterType.DATE },
    ]

    return (
      <>
        <Helmet>
          <title>{t('Expenses::{{__appName}} | Expenses')}</title>
        </Helmet>
        <NavigationDetection onRouteChange={this.onRouteChange} />
        <ScrollToTopOnMount />

        <TopNavigation
          icon='receipt'
          title={t('Expenses::Expenses')}
          action={
            <a key='new-contact' href='javascript://' className='button button-primary page-action' onClick={this.onNewExpensesClick}>
              <Icon icon='plus' />
              {t('Expenses::New expense')}
            </a>
          }
        />

        <PageContent>
          <PageHeader
            title={t('Expenses::Expenses')}
            mainActions={[
              { key: 'export_expenses', icon: 'download-circle', content: t('Expenses::Export expenses'), visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE_EXPORT) },
              { key: 'deliver_by_email', icon: 'email', content: t('Expenses::Deliver by email') },
            ]}
            onMainActionClick={this.onPageHeaderActionClick}
          />

          {this.renderExpenseGraph()}

          <ExpensesLastUpdated
            ref={this.lastUpdatedExpenses}
            onAddClick={this.onNewExpensesClick}
            onExpenseClick={(expense) => this.onTableExpenseClick(expense)}
            onFilesDropped={this.onFilesDropped}
            allowedFileTypes={ExpenseHelper.getExpenseDropzoneMimeTypes()}
            setting={setting}
          />

          {!didInitialLoad && <ListLoader />}
          {didInitialLoad && <ResourceTable
            headers={[
              { title: t('ExpensesTable::Details') },
              { title: '' },
              { title: t('ExpensesTable::Supplier') },
              { title: t('ExpensesTable::Invoice date'), align: 'right' },
              { title: t('ExpensesTable::Due date'), align: 'right' },
              { title: t('ExpensesTable::Status'), align: 'right' },
              { title: t('ExpensesTable::Amount (excl. VAT)'), align: 'right' },
              { title: t('ExpensesTable::Amount'), align: 'right' },
              { title: '', stickyRight: '0px' },
            ]}
            data={expenses}
            renderRow={(expense: Expense, index: number) => {
              return (
                <ExpenseResourceItemRow
                  key={expense.id}
                  expense={expense}
                  onClick={this.onTableExpenseClick}
                  dateFormat={setting.date_format}
                  numberFormat={setting.number_format}
                  onActionClick={this.onTableExpenseActionClick}
                  onUpdate={(expense) => this.onTableExpenseUpdate(expense, index)}
                />
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'expense'}
              description={filtersActive ? t('Expenses::No expenses found') : t('Expenses::No expenses have been created yet')}
              descriptionActionText={filtersActive ? t('Expenses::Clear filters') : t('Expenses::Add expense')}
              onDescriptionActionClick={filtersActive ? this.onExpenseClearFiltersChange : this.onNewExpensesClick}
            />}
            filters={filters}
            activeFilters={activeFilters}
            onFiltersChange={this.onExpenseFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('Expenses::Invoice date ↑'), value: 'invoiced_on_asc' },
              { label: t('Expenses::Invoice date ↓'), value: 'invoiced_on_desc' },
              { label: t('Expenses::Name (A-Z)'), value: 'name_asc' },
              { label: t('Expenses::Name (Z-A)'), value: 'name_desc' },
              { label: t('Expenses::Amount (excl. VAT) ↑'), value: 'net_total_asc' },
              { label: t('Expenses::Amount (excl. VAT) ↓'), value: 'net_total_desc' },
              { label: t('Expenses::Amount ↑'), value: 'amount_asc' },
              { label: t('Expenses::Amount ↓'), value: 'amount_desc' },
              { label: t('Expenses::Created at ↑'), value: 'created_at_asc' },
              { label: t('Expenses::Created at ↓'), value: 'created_at_desc' },
            ]}
            sortValue={sortValue}
            onSortChange={this.onExpenseSortValueChange}
            pagination={{ page: page, pageCount: totalPages }}
            onPageChange={this.onPageChange}
            searchValue={search}
            onSearchChange={this.onExpenseSearchValueChange}
            onSearchSubmit={this.onExpenseSearchSubmit}
            isLoading={isFetching}
            stickyHeader={true}
            maxHeight='65vh'
          />}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showPaymentModal: (options) => dispatch(showPaymentModal(options)),
    showExpenseModal: (options) => dispatch(showExpenseModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showExportExpensesModal: (options) => dispatch(showExportExpensesModal(options)),
    showShareLinksModal: (options) => dispatch(showShareLinksModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Expenses))