import * as React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import Icon from '../components/Icons/Icon'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { AppState } from '../store'
import { showProjectModal, showConfirmModal } from '../store/modals/actions'
import ERoute from '../ERoute'
import { RouteComponentProps, NavLink, Redirect, Route, Switch } from 'react-router-dom'
import ProjectHelper from '../helpers/ProjectHelper'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import ProjectTable from '../components/Projects/ProjectTable'
import PageContent from '../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import ProjectMonthlyTimeline, { ProjectMonthlyTimeline as ProjectMonthlyTimelineClass } from '../components/Projects/ProjectMonthlyTimeline/ProjectMonthlyTimeline'
import RouteHelper from '../helpers/RouteHelper'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CurrentUser, Project, ProjectStatus } from '../types'

interface IContentItem {
  key: string
  title: string
  status: ProjectStatus
  to: ERoute
  component: React.ComponentClass<any, any>
}

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showProjectModal: typeof showProjectModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<any> & WithTranslation

interface IState {
  content: IContentItem[]
  lists: string[] | null
}

class Projects extends React.Component<IProps, IState> {
  private timeline = React.createRef<ProjectMonthlyTimelineClass>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      content: [
        { key: ERoute.PATH_PROJECTS_PROPOSAL, title: ProjectHelper.getStatusTitle(ProjectStatus.PROPOSAL), status: ProjectStatus.PROPOSAL, to: ERoute.PATH_PROJECTS_PROPOSAL, component: ProjectTable },
        { key: ERoute.PATH_PROJECTS_ACTIVE, title: ProjectHelper.getStatusTitle(ProjectStatus.ACTIVE), status: ProjectStatus.ACTIVE, to: ERoute.PATH_PROJECTS_ACTIVE, component: ProjectTable },
        { key: ERoute.PATH_PROJECTS_COMPLETED, title: ProjectHelper.getStatusTitle(ProjectStatus.COMPLETED), status: ProjectStatus.COMPLETED, to: ERoute.PATH_PROJECTS_COMPLETED, component: ProjectTable },
        { key: ERoute.PATH_PROJECTS_CANCELLED, title: ProjectHelper.getStatusTitle(ProjectStatus.CANCELLED), status: ProjectStatus.CANCELLED, to: ERoute.PATH_PROJECTS_CANCELLED, component: ProjectTable },
      ],
      lists: null
    }

    this.refreshTimeline = this.refreshTimeline.bind(this)
    this.onNewProjectClick = this.onNewProjectClick.bind(this)
    this.onProjectFormSubmit = this.onProjectFormSubmit.bind(this)
    this.onProjectMonthlyItemClick = this.onProjectMonthlyItemClick.bind(this)
    this.onProjectFormUpdateSubmit = this.onProjectFormUpdateSubmit.bind(this)
  }

  refreshTimeline() {
    if (this.timeline.current) {
      this.timeline.current.refresh()
    }
  }

  onProjectFormSubmit(project: Project) {
    var event = new CustomEvent('project-create', { detail: project })
    document.dispatchEvent(event)

    this.refreshTimeline()
  }

  onProjectFormUpdateSubmit(project: Project) {
    var event = new CustomEvent('project-update', { detail: project })
    document.dispatchEvent(event)
    this.refreshTimeline()
  }

  onProjectMonthlyItemClick(id: string) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_PROJECT, { id: id }))
  }

  onNewProjectClick() {
    const { showProjectModal } = this.props

    showProjectModal({
      onSubmit: this.onProjectFormSubmit
    })
  }

  render() {
    const { location,
      currentUser: {
        workspace: {
          setting
        }
      },
      t
    } = this.props

    const { content } = this.state

    return (
      <>
        <Helmet>
          <title>{t('Projects::{{__appName}} | Projects')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='project-navigation'
          title={t('Projects::Projects')}
          action={
            <a key='new-project' href='javascript://' className='button button-primary page-action' onClick={this.onNewProjectClick}>
              <Icon icon='plus' />
              {t('Projects::New project')}
            </a>
          }
        />

        <PageContent>
          <ProjectMonthlyTimeline
            // @ts-ignore
            ref={this.timeline}
            setting={setting}
            onItemClick={this.onProjectMonthlyItemClick}
          />

          <div className='content-tabs'>
            {content.map((contentItem, index) => {
              return (
                <NavLink
                  key={contentItem.key}
                  to={contentItem.to}
                  className='content-tab'
                  activeClassName='is-active'
                >
                  {contentItem.title}
                  <div className='content-tab-indicator' />
                </NavLink>
              )
            })}
          </div>


          <div className='content-tab-content'>
            <Switch location={location}>
              {content.map((contentItem, index) => {
                return (
                  <Route
                    key={contentItem.key}
                    path={contentItem.to}
                    render={(routerProps) => (<contentItem.component
                      {...routerProps}
                      status={contentItem.status}
                      refreshTimeline={this.refreshTimeline}
                      urlRewriteEnabled
                    />)}
                  />
                )
              })}

              <Redirect from='/' to={ERoute.PATH_PROJECTS_ACTIVE} />
            </Switch>
          </div>
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Projects))