import * as React from 'react'
import { InvoicesController, TimeEntriesController } from '../../controllers'
import Notification from '../../utilities/Notification'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import { IActionListItem } from '../ActionList/ActionList'
import PageLoader from '../Page/PageLoader'
import { Dispatch } from 'redux'
import { showConfirmModal, showTimeEntryModal } from '../../store/modals/actions'
import { connect } from 'react-redux'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { Helmet } from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AppState } from '../../store'
import ButtonPanel from '../Button/ButtonPanel'
import { CurrentUser, ResourceListFilterType, TimeEntry, UserWorkspaceSettingScope } from '../../types'
import ResourceTable from '../Resource/ResourceTable'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import ResourceTableRow from '../Resource/ResourceTableRow'
import TimeEntryBillableIndicator from './TimeEntryBillableIndicator'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import moment from '../../utilities/Moment'
import TimeFormatter from '../../utilities/TimeFormatter'
import ResourceTableRowActions from '../Resource/ResourceTableRowActions'

export interface IComponentProps {
  contactId?: string
  projectId?: string
  taskId?: string
  dealId?: string
  showDate?: boolean
}

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showTimeEntryModal: typeof showTimeEntryModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IComponentProps & IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  timeEntries: TimeEntry[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  filters: any,
  sortValue: string
  isFetching: boolean
  searchValue: string
}

class TimeEntriesTable extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      timeEntries: [],
      currentPage: 1,
      totalPages: 1,
      didInitialLoad: false,
      sortValue: LocalStorage.get(LocalStorageKey.TIME_ENTRY_SORT_VALUE, 'started_at_desc'),
      isFetching: false,
      filters: {},
      searchValue: '',
    }

    this.onNewTimeEntryClick = this.onNewTimeEntryClick.bind(this)
    this.onTimeEntrySubmit = this.onTimeEntrySubmit.bind(this)
    this.onTimeEntryFilterChange = this.onTimeEntryFilterChange.bind(this)
    this.onTimeEntrySortValueChange = this.onTimeEntrySortValueChange.bind(this)
    this.onTimeEntrySearchChange = this.onTimeEntrySearchChange.bind(this)
    this.onTimeEntrySearchSubmit = this.onTimeEntrySearchSubmit.bind(this)
    this.onTimeEntryClearFilters = this.onTimeEntryClearFilters.bind(this)
  }

  getTitle() {
    const { t } = this.props

    return t('TimeEntriesTable::{{__appName}} | Timesheets')
  }

  componentDidMount() {
    this.fetchTimeEntries(1)
  }

  async fetchTimeEntries(page = 1) {
    const { sortValue, searchValue, filters } = this.state
    const { contactId, projectId, taskId, dealId } = this.props
    let parameters: any = {
      search: searchValue,
      order: sortValue,
      ...filters,
    };

    if (contactId) {
      parameters = {
        ...parameters,
        contact_id: contactId,
      }
    }

    if (projectId) {
      parameters = {
        ...parameters,
        project_id: projectId,
      }
    }

    if (taskId) {
      parameters = {
        ...parameters,
        task_id: taskId,
      }
    }

    if (dealId) {
      parameters = {
        ...parameters,
        deal_id: dealId,
      }
    }

    this.setState({ isFetching: true })

    try {
      const response = await TimeEntriesController.getEntries(page, parameters)
      const { current_page, total_pages, time_entries } = response

      this.setState({
        timeEntries: [...time_entries],
        currentPage: current_page,
        totalPages: total_pages,
        didInitialLoad: true,
        isFetching: false,
      });
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isFetching: false })
    }
  }

  updateTimeEntry(timeEntry, callback) {
    const { t } = this.props

    InvoicesController
      .update(timeEntry)
      .then(response => {
        const { errors } = response;

        if (errors) {
          Notification.notifyError(t('TimeEntriesTable::Oops something went wrong!'))
        }
        else {
          Notification.notifySuccess(t('TimeEntriesTable::Updated successfully'))
          callback(response);
        }
      });
  }

  deleteTimeEntry(timeEntry, callback) {
    const { t } = this.props
    TimeEntriesController
      .delete(timeEntry.id)
      .then(response => {
        Notification.notifySuccess(t('TimeEntriesTable::Deleted successfully'))
        callback(response);
      })
  }

  onNewTimeEntryClick() {
    const { contactId, projectId, taskId, dealId, showTimeEntryModal } = this.props

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: {
          contact_id: contactId,
          project_id: projectId,
          task_id: taskId,
          deal_id: dealId,
        },
        onSubmit: this.onTimeEntrySubmit,
      })
    })
  }

  onTimeEntrySubmit(timeEntry: TimeEntry) {
    const { contactId, projectId, taskId, dealId } = this.props
    const { timeEntries } = this.state

    const keep = ((timeEntry: TimeEntry) => {
      let keepAlive = true

      if (projectId) keepAlive = timeEntry.project_id === projectId
      if (contactId && keepAlive) keepAlive = timeEntry.contact_id === contactId
      if (taskId && keepAlive) keepAlive = timeEntry.task_id === taskId
      if (dealId && keepAlive) keepAlive = timeEntry.deal_id === dealId

      return keepAlive
    })

    const contactIndex = timeEntries.findIndex(c => c.id === timeEntry.id);

    if (contactIndex !== -1) {
      timeEntries[contactIndex] = timeEntry

      this.setState({ timeEntries: [...timeEntries].filter(keep) })
    } else {
      this.setState({ timeEntries: [timeEntry, ...timeEntries,].filter(keep) })
    }
  }

  onTableTimeEntryClick(timeEntry) {
    const { showTimeEntryModal } = this.props

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: { id: timeEntry.id },
        onSubmit: this.onTimeEntrySubmit
      })
    })
  }

  onTableTimeEntryEditClick(timeEntry: TimeEntry) {
    const { showTimeEntryModal } = this.props

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: { id: timeEntry.id },
        onSubmit: this.onTimeEntrySubmit
      })
    })
  }

  onTableTimeEntryDeleteClick(timeEntry: TimeEntry) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('TimeEntriesTable::Delete time entry'),
      description: t('TimeEntriesTable::You are about to delete a time entry. Deletion is permanent and irreversible. Are you sure?'),
      action: { label: t('TimeEntriesTable::Delete'), isDestructive: true },
      onConfirm: () => {
        this.deleteTimeEntry(timeEntry, () => {
          const { timeEntries } = this.state

          const timeEntryIndex = timeEntries.findIndex(i => i.id === timeEntry.id);
          timeEntries.splice(timeEntryIndex, 1);

          this.setState({
            timeEntries
          });
        })
      }
    })
  }

  onTableTimeEntryActionClick(key: string, timeEntry: TimeEntry) {
    switch (key) {
      case 'view': this.onTableTimeEntryClick(timeEntry)
        break
      case 'edit': this.onTableTimeEntryEditClick(timeEntry)
        break
      case 'delete': this.onTableTimeEntryDeleteClick(timeEntry)
        break
      default:
        throw Error('[TimeEntriesTable] Unimplemented onTableTimeEntryActionClick')
    }
  }

  onTimeEntryFilterChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchTimeEntries(1)
    })
  }

  onTimeEntrySortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.TIME_ENTRY_SORT_VALUE, value)

    this.setState({
      sortValue: value,
    }, () => {
      this.fetchTimeEntries(1)
    })
  }

  onTimeEntrySearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onTimeEntrySearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchTimeEntries(1))
  }

  onTimeEntryClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchTimeEntries(1))
  }

  render() {
    const { t, showDate } = this.props
    const { currentUser: { workspace: { setting } } } = this.props
    const { didInitialLoad, timeEntries, currentPage, totalPages, sortValue, isFetching, filters, searchValue } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <>
          <ResourceTable
            headers={[
              { title: '', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE), align: 'right' },
              { title: t('TimeTrackingOverview::Description') },
              { title: t('TimeTrackingOverview::Contact') },
              { title: t('TimeTrackingOverview::Project') },
              { title: t('TimeTrackingOverview::Date'), align: 'right', visible: Boolean(showDate) },
              { title: t('TimeTrackingOverview::Start'), align: 'right' },
              { title: t('TimeTrackingOverview::End'), align: 'right' },
              { title: t('TimeTrackingOverview::Duration'), align: 'right' },
              { title: '', stickyRight: '0px' },
            ]}
            data={timeEntries}
            renderRow={(timeEntry: TimeEntry) => {
              let actions: IActionListItem[] = [
                { key: 'view', icon: 'eye', content: t('TimeEntriesTable::View') },
                { key: 'edit', icon: 'edit-solid', content: t('TimeEntriesTable::Edit') },
                { key: 'delete', icon: 'trash-alt-solid', content: t('TimeEntriesTable::Delete'), destructive: true },
              ]

              const durationInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')

              return (
                <ResourceTableRow key={timeEntry.id}>
                  {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <ResourceTableRowData
                    onClick={() => this.onTableTimeEntryClick(timeEntry)}
                    textAlign='right'
                    maxWidth={20}
                    width={20}
                  >
                    <TimeEntryBillableIndicator timeEntry={timeEntry} />
                  </ResourceTableRowData>}
                  <ResourceTableRowData onClick={() => this.onTableTimeEntryEditClick(timeEntry)} maxWidth='150px' ellipse>
                    {timeEntry?.description?.length > 0 ? timeEntry.description : '-'}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableTimeEntryEditClick(timeEntry)} maxWidth='150px' ellipse>
                    {timeEntry?.contact?.name?.length > 0 ? timeEntry.contact.name : '-'}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableTimeEntryEditClick(timeEntry)} maxWidth='150px' ellipse>
                    {timeEntry?.project?.name?.length > 0 ? timeEntry.project.name : '-'}
                  </ResourceTableRowData>
                  {showDate && <ResourceTableRowData textAlign='right' onClick={() => this.onTableTimeEntryEditClick(timeEntry)}>
                    {moment(timeEntry.started_at).format(setting.date_format)}
                  </ResourceTableRowData>}
                  <ResourceTableRowData textAlign='right' onClick={() => this.onTableTimeEntryEditClick(timeEntry)}>
                    {moment(timeEntry.started_at).format('H:mm')}
                  </ResourceTableRowData>
                  <ResourceTableRowData textAlign='right' onClick={() => this.onTableTimeEntryEditClick(timeEntry)}>
                    {moment(timeEntry.ended_at).format('H:mm')}
                  </ResourceTableRowData>
                  <ResourceTableRowData
                    textAlign='right'
                    onClick={() => this.onTableTimeEntryClick(timeEntry)}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: TimeFormatter.durationFormatHighlighted(durationInSeconds, setting.time_format)
                      }}
                    />
                  </ResourceTableRowData>
                  <ResourceTableRowActions
                    actions={actions}
                    onActionClick={(key) => this.onTableTimeEntryActionClick(key, timeEntry)}
                    sticky={true}
                    stickyRight='0px'
                  />
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'stopwatch'}
              description={filtersActive ? t('TimeEntriesTable::No time entries found') : t('TimeEntriesTable::No time entries have been created yet')}
              descriptionActionText={filtersActive ? t('TimeEntriesTable::Clear filters') : t('TimeEntriesTable::Add new time entry')}
              onDescriptionActionClick={filtersActive ? this.onTimeEntryClearFilters : this.onNewTimeEntryClick}
            />}
            actionsLeft={[
              <ButtonPanel
                icon='plus'
                text={t('TimeEntriesTable::New entry')}
                onClick={this.onNewTimeEntryClick}
              />
            ]}
            filters={[
              { name: 'description', label: t('TimeEntriesTable::Description'), type: ResourceListFilterType.STRING },
              { name: 'contact_id', label: t('TimeEntriesTable::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
              { name: 'project_id', label: t('TimeEntriesTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
              { name: 'work_type_id', label: t('TimeEntriesTable::Work type'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'work_type', isValidNewOption: () => false },
              { name: 'user_id', label: t('TimeEntriesTable::User'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'user', isValidNewOption: () => false },
              { name: 'start', label: t('TimeEntriesTable::Start'), type: ResourceListFilterType.DATE },
              { name: 'end', label: t('TimeEntriesTable::Ended'), type: ResourceListFilterType.DATE },
              { name: 'rate', label: t('TimeEntriesTable::Rate'), type: ResourceListFilterType.NUMBER },
              { name: 'amount', label: t('TimeEntriesTable::Amount'), type: ResourceListFilterType.NUMBER },
              { name: 'billable', label: t('TimeEntriesTable::Billable'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('TimeEntriesTable::Yes'), value: String(true) }, { label: t('TimeEntriesTable::No'), value: String(false) }] },
              { name: 'billed', label: t('TimeEntriesTable::Billed'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('TimeEntriesTable::Yes'), value: String(true) }, { label: t('TimeEntriesTable::No'), value: String(false) }] },
              { name: 'active', label: t('TimeEntriesTable::Active'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('TimeEntriesTable::Yes'), value: String(true) }, { label: t('TimeEntriesTable::No'), value: String(false) }] },
              { name: 'created_at', label: t('TimeEntriesTable::Created date'), type: ResourceListFilterType.DATE },
            ]}
            onFiltersChange={this.onTimeEntryFilterChange}
            sortOptions={[
              { label: t('TimeEntriesTable::Started at ↑'), value: 'started_at_asc' },
              { label: t('TimeEntriesTable::Started at ↓'), value: 'started_at_desc' },
            ]}
            sortValue={sortValue}
            onSortChange={this.onTimeEntrySortValueChange}
            pagination={{ page: currentPage, pageCount: totalPages }}
            onPageChange={(page) => this.fetchTimeEntries(page)}
            isLoading={isFetching}
            searchValue={searchValue}
            onSearchChange={this.onTimeEntrySearchChange}
            onSearchSubmit={this.onTimeEntrySearchSubmit}
          />
        </>}
      </>
    );
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showTimeEntryModal: (options) => dispatch(showTimeEntryModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimeEntriesTable))