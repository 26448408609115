import SearchHelper from "../helpers/SearchHelper";
import { PlannedTask, PlannerData } from "../types";
import Utils from "../utilities/Utils";
import Api from "./Api";

class PlannerController extends Api {
  static getPlanning(params: object): Promise<PlannerData> {
    return fetch(`/api/planner?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static get(id: string): Promise<PlannedTask> {
    return fetch(`/api/planner/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(plannedTask: PlannedTask) {
    return fetch("/api/planner", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ planned_task: plannedTask }),
    }).then((res) => res.json());
  }

  static update(plannedTask: PlannedTask) {
    return fetch(`/api/planner/${plannedTask.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ planned_task: plannedTask }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/planner/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: object) {
    return fetch(`/api/planner/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}

export default PlannerController;
