import Api from "./Api";
import Utils from "../utilities/Utils";
import { UserWorkspaceSetting } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class UserWorkspaceSettingsController extends Api {
  static getUserWorkspaceSetting(id: string) {
    return fetch(`/api/user_workspace_settings/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getUserWorkspaceSettings(params: object) {
    return fetch(
      `/api/user_workspace_settings?${SearchHelper.getQueryString(params)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static create(user_workspace_setting: UserWorkspaceSetting) {
    return fetch("/api/user_workspace_settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ user_workspace_setting: user_workspace_setting }),
    }).then((res) => res.json());
  }

  static update(user_workspace_setting: UserWorkspaceSetting) {
    return fetch(`/api/user_workspace_settings/${user_workspace_setting.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ user_workspace_setting: user_workspace_setting }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/user_workspace_settings/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(
      `/api/user_workspace_settings/form?${Utils.encodeQueryData(params)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }
}
