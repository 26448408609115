import Api from "./Api";
import Utils from "../utilities/Utils";
import {
  BlobResponse,
  Expense,
  ExpenseExportType,
  ExpenseExtractionData,
  ExpenseMetadata,
  ExportExpenseParams,
} from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class ExpensesController extends Api {
  static getExpenses(params: object) {
    return fetch(`/api/expenses?${SearchHelper.getQueryString(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(expense: Expense) {
    return fetch("/api/expenses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ expense: expense }),
    }).then((res) => res.json());
  }

  static update(expense: Expense) {
    return fetch(`/api/expenses/${expense.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ expense: expense }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/expenses/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(id?: string) {
    const params = { id: id };

    return fetch(`/api/expenses/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getTimeline(mode: "yearly" | "monthly") {
    return fetch(`/api/expenses/timeline?mode=${mode}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static book(expense: Expense) {
    return fetch(`/api/expenses/${expense.id}/book`, {
      method: "POST",
      headers: {
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static importExpenses(data: any) {
    return fetch(`/api/expenses/import?${Utils.encodeQueryData(data)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static exportExpenses(
    params: ExportExpenseParams
  ): Promise<BlobResponse | { success: true }> {
    if (ExpenseExportType.RECEIPT === params.type) {
      return fetch(`/api/expenses/export?${Utils.encodeQueryData(params)}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }).then((res) => res.json());
    } else {
      return fetch(`/api/expenses/export?${Utils.encodeQueryData(params)}`, {
        method: "POST",
        headers: {
          Authorization: this.token,
        },
        credentials: "same-origin",
      }).then((res) => {
        return new Promise((resolve, reject) => {
          res.blob().then((blob) => {
            resolve({
              filename: res.headers
                .get("content-disposition")
                .match(/filename="(.+)"/)[1],
              blob: blob,
            });
          });
        });
      });
    }
  }

  static extractData(
    fileName: string,
    file: string | ArrayBuffer
  ): Promise<ExpenseExtractionData> {
    return fetch(`/api/expenses/extract`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ file_name: fileName, file: file }),
    }).then((res) => res.json());
  }

  static getMetadata(params: {
    supplier_id?: string;
  }): Promise<ExpenseMetadata> {
    return fetch(`/api/expenses/metadata?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static searchSuppliers(params: { name?: string; vat_number?: string }) {
    return fetch(
      `/api/expenses/search_suppliers?${SearchHelper.getQueryString(params)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }
}
