import * as React from 'react'
import { ProjectsController } from '../../controllers'
import Notification from '../../utilities/Notification'
import { withRouter, RouteComponentProps } from 'react-router'
import RouteHelper from '../../helpers/RouteHelper'
import ERoute from '../../ERoute';
import { Dispatch } from 'redux'
import { showBulkProjectStatusUpdateModal, showConfirmModal, showProjectModal } from '../../store/modals/actions'
import { connect } from 'react-redux'
import PageLoader from '../Page/PageLoader'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import { IActionListItem } from '../ActionList/ActionList'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import ResourceTable, { ResourceTableAction } from '../Resource/ResourceTable'
import ResourceTableRow from '../Resource/ResourceTableRow'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import ResourceTableRowActions from '../Resource/ResourceTableRowActions'
import { AppState } from '../../store'
import { CurrentUser, Project, ProjectBudgetType, ProjectFilter, ProjectFilters, ProjectStatus, ResourceListFilterType } from '../../types'
import ButtonPanel from '../Button/ButtonPanel'
import ProjectHelper from '../../helpers/ProjectHelper'
import NumberFormatter from '../../utilities/NumberFormatter'
import ReportProgressbar from '../ProgressBars/ReportProgressBar'
import { Style } from '../../styles'
import moment from '../../utilities/Moment'
import NavigationDetection from '../Effects/NavigationDetection'
import SearchHelper from '../../helpers/SearchHelper'

export interface IComponentProps {
  title: string
  status?: ProjectStatus
  onItemUpdated: () => void,
  onItemDeleted: () => void,
  contactId?: string
  refreshTimeline?: () => void
  primaryActionEnabled?: boolean
  urlRewriteEnabled?: boolean
}

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showProjectModal: typeof showProjectModal
  showConfirmModal: typeof showConfirmModal
  showBulkProjectStatusUpdateModal: typeof showBulkProjectStatusUpdateModal
}

type IProps = IStateToProps & IComponentProps & IDispatchToProps & RouteComponentProps<any> & WithTranslation

interface IState {
  projects: Project[]
  page: number
  totalPages: number
  didInitialLoad: boolean
  sortValue: string
  isFetching: boolean
  activeFilters: any
  search: string
  selectedProjectIds: string[]
}

class ProjectTable extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    const { search, page, filters, } = SearchHelper.getSearch(this.props.location.search, ProjectFilters)

    this.state = {
      projects: [],
      page: page,
      totalPages: 1,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.PROJECT_SORT_VALUE, '-'),
      activeFilters: filters,
      search: search,
      selectedProjectIds: []
    }

    this.onRouteChange = this.onRouteChange.bind(this)
    this.rehydratedFetch = this.rehydratedFetch.bind(this)
    this.onNewProjectClick = this.onNewProjectClick.bind(this)
    this.onEmptyInfoActionClick = this.onEmptyInfoActionClick.bind(this)
    this.onTableProjectActionClick = this.onTableProjectActionClick.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onTableProjectDeleteClick = this.onTableProjectDeleteClick.bind(this)
    this.onTableProjectClick = this.onTableProjectClick.bind(this)
    this.onProjectFiltersChange = this.onProjectFiltersChange.bind(this)
    this.onProjectSortValueChange = this.onProjectSortValueChange.bind(this)
    this.onProjectFormSubmit = this.onProjectFormSubmit.bind(this)
    this.onProjectUpdateFormSubmit = this.onProjectUpdateFormSubmit.bind(this)
    this.onProjectCreateEvent = this.onProjectCreateEvent.bind(this)
    this.onProjectSearchChange = this.onProjectSearchChange.bind(this)
    this.onProjectSearchSubmit = this.onProjectSearchSubmit.bind(this)
    this.onProjectClearFilters = this.onProjectClearFilters.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onResourceBulkUpdateStatusActionClick = this.onResourceBulkUpdateStatusActionClick.bind(this)
    this.onResourceBulkDeleteActionClick = this.onResourceBulkDeleteActionClick.bind(this)
  }

  getTitle() {
    const { status, t } = this.props

    switch (status) {
      case ProjectStatus.ACTIVE:
        return t('ProjectsTable::{{__appName}} | Active projects')
      case ProjectStatus.PROPOSAL:
        return t('ProjectsTable::{{__appName}} | Proposal projects')
      case ProjectStatus.COMPLETED:
        return t('ProjectsTable::{{__appName}} | Completed projects')
      case ProjectStatus.CANCELLED:
        return t('ProjectsTable::{{__appName}} | Cancelled projects')
      default:
        return t('ProjectsTable::{{__appName}} | Projects')
    }
  }

  componentDidMount() {
    const { page } = this.state

    this.fetchProjects(page)

    document.addEventListener('project-create', this.onProjectCreateEvent)
  }

  componentWillUnmount() {
    document.removeEventListener('project-create', this.onProjectCreateEvent)
  }

  onRouteChange() {
    const { search, page, filters } = SearchHelper.getSearch(this.props.location.search, ProjectFilters)

    this.setState({ search: search, page: page, activeFilters: filters }, () => {
      this.fetchProjects(page)
    })
  }

  rehydratedFetch() {
    const { urlRewriteEnabled } = this.props
    const { page, activeFilters, search, sortValue } = this.state

    if (urlRewriteEnabled) {
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    } else {
      this.fetchProjects(page)
    }
  }

  fetchProjects(page: number) {
    const { contactId, status } = this.props
    const { search, sortValue, activeFilters } = this.state

    this.setState({ isFetching: true })

    let params: any = {
      page: page,
      search: search,
      order: sortValue,
      ...activeFilters,
    }

    if (contactId) params.contact_id = contactId
    if (status) params.status = status

    ProjectsController
      .getProjects(params)
      .then(response => {
        const { current_page, total_pages, projects } = response

        this.setState({
          projects: [...projects],
          page: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
        });
      }).catch(console.error)
  }

  onTableProjectActionClick(key: string, project: Project) {
    switch (key) {
      case 'edit': this.onTableProjectEditClick(project)
        break
      case 'delete': this.onTableProjectDeleteClick(project)
        break
      default:
        throw Error('[Projects] Unimplemented onTableProjectActionClick')
    }
  }

  onTableSelectionChange(selectedProjectIds: string[]) {
    this.setState({ selectedProjectIds: selectedProjectIds })
  }

  onTableRowSelectionChange(selected: boolean, transactionId: string) {
    const { selectedProjectIds } = this.state

    if (selected) {
      this.setState({
        selectedProjectIds: [
          ...selectedProjectIds,
          transactionId
        ]
      })
    } else {
      this.setState({
        selectedProjectIds: selectedProjectIds.filter(selectedProjectId => selectedProjectId !== transactionId)
      })
    }
  }

  onTableProjectEditClick(project) {
    const { showProjectModal } = this.props

    showProjectModal({
      project: { id: project.id },
      onSubmit: this.onProjectUpdateFormSubmit
    })
  }

  onTableProjectDeleteClick(project) {
    const { showConfirmModal, refreshTimeline, t } = this.props

    showConfirmModal({
      title: t('ProjectsTable::Delete project'),
      description: t('ProjectsTable::You are about to delete project <b>{{name}}</b>. Deleting a project also deletes all its associated data. Are you sure?', { name: project.name }),
      action: { label: t('ProjectsTable::Delete'), isDestructive: true },
      onConfirm: () => {
        ProjectsController
          .delete(project.id)
          .then(response => {
            const { projects, page } = this.state

            Notification.notifySuccess(t('ProjectsTable::Project deleted successfully'))

            const projectIndex = projects.findIndex(c => c.id === project.id);

            projects.splice(projectIndex, 1);

            this.setState({
              projects: [...projects]
            })

            if (refreshTimeline) refreshTimeline()
          })
          .catch(console.error)
      }
    })
  }

  onNewProjectClick() {
    const { showProjectModal, status, contactId } = this.props

    showProjectModal({
      project: {
        // If project status not 
        status: status ? status : ProjectStatus.ACTIVE,
        contact_id: contactId
      },
      statusDisabled: Boolean(status),
      contactDisabled: Boolean(contactId),
      onSubmit: this.onProjectFormSubmit
    })
  }

  onTableProjectClick(project: Project) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_PROJECT, { id: project.id }))
  }

  onProjectFiltersChange(activeFilters: any) {
    this.setState({ page: 1, activeFilters: activeFilters }, () => {
      this.rehydratedFetch()
    })
  }

  onProjectSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.PROJECT_SORT_VALUE, value)

    this.setState({ page: 1, sortValue: value, }, () => {
      this.rehydratedFetch()
    })
  }

  onProjectSearchChange(search) {
    this.setState({ search: search })
  }

  onProjectSearchSubmit(search) {
    this.setState({ search: search }, () => {
      this.rehydratedFetch()
    })
  }

  onProjectClearFilters() {
    this.setState({
      page: 1,
      search: '',
      activeFilters: {}
    }, this.rehydratedFetch)
  }

  onPageChange(page: number) {
    this.setState({ page: page }, () => {
      this.rehydratedFetch()
    })
  }

  onProjectFormSubmit(project: Project) {
    const { refreshTimeline } = this.props
    const { projects } = this.state

    if (!this.props.status || project.status === this.props.status) {
      this.setState({
        projects: [
          project,
          ...projects,
        ],
      })
    }

    if (refreshTimeline) refreshTimeline()
  }

  onProjectUpdateFormSubmit(project: Project) {
    const { status, refreshTimeline } = this.props
    const { projects } = this.state

    if (project.status === status) {
      const projectIndex = projects.findIndex(p => p.id === project.id);

      if (projectIndex !== -1) {
        projects[projectIndex] = project
        projects[projectIndex]
      }

      this.setState({
        projects: [
          ...projects,
        ]
      })
    } else {
      const projectIndex = projects.findIndex(c => c.id === project.id);

      projects.splice(projectIndex, 1);

      this.setState({
        projects: [...projects]
      })
    }

    if (refreshTimeline) refreshTimeline()
  }

  onProjectCreateEvent(e: CustomEvent) {
    const { detail: project } = e

    this.onProjectFormSubmit(project)
  }


  onEmptyInfoActionClick() {
    this.onNewProjectClick()
  }

  onResourceBulkUpdateStatusActionClick() {
    const { t, status } = this.props
    const { selectedProjectIds, projects } = this.state

    this.props.showBulkProjectStatusUpdateModal({
      excludeStatuses: status ? [status] : [],
      onSubmit: async (status) => {
        try {
          const response = await ProjectsController.statusUpdateAll(selectedProjectIds, status)

          if (response.errors) { }
          else {
            const newProjects = projects.filter(p => !selectedProjectIds.includes(p.id));

            this.setState({ projects: [...newProjects], selectedProjectIds: [] });

            Notification.notifySuccess(t('ProjectsTable::Project statuses succesfully updated'))
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onResourceBulkDeleteActionClick() {
    const { t } = this.props
    const { selectedProjectIds, projects } = this.state

    this.props.showConfirmModal({
      title: t('ProjectsTable::Bulk project deletion'),
      description: t('ProjectsTable::You are about to delete these projects. By deleting these projects you are also deleting all its associated data. Are you sure?'),
      action: { label: t('ProjectsTable::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await ProjectsController.deleteAll(selectedProjectIds)
          if (response.errors) { }
          else {
            const newProjects = projects.filter(p => !selectedProjectIds.includes(p.id));

            this.setState({ projects: [...newProjects], selectedProjectIds: [] });

            Notification.notifySuccess(t('ProjectsTable::Projects successfully deleted'))
          }

        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  render() {
    const {
      t,
      status,
      currentUser,
      primaryActionEnabled
    } = this.props
    const { workspace: { setting } } = currentUser
    const {
      didInitialLoad,
      projects,
      page,
      totalPages,
      isFetching,
      activeFilters,
      sortValue,
      search,
      selectedProjectIds
    } = this.state

    const filtersActive = search?.length > 0 || Object.keys(activeFilters).length > 0

    let emptyDescription = filtersActive ? t('ProjectsTable::No projects found') : t('ProjectsTable::No projects have been created yet')
    let emptyActionText = filtersActive ? t('ProjectsTable::Clear filters') : t('ProjectsTable::Add project')

    if (status === ProjectStatus.PROPOSAL) {
      emptyDescription = filtersActive ? t('ProjectsTable::No proposal projects found') : t('ProjectsTable::No proposal projects have been created yet')
      emptyActionText = filtersActive ? t('ProjectsTable::Clear filters') : t('ProjectsTable::Add potential project')
    } else if (status === ProjectStatus.ACTIVE) {
      emptyDescription = filtersActive ? t('ProjectsTable::No active projects found') : t('ProjectsTable::No active projects have been created yet')
      emptyActionText = filtersActive ? t('ProjectsTable::Clear filters') : t('ProjectsTable::Add active project')
    } else if (status === ProjectStatus.COMPLETED) {
      emptyDescription = filtersActive ? t('ProjectsTable::No completed projects found') : t('ProjectsTable::No completed projects have been created yet')
      emptyActionText = filtersActive ? t('ProjectsTable::Clear filters') : t('ProjectsTable::Add completed project')
    } else if (status === ProjectStatus.CANCELLED) {
      emptyDescription = filtersActive ? t('ProjectsTable::No cancelled projects found') : t('ProjectsTable::No cancelled projects have been created yet')
      emptyActionText = filtersActive ? t('ProjectsTable::Clear filters') : t('ProjectsTable::Add cancelled project')
    }

    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'tag', content: t('ProjectsTable::Update status'), onAction: this.onResourceBulkUpdateStatusActionClick },
      { icon: 'trash-alt-solid', content: t('ProjectsTable::Delete'), onAction: this.onResourceBulkDeleteActionClick }
    ]

    const filters: ProjectFilter[] = [
      { name: 'name', label: t('Project::Name'), type: ResourceListFilterType.STRING },
      { name: 'po_number', label: t('Project::PO number'), type: ResourceListFilterType.STRING, visible: setting.po_number_enabled },
      { name: 'contact_id', label: t('Project::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
      { name: 'billable_type', label: t('Project::Billing'), type: ResourceListFilterType.SINGLE_OPTION, options: ProjectHelper.getProjectBillableTypeOptions() },
      { name: 'hourly_rate', label: t('Project::Hourly rate'), type: ResourceListFilterType.NUMBER },
      { name: 'day_rate', label: t('Project::Day rate'), type: ResourceListFilterType.NUMBER },
      { name: 'budget', label: t('Project::Budget'), type: ResourceListFilterType.NUMBER },
      { name: 'budget_type', label: t('Project::Budget type'), type: ResourceListFilterType.SINGLE_OPTION, options: ProjectHelper.getBudgetTypeOptions() },
      { name: 'total_costs', label: t('Project::Costs'), type: ResourceListFilterType.NUMBER },
      { name: 'created_at', label: t('Project::Created date'), type: ResourceListFilterType.DATE },
    ]

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        <NavigationDetection onRouteChange={this.onRouteChange} />
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={projects}
          selectedItems={selectedProjectIds}
          onSelectionChange={this.onTableSelectionChange}
          promotedBulkActions={promotedBulkActions}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('ProjectTable::New project')}
              onClick={this.onNewProjectClick}
            />
          ] : null}
          headers={[
            { title: t('ProjectTable::Name') },
            { title: t('ProjectTable::Contact') },
            { title: t('ProjectTable::Start date'), align: 'right' },
            { title: t('ProjectTable::End date'), align: 'right' },
            { title: t('ProjectTable::Budget') },
            { title: t('ProjectTable::Spent'), align: 'right' },
            { title: '', align: 'right' },
            { title: t('ProjectTable::Remaining'), align: 'right' },
            { title: t('ProjectTable::Costs'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          renderRow={(project: Project) => {
            let actions: IActionListItem[] = [
              { key: 'edit', icon: 'edit-solid', content: t('ProjectsTable::Edit') },
              { key: 'delete', icon: 'trash-alt-solid', content: t('ProjectsTable::Delete'), destructive: true }
            ]

            return (
              <ResourceTableRow
                key={project.id}
                selected={selectedProjectIds.includes(project.id)}
                onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, project.id)}
              >
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} maxWidth='300px' ellipse>
                  {project.name}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} maxWidth='300px' ellipse>
                  {project.contact ? project.contact.name : ''}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} textAlign='right'>
                  {project.start_date ? moment(project.start_date).format(setting.date_format) : ''}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} textAlign='right'>
                  {project.end_date ? moment(project.end_date).format(setting.date_format) : ''}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)}>
                  {project.budget_type === ProjectBudgetType.TOTAL_COSTS && NumberFormatter.formatCurrency(project?.contact?.currency || setting.default_currency, setting.number_format, project.budget)}
                  {project.budget_type === ProjectBudgetType.TOTAL_HOURS && NumberFormatter.formatNumber(setting.number_format, project.budget_hours)}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} textAlign='right'>
                  {project.budget_type === ProjectBudgetType.TOTAL_COSTS && NumberFormatter.formatCurrency(project?.contact?.currency || setting.default_currency, setting.number_format, project.budget_spent)}
                  {project.budget_type === ProjectBudgetType.TOTAL_HOURS && NumberFormatter.formatNumber(setting.number_format, project.budget_spent)}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} textAlign='right'>
                  {project.budget_type !== ProjectBudgetType.NONE && <div style={{ minWidth: 100 }}>
                    {project.budget_type === ProjectBudgetType.TOTAL_COSTS && <ReportProgressbar
                      items={[
                        {
                          value: Number(project.budget_spent),
                          tooltipLabel: () => t('ProjectTable::{{budgetSpent}} spent', { budgetSpent: NumberFormatter.formatCurrency(project?.contact?.currency || setting.default_currency, setting.number_format, project.budget_spent) }),
                          color: project.budget_remaining > 0 ? Style.color.budgetColor : Style.color.brandDanger
                        },
                        {
                          value: Number(project.budget_remaining),
                          tooltipLabel: () => t('ProjectTable::{{budgetRemaining}} remaining', { budgetRemaining: NumberFormatter.formatCurrency(project?.contact?.currency || setting.default_currency, setting.number_format, project.budget_remaining) }),
                          color: Style.color.budgetTrail
                        },
                      ]}
                    />}
                    {project.budget_type === ProjectBudgetType.TOTAL_HOURS && <ReportProgressbar
                      items={[
                        {
                          value: Number(project.budget_spent),
                          tooltipLabel: () => t('ProjectTable::{{budgetSpent}} hrs spent', { budgetSpent: project.budget_spent }),
                          color: project.budget_remaining > 0 ? Style.color.budgetColor : Style.color.brandDanger
                        },
                        {
                          value: Number(project.budget_remaining),
                          tooltipLabel: () => t('ProjectTable::{{budgetRemaining}} hrs remaining', { budgetRemaining: project.budget_remaining }),
                          color: Style.color.budgetTrail
                        },
                      ]}
                    />}
                  </div>}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} textAlign='right'>
                  {project.budget_type === ProjectBudgetType.TOTAL_COSTS && `${NumberFormatter.formatCurrency(project?.contact?.currency || setting.default_currency, setting.number_format, project.budget_remaining)} (${(project.budget_remaining / project.budget * 100).toFixed(2)}%)`}
                  {project.budget_type === ProjectBudgetType.TOTAL_HOURS && `${NumberFormatter.formatNumber(setting.number_format, project.budget_remaining)} (${(project.budget_remaining / project.budget_hours * 100).toFixed(2)}%)`}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableProjectClick(project)} textAlign='right'>
                  {NumberFormatter.formatCurrency(project?.contact?.currency || setting.default_currency, setting.number_format, project.total_costs)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableProjectActionClick(key, project)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'briefcase'}
            description={emptyDescription}
            descriptionActionText={emptyActionText}
            onDescriptionActionClick={filtersActive ? this.onProjectClearFilters : this.onEmptyInfoActionClick}
          />}
          filters={filters}
          activeFilters={activeFilters}
          onFiltersChange={this.onProjectFiltersChange}
          sortOptions={[
            { label: '-', value: '-' },
            { label: t('ProjectsTable::Name (A-Z)'), value: 'name_asc' },
            { label: t('ProjectsTable::Name (Z-A)'), value: 'name_desc' },
            { label: t('ProjectsTable::Start date ↑'), value: 'start_date_asc' },
            { label: t('ProjectsTable::Start date ↓'), value: 'start_date_desc' },
            { label: t('ProjectsTable::End date ↑'), value: 'end_date_asc' },
            { label: t('ProjectsTable::End date ↓'), value: 'end_date_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onProjectSortValueChange}
          pagination={{ page: page, pageCount: totalPages }}
          onPageChange={this.onPageChange}
          isLoading={isFetching}
          stickyHeader={true}
          searchValue={search}
          onSearchChange={this.onProjectSearchChange}
          onSearchSubmit={this.onProjectSearchSubmit}
          maxHeight='65vh'
        />}
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showBulkProjectStatusUpdateModal: (options) => dispatch(showBulkProjectStatusUpdateModal(options))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectTable)))