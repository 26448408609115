import * as React from 'react';
import copy from 'copy-to-clipboard';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import ActionList, { IActionListItem } from '../../components/ActionList/ActionList';
import BlockEditor from '../../components/BlockEditor/BlockEditor';
import BlockEditorSidebar from '../../components/BlockEditor/BlockEditorSidebar';
import BlockEditorSidebarSection from '../../components/BlockEditor/BlockEditorSidebarSection';
import Button from '../../components/Button/Button';
import PowerSelect from '../../components/Form/PowerSelect';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import PageContent from '../../components/Page/PageContent';
import PageLoader from '../../components/Page/PageLoader';
import Popover from '../../components/Popover/Popover';
import ActionCableConsumer from '../../consumers/ActionCableConsumer';
import FormsController from '../../controllers/FormsController';
import ERoute from '../../ERoute';
import { AppState } from '../../store';
import { CopyBlock, dracula } from 'react-code-blocks';

import {
	createContentblock,
	deleteContentBlock,
	setInitialContentBlockEditorState,
	updateContentBlock
} from '../../store/content-blocks/actions';
import { Style } from '../../styles';
import Notification from '../../utilities/Notification';
import Utils from '../../utilities/Utils';
import { showConfirmModal, showCustomComponentModal } from '../../store/modals/actions';
import RouteHelper from '../../helpers/RouteHelper';
import DateInput from '../../components/Form/DateInput';
import moment from '../../utilities/Moment';
import ReactTooltip from 'react-tooltip';
import FormHelper from '../../helpers/FormHelper';
import { ContentBlock, ContentBlockResource, ContentBlockType, ContentBlockVariables, CurrencyOption, CurrentUser, DisplayableError, Form, FormChannelEvent, FormChannelEventType } from '../../types';
import Switch from '../../components/Form/Switch';
import ResourceCreatablePowerSelect from '../../components/Form/ResourceCreatablePowerSelect';
import BlockEditorSidebarActionSection from '../../components/BlockEditor/BlockEditorSidebarActionSection';
import Alert from '../../components/Alert/Alert';
import Icon from '../../components/Icons/Icon';
import Tooltip from '../../components/Tooltips/Tooltip';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		height: auto;
		flex-direction: column-reverse;
	}
`

const BlockEditorSidebarAction = styled.div`
	position: relative;
	width: 100%;

	> * {
		width: 100%;
	}

	.button {
		width: 100%;
	}
`

interface IStateToProps {
	currentUser: CurrentUser
	contentBlocks: ContentBlock[]
}

interface IDispatchToProps {
	setInitialContentBlockEditorState: typeof setInitialContentBlockEditorState
	createContentblock: typeof createContentblock
	updateContentBlock: typeof updateContentBlock
	deleteContentBlock: typeof deleteContentBlock
	showConfirmModal: typeof showConfirmModal
	showCustomComponentModal: typeof showCustomComponentModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation

interface IState {
	didInitialLoad: boolean
	form: Form | null
	currencies: CurrencyOption[]
	actionPopoverActive: boolean
	isSubmitting: boolean
	errors: DisplayableError[]
	previewModeEnabled: boolean
	variables: ContentBlockVariables
}

class FormEditor extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			didInitialLoad: false,
			form: null,
			currencies: [],
			actionPopoverActive: false,
			isSubmitting: false,
			errors: [],
			previewModeEnabled: false,
			variables: {}
		}

		this.onNameChange = this.onNameChange.bind(this)
		this.onEnabledChange = this.onEnabledChange.bind(this)
		this.onCurrencyChange = this.onCurrencyChange.bind(this)
		this.onActivationDateChange = this.onActivationDateChange.bind(this)
		this.onExpirationDateChange = this.onExpirationDateChange.bind(this)
		this.onResponseLimitChange = this.onResponseLimitChange.bind(this)
		this.onRedirectUrlChange = this.onRedirectUrlChange.bind(this)
		this.onRecaptchaEnabledChange = this.onRecaptchaEnabledChange.bind(this)
		this.onRecaptchaSecretChange = this.onRecaptchaSecretChange.bind(this)
		this.onTaskCreateEnabledChange = this.onTaskCreateEnabledChange.bind(this)
		this.onBoardChange = this.onBoardChange.bind(this)
		this.onBoardListChange = this.onBoardListChange.bind(this)
		this.onAssigneesChange = this.onAssigneesChange.bind(this)
		this.onFormSubmit = this.onFormSubmit.bind(this)
		this.debouncedFormSubmit = Utils.debounce(this.onFormSubmit, 500, false)
		this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
		this.onActionsClick = this.onActionsClick.bind(this)
		this.onActionPopoverClose = this.onActionPopoverClose.bind(this)
		this.onActionPopoverClick = this.onActionPopoverClick.bind(this)
		this.onDuplicateClick = this.onDuplicateClick.bind(this)
		this.onDeleteClick = this.onDeleteClick.bind(this)

		// Action cable
		this.onActionCableConnected = this.onActionCableConnected.bind(this)
		this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
		this.onActionCableReceived = this.onActionCableReceived.bind(this)

		this.onCreateBlock = this.onCreateBlock.bind(this)
		this.onUpdateBlock = this.onUpdateBlock.bind(this)
		this.onDeleteBlock = this.onDeleteBlock.bind(this)
	}

	componentDidMount() {
		this.fetchForm().catch(console.error)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.fetchForm().catch(console.error)
		}

		ReactTooltip.rebuild();
	}

	onActionCableConnected() {
		console.log('[FormChannel] connected')
	}

	onActionCableDisconnected() {
		console.log('[FormChannel] Disconnected')
	}

	onActionCableReceived(event: FormChannelEvent) {
		const { t } = this.props

		console.log('[FormChannel] received event', event)

		switch (event.type) {
			case FormChannelEventType.FORM_CREATE:
				// Not really fired since you can't subscribe on a form that hasn't been created yet
				break
			case FormChannelEventType.FORM_UPDATE:
				this.setState({ form: event.data.form })
				break
			case FormChannelEventType.FORM_DELETE:
				Notification.notifySuccess(t('Form::Form has been deleted'))
				this.props.history.replace(ERoute.PATH_FORMS)
				break
			case FormChannelEventType.CONTENT_BLOCK_CREATE:
				this.props.createContentblock(event.data.content_block)
				break
			case FormChannelEventType.CONTENT_BLOCK_UPDATE:
				this.props.updateContentBlock(event.data.content_block)
				break
			case FormChannelEventType.CONTENT_BLOCK_DELETE:
				this.props.deleteContentBlock(event.data.content_block_id)
				break
			default:
				console.warn('Unhandled event type: ' + event.type)
		}
	}

	onCreateBlock(contentBlock: ContentBlock) {
		this.props.createContentblock(contentBlock)
	}

	onUpdateBlock(contentBlock: ContentBlock) {
		this.props.updateContentBlock(contentBlock)
	}

	onDeleteBlock(contentBlock: ContentBlock) {
		this.props.deleteContentBlock(contentBlock.id)
	}

	async fetchForm() {
		try {
			const {
				form,
				currencies,
			} = await FormsController.getFormForm({ id: this.props.match.params.id })

			this.props.setInitialContentBlockEditorState({ contentBlocks: form.content_blocks })

			this.setState({
				didInitialLoad: true,
				form: form,
				currencies: currencies,
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	onNameChange(e) {
		const name = e.currentTarget.value

		this.setState({
			form: {
				...this.state.form,
				name: name,
			}
		}, this.debouncedFormSubmit)
	}

	onEnabledChange() {
		const { form } = this.state

		this.setState({
			form: {
				...this.state.form,
				enabled: !form.enabled,
			}
		}, this.debouncedFormSubmit)
	}

	onCurrencyChange(option) {
		this.setState({
			form: {
				...this.state.form,
				currency: option.value,
			}
		}, this.debouncedFormSubmit)
	}

	onActivationDateChange(value) {
		const activationDate = moment(value);

		this.setState({
			form: {
				...this.state.form,
				activation_date: activationDate.isValid() ? activationDate.format('YYYY-MM-DD') : null
			}
		}, this.debouncedFormSubmit)
	}

	onExpirationDateChange(value) {
		const expirationDate = moment(value);

		this.setState({
			form: {
				...this.state.form,
				expiration_date: expirationDate.isValid() ? expirationDate.format('YYYY-MM-DD') : null
			}
		}, this.debouncedFormSubmit)
	}

	onResponseLimitChange(e) {
		const newResponseLimit = e.currentTarget.value

		this.setState({
			form: {
				...this.state.form,
				response_limit: newResponseLimit
			}
		}, this.debouncedFormSubmit)
	}

	onRedirectUrlChange(e) {
		const newRedirectUrl = e.currentTarget.value

		this.setState({
			form: {
				...this.state.form,
				redirect_url: newRedirectUrl?.length === 0 ? null : newRedirectUrl
			}
		}, this.debouncedFormSubmit)
	}

	onRecaptchaEnabledChange() {
		const { form } = this.state

		const newRecaptchaEnabled = !form.recaptcha_enabled

		this.setState({
			form: {
				...this.state.form,
				recaptcha_enabled: newRecaptchaEnabled,
				recaptcha_secret: newRecaptchaEnabled ? form.recaptcha_secret : null
			}
		}, this.debouncedFormSubmit)
	}

	onRecaptchaSecretChange(e) {
		const newRecaptchaSecret = e.currentTarget.value

		this.setState({
			form: {
				...this.state.form,
				recaptcha_secret: newRecaptchaSecret
			}
		}, this.debouncedFormSubmit)
	}

	onTaskCreateEnabledChange(e) {
		e.preventDefault()

		const { form } = this.state

		const newTaskCreateEnabled = !form.task_create_enabled

		this.setState({
			form: {
				...this.state.form,
				task_create_enabled: newTaskCreateEnabled,
				board_id: newTaskCreateEnabled ? form.board_id : null,
				board_list_id: newTaskCreateEnabled ? form.board_list_id : null,
				task_assignee_ids: newTaskCreateEnabled ? form.task_assignee_ids : [],
			}
		}, this.debouncedFormSubmit)
	}

	onBoardChange(boardId, board) {
		const { form } = this.state

		this.setState({
			form: {
				...form,
				board_id: boardId,
				board_list_id: null,
			}
		}, this.debouncedFormSubmit)
	}

	onBoardListChange(listId, list) {
		const { form } = this.state

		this.setState({
			form: {
				...form,
				board_list_id: listId,
			}
		}, this.debouncedFormSubmit)
	}

	onAssigneesChange(assigneeIds) {
		const { form } = this.state

		this.setState({
			form: {
				...form,
				task_assignee_ids: assigneeIds,
			}
		}, this.debouncedFormSubmit)
	}

	debouncedFormSubmit() {
		this.onFormSubmit().catch(console.error)
	}

	async onFormSubmit(e?: any) {
		const { isSubmitting } = this.state
		if (e) e.preventDefault()

		if (!isSubmitting) {
			try {
				this.setState({ isSubmitting: true })

				const response = await FormsController.update(this.state.form)

				if (response.errors) {
					this.setState({ errors: response.errors })
				} else {
					this.setState({ errors: [] })
				}

				await this.fetchForm()
			} catch (ex) {
				console.error(ex)
			} finally {
				this.setState({ isSubmitting: false })
			}
		}
	}

	onTogglePreviewClick() {
		this.setState({ previewModeEnabled: !this.state.previewModeEnabled })
	}

	onActionsClick() {
		this.setState({ actionPopoverActive: true })
	}

	onActionPopoverClose() {
		this.setState({ actionPopoverActive: false })
	}

	async onActionPopoverClick(key: string) {
		this.setState({ actionPopoverActive: false })

		switch (key) {
			case 'copy-link':
				this.onCopyLinksClick()
				break
			case 'embed':
				this.onEmbedClick()
				break
			case 'duplicate':
				this.onDuplicateClick()
				break
			case 'delete':
				this.onDeleteClick()
				break
		}
	}

	async onCopyLinksClick() {
		const { t } = this.props
		const { form } = this.state

		try {
			copy(form.url)
			Notification.notifySuccess(t('Form::Copied to clipboard'))
		} catch (ex) {
			console.error(ex)
		}
	}

	onEmbedClick() {
		const { t, showCustomComponentModal } = this.props
		const { form } = this.state

		requestAnimationFrame(() => {
			showCustomComponentModal({
				title: t('FormEditor::Embed form'),
				component: <>
					<div className='form-item'>
						<Alert type='info' text={<>{t('FormEditor::Copy and paste this code snippet anywhere on your website to show this form.')}</>} />
					</div>

					<div className='form-item'>
						<CopyBlock
							text={`<iframe src="${form.embed_url}" width="760" height="400" frameborder="0"></iframe>`} language='html'
							showLineNumbers
							theme={dracula}
							codeBlock
						/>
					</div>

					<div className='form-item'>
						<p style={{ textAlign: 'center' }}>{t('FormEdit::Or have complete control over your form')}</p>
					</div>

					<div className='form-item'>
						<CopyBlock
							text={`<!-- modify this form HTML and place wherever you want your form -->\n<form \n   action="${form.url}"\n   method="POST">\n    <label>\n      Your email:\n     <input type="email" name="email">\n    </label>\n    <!-- your other form fields go here -->\n    <button type="submit">Send</button>\n</form>`} language='html'
							showLineNumbers
							theme={dracula}
							codeBlock
						/>
					</div>
				</>
			})
		})
	}

	async onDuplicateClick() {
		const { form } = this.state

		try {
			const response = await FormsController.duplicate(form.id)

			this.props.history.replace(RouteHelper.process(ERoute.PATH_FORM, { id: response.id }))
		} catch (ex) {
			console.error(ex)
		}
	}

	async onDeleteClick() {
		const { t, showConfirmModal } = this.props
		const { form } = this.state

		requestAnimationFrame(() => {
			showConfirmModal({
				title: t('FormEditor::Delete form'),
				description: t('FormEditor::You are about to delete this form. Deleting this form also deletes all its associated data. Are you sure?'),
				action: { label: t('FormEditor::Delete'), isDestructive: true },
				onConfirm: async () => {
					try {
						await FormsController.delete(form.id)
						this.props.history.replace(ERoute.PATH_FORMS)
					} catch (ex) {
						console.error(ex)
					}
				}
			})
		})
	}

	render() {
		const { t, contentBlocks, currentUser: { workspace: { setting } } } = this.props
		const {
			didInitialLoad,
			form,
			currencies,
			previewModeEnabled,
			actionPopoverActive
		} = this.state

		const editable = Boolean(form)

		const actions: IActionListItem[] = [
			{ key: 'copy-link', icon: 'link', content: t('Form::Copy link') },
			{ key: 'embed', icon: 'code', content: t('Form::Embed form') },
			{ key: 'duplicate', icon: 'duplicate', content: t('Form::Duplicate') },
			{ key: 'delete', icon: 'trash-alt-solid', content: t('Form::Delete'), destructive: true },
		]

		const currencyOptions = currencies.map(c => ({ label: c.label, value: c.value }))

		let selectedCurrencyOption = null
		if (form) {
			selectedCurrencyOption = currencies.find(option => option.value === form.currency)
		}

		return (
			<>
				<Container>
					{!didInitialLoad && <PageContent><PageLoader /></PageContent>}

					{didInitialLoad && <>
						<ActionCableConsumer
							channel={{ channel: 'FormChannel', id: form.id }}
							onConnected={this.onActionCableConnected}
							onDisconnected={this.onActionCableDisconnected}
							onReceived={this.onActionCableReceived}
						>
							<BlockEditor
								resource={ContentBlockResource.FORM}
								resourceId={form.id}
								blockTypes={[
									ContentBlockType.INPUT,
									ContentBlockType.TEXT,
									ContentBlockType.IMAGE,
								]}
								variables={{}}
								taxes={[]}
								currency={form.currency}
								numberFormat={setting.number_format}
								blocks={contentBlocks}
								previewModeEnabled={previewModeEnabled}
								editable={editable}
								onTogglePreviewClick={this.onTogglePreviewClick}
								onCreateBlock={this.onCreateBlock}
								onUpdateBlock={this.onUpdateBlock}
								onDeleteBlock={this.onDeleteBlock}
							/>

							<BlockEditorSidebar>
								<form onSubmit={this.onFormSubmit}>
									<BlockEditorSidebarSection>
										<div className='form-item'>
											<label>{t('Form::Name')}</label>
											<input
												type='text'
												value={form.name}
												onChange={this.onNameChange}
												placeholder={t('Form::Name')}
											/>
										</div>
										<div className='form-item'>
											<label>{t('Form::Enabled')}</label>
											<Switch
												name='submission_to_task'
												label={t('Form::Enabled')}
												onClick={this.onEnabledChange}
												checked={form.enabled}
											/>
										</div>
										{FormHelper.showCurrencyOption(form) && <div className='form-item'>
											<label>{t('Form::Currency')}</label>
											<PowerSelect
												options={currencyOptions}
												value={selectedCurrencyOption}
												onChange={this.onCurrencyChange}
												noOptionsMessage={(value) => String(t('Form::No currency option found'))}
												theme={ReactSelectTheme}
											/>
										</div>}
										<div className='form-item'>
											<label>{t('Form::Activation date')}</label>
											<DateInput
												name='activation_date'
												dateFormat={setting.date_format}
												timeFormat={false}
												value={moment(form.activation_date)}
												inputProps={{ placeholder: setting.date_format }}
												onChange={this.onActivationDateChange}
												closeOnSelect
											/>
										</div>
										<div className='form-item'>
											<label>{t('Form::Expiration date')}</label>
											<DateInput
												name='expiration_date'
												dateFormat={setting.date_format}
												timeFormat={false}
												value={moment(form.expiration_date)}
												inputProps={{ placeholder: setting.date_format }}
												onChange={this.onExpirationDateChange}
												closeOnSelect
											/>
										</div>

										<div className='form-item'>
											<label>{t('Form::Submission limit')}</label>
											<input
												type='number'
												min={1}
												value={form.response_limit}
												placeholder={t('Form::Not set')}
												onChange={this.onResponseLimitChange}
											/>
										</div>

										<div className='form-item'>
											<label>
												{t('Form::Redirect url')}
												<Tooltip
													content={t('Form::After successful submit, where should the user be redirected?')}
													containerStyle={{ marginLeft: 8 }}
												/>
											</label>
											<input
												type='text'
												value={form.redirect_url}
												onChange={this.onRedirectUrlChange}
												placeholder={t('Form::Add a redirect url')}
											/>
										</div>

										<div className='form-item'>
											<label>
												{t('Form::Custom recaptcha')}
												<Tooltip
													content={t('Form::reCAPTCHA provides spam protection. Providing a custom reCAPTCHA key will remove the reCAPTCHA redirect for legitimate users.')}
													containerStyle={{ marginLeft: 8 }}
												/>
											</label>
											<Switch
												name='recapatca_enabled'
												label={t('Form::Enabled')}
												onClick={this.onRecaptchaEnabledChange}
												checked={form.recaptcha_enabled}
											/>
										</div>

										{form.recaptcha_enabled && <div className='form-item'>
											<label>{t('Form::Recaptcha secret')}</label>
											<input
												type='text'
												value={form.recaptcha_secret}
												onChange={this.onRecaptchaSecretChange}
												placeholder={t('Form::reCAPTCHA secret key (optional)')}
											/>
										</div>}

										<div className='form-item'>
											<label>{t('Form::Submission to task')}</label>
											<Switch
												name='submission_to_task'
												label={t('Form::Enabled')}
												onClick={this.onTaskCreateEnabledChange}
												checked={form.task_create_enabled}
											/>
										</div>

										{form.task_create_enabled && <>
											<div className='form-item'>
												<label>{t('Form::Board')}</label>
												<ResourceCreatablePowerSelect
													type='board'
													value={form.board_id}
													onChange={this.onBoardChange}
													isClearable
												/>
											</div>
											<div className='form-item'>
												<label>{t('Form::List')}</label>
												<ResourceCreatablePowerSelect
													type='board_list'
													value={form.board_list_id}
													params={{ 'board_id': form.board_id }}
													isDisabled={!form.board_id}
													onChange={this.onBoardListChange}
													isClearable
												/>
											</div>
											<div className='form-item'>
												<label>{t('Form::Assignees')}</label>
												<ResourceCreatablePowerSelect
													type='user'
													value={form.task_assignee_ids}
													onChange={this.onAssigneesChange}
													isMulti
													isClearable
												/>
											</div>
										</>}
									</BlockEditorSidebarSection>
								</form>

								<BlockEditorSidebarActionSection>
									<BlockEditorSidebarAction>
										<Popover
											activator={
												<div style={{ position: 'relative' }}>
													<Button
														text={t('Form::Actions')}
														type='default'
														onClick={this.onActionsClick}
													/>

												</div>
											}
											active={actionPopoverActive}
											placement='top'
											onClose={this.onActionPopoverClose}
										>
											<ActionList
												actions={actions}
												onClick={(key) => this.onActionPopoverClick(key)}
												hasIndicator
											/>
										</Popover>
									</BlockEditorSidebarAction>
								</BlockEditorSidebarActionSection>
							</BlockEditorSidebar>
						</ActionCableConsumer>
					</>}
				</Container >
			</>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
		contentBlockEditor: {
			contentBlocks,
		}
	} = state

	return {
		currentUser: currentUser,
		contentBlocks: contentBlocks,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	setInitialContentBlockEditorState,
	createContentblock,
	updateContentBlock,
	deleteContentBlock,
	showConfirmModal,
	showCustomComponentModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormEditor))