import * as React from 'react'
import { closeExpenseModal, showAttachmentsViewerModal, showContactModal, showContactTypeModal, showExpenseCategoryModal, showPaymentModal, showPaymentQRModal } from '../../store/modals/actions'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { ActiveStorageController, ExpensesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import DateInput, { DateInput as DateInputClass } from '../Form/DateInput'
import ResourceModalDropzone from '../Form/ResourceModalDropzone'
import moment from '../../utilities/Moment'
import MoneyInput from '../Form/MoneyInput'
import UrlHelper from '../../helpers/UrlHelper'
import { components } from 'react-select'
import CreatablePowerSelect from '../Form/CreatablePowerSelect'
import ExpenseCategoryHelper from '../../helpers/ExpenseCategoryHelper'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import PowerSelect from '../Form/PowerSelect'
import { withTranslation, WithTranslation } from 'react-i18next'
import PercentInput from '../Form/PercentInput'
import Tooltip from '../Tooltips/Tooltip'
import NumberFormatter from '../../utilities/NumberFormatter'
import ExpenseHelper from '../../helpers/ExpenseHelper'
import CheckboxInput from '../Form/CheckboxInput'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import CustomFieldModalInputs from '../CustomFields/CustomFieldModalInputs'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Alert from '../Alert/Alert'
import VATHelper from '../../helpers/VatHelper'
import AttachmentPreviewer from './AttachmentsViewer/AttachmentsPreviewer'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import SidebarContainerComponent from '../Sidebar/SidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import TooltipError from '../Tooltips/ErrorTooltip'
import Button from '../Button/Button'
import SidebarFooter from '../Sidebar/SidebarFooter'
import Loader from '../Loaders/Loader'
import RecurringScheduleHelper from '../../helpers/RecurringScheduleHelper'
import GroupHeading from '../Powerselect/GroupHeading'
import { Contact, CurrentUser, CustomField, Expense, ExpenseCategory, ExpenseMetadata, ExpenseType, Payment, PreviewAttachment, Project, ProjectBillableType, ProjectStatus, RecurrenceUnit, RecurringSchedule, RemittanceInformationType } from '../../types'
import ProjectHelper from '../../helpers/ProjectHelper'
import { saveAs } from 'file-saver'
import RemittanceInformationInput from '../Form/RemittanceInformationInput'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  width: 100%;
`

const SidebarContainer = styled(SidebarContainerComponent)`
  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    width: 100%;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const PreviewContainer = styled.div`
  position: relative;
	height: 100%;
  width: 100%;
  pointer-events: all;
  cursor: auto;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;
  }
`

const PreviewDropzoneContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  pointer-events: all;

  > div {
    border: none;
    height: 100vh;
  }
`

const PreviewWrapper = styled.div<{ noPadding?: boolean }>`
 	padding: 48px 24px;
	outline: none !important;
  overflow: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.noPadding && css`
    padding: 0;
  `}



	img, iframe {
		border-radius: 3px;
		width: 100%;
    max-width: 100%;
    position: relative;
    transition: transform .15s,opacity .4s;
    cursor: auto;
    pointer-events: all;
	}

	iframe {
		height: 100vh;
		border: none;
	}
`

const PreviewAction = styled.div<{ destructive?: boolean }>`
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 9999;
  background: ${Style.color.brandDanger};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #edeff0;
  border: 1px solid ${Style.color.border};

  svg, i {
    color: black;
    fill: black;
  }

  svg {
    width: 20px;
  }

  i {
    font-size: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${props => props.destructive && css`
    background: ${Style.color.brandDanger};

    svg, i {
      color: white;
      fill: white;
    }
  `}
`

const PreviewActions = styled.div`
  position: absolute;
  bottom: 5px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${PreviewAction}:not(:last-child) {
    margin-right: ${Style.spacing.x1};
  }
`

const AttachmentExpand = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${Style.color.brandPrimary};
	color: white;
	border-radius: 50%;
	transition: transform 0.1s ease 0s;
	transform: translate(-50%, -50%) scale(0);

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		transform: translate(-50%, -50%) scale(1);
	}

	i {
		font-size: 20px;
		fill: currentColor;
	}
`

const AttachmentPreviewContainer = styled.div`
	position: relative;
	max-width: 100%;
  min-height: 250px;
	padding-bottom: 31px;
	cursor: pointer;
  display: none;

	&:hover {
		${AttachmentExpand} {
			transform: translate(-50%, -50%) scale(1);
		}
	}

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: block;
  }

  iframe {
    width: 100%;
    min-height: 250px;
  }
`

const AttachmentPreviewDelete = styled.a`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	background-color: ${Style.color.brandDanger};
	font-weight: 600;
	color: #FFF;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
`

const EditCategoryImage = styled.div`
  justify-self: flex-end;
  cursor: pointer;

  svg, i {
    color: rgb(204, 204, 204);
    fill: rgb(204, 204, 204);
  }
`

const ExtractionMetadata = styled.div`
  margin-top: ${Style.spacing.x1};

  a {
    text-decoration: underline;
  }
`

const ExtractionMetadataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const ExtractionMetadataContent = styled.div`
  .tooltip-info-icon {
    color: rgb(133, 100, 4);
  }
`

const MobileExpenseModalDropzone = styled.div`
  display: none;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: block;
  }
`

const PayWithQRCodeLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
  color: ${Style.color.brandPrimary};
  font-size: 14px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;
    
  }

  svg, i {
    margin-right: ${Style.spacing.x1};
    color: ${Style.color.brandPrimary};
    fill: ${Style.color.brandPrimary};
  }

  &:hover {
    text-decoration: underline;
  }

`

interface IStateToProps {
  currentUser: CurrentUser
  expense?: Expense
  supplierDisabled?: boolean
  contactDisabled?: boolean
  projectDisabled?: boolean
  onSubmit?: (expense: Expense) => void
}

interface IDispatchToProps {
  close: typeof closeExpenseModal
  showExpenseCategoryModal: typeof showExpenseCategoryModal
  showAttachmentsViewerModal: typeof showAttachmentsViewerModal
  showContactTypeModal: typeof showContactTypeModal
  showContactModal: typeof showContactModal
  showPaymentModal: typeof showPaymentModal
  showPaymentQRModal: typeof showPaymentQRModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  didInitialLoad: boolean
  expense: Expense | null
  expenseMetadata: ExpenseMetadata | null
  categories: ExpenseCategory[]
  currencies: { label: string, value: string, symbol: string }[]
  customFields: CustomField[]
  errors: any
  isSubmitting: boolean
  extractingData: boolean
}

class ExpenseModal extends React.Component<IProps, IState> {
  private netAmountInput = React.createRef<MoneyInput>()
  private amountInput = React.createRef<MoneyInput>()
  private fileUploadInput = React.createRef<HTMLInputElement>()
  private invoicedOnInput = React.createRef<DateInputClass>()
  private dueOnInput = React.createRef<DateInputClass>()
  private paidOnInput = React.createRef<DateInputClass>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      expense: null,
      expenseMetadata: null,
      categories: [],
      currencies: [],
      customFields: [],
      errors: {},
      isSubmitting: false,
      extractingData: false,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.fetchExtractionData = this.fetchExtractionData.bind(this)
    this.onNameChange = this.onNameChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onSupplierChange = this.onSupplierChange.bind(this);
    this.onRemittanceInformationChange = this.onRemittanceInformationChange.bind(this);
    this.onRemittanceInformationTypeChange = this.onRemittanceInformationTypeChange.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this)
    this.onCategoryCreate = this.onCategoryCreate.bind(this)
    this.onCategoryEditClick = this.onCategoryEditClick.bind(this)
    this.onCurrencyChange = this.onCurrencyChange.bind(this)
    this.onNotesChange = this.onNotesChange.bind(this)
    this.onInvoicedOnChange = this.onInvoicedOnChange.bind(this);
    this.onDueOnChange = this.onDueOnChange.bind(this);
    this.onPaidOnChange = this.onPaidOnChange.bind(this)
    this.onNetAmountChange = this.onNetAmountChange.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.onContactChange = this.onContactChange.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.onCustomFieldValueChange = this.onCustomFieldValueChange.bind(this)
    this.onBillableChange = this.onBillableChange.bind(this)
    this.onMarkupChange = this.onMarkupChange.bind(this)
    this.onBilledChange = this.onBilledChange.bind(this)
    this.onRecurringChange = this.onRecurringChange.bind(this)
    this.onRecurringScheduleUnitChange = this.onRecurringScheduleUnitChange.bind(this)
    this.onRecurringScheduleDayOfWeekChange = this.onRecurringScheduleDayOfWeekChange.bind(this)
    this.onRecurringScheduleDayOfMonthChange = this.onRecurringScheduleDayOfMonthChange.bind(this)
    this.onRecurringScheduleMonthOfYearChange = this.onRecurringScheduleMonthOfYearChange.bind(this)
    this.onRecurringScheduleStartDateChange = this.onRecurringScheduleStartDateChange.bind(this)
    this.onRecurringScheduleEndedOptionChange = this.onRecurringScheduleEndedOptionChange.bind(this)
    this.onRecurringScheduleEndDateChange = this.onRecurringScheduleEndDateChange.bind(this)
    this.onBookedOnChange = this.onBookedOnChange.bind(this)
    this.onFileChange = this.onFileChange.bind(this);
    this.onFileButtonClick = this.onFileButtonClick.bind(this);
    this.onFileDownloadClick = this.onFileDownloadClick.bind(this);
    this.onFileDeleteClick = this.onFileDeleteClick.bind(this);
    this.onFileExpandClick = this.onFileExpandClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onExpenseModalClose = this.onExpenseModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onExpenseCategoryModalSubmit = this.onExpenseCategoryModalSubmit.bind(this)
    this.onApplyDocumentTypeExtraction = this.onApplyDocumentTypeExtraction.bind(this)
    this.onApplyNetAmountExtraction = this.onApplyNetAmountExtraction.bind(this)
    this.onApplyAmountExtraction = this.onApplyAmountExtraction.bind(this)
    this.onApplySupplierExtraction = this.onApplySupplierExtraction.bind(this)
    this.onApplySupplierIbanExtraction = this.onApplySupplierIbanExtraction.bind(this)
    this.onApplyIssueDateExtraction = this.onApplyIssueDateExtraction.bind(this)
    this.onApplyDueDateExtraction = this.onApplyDueDateExtraction.bind(this)
    this.onApplyCurrencyExtraction = this.onApplyCurrencyExtraction.bind(this)
    this.onQRCodeClick = this.onQRCodeClick.bind(this)
    this.onPaymentClick = this.onPaymentClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    try {
      const { expense: propExpense } = this.props

      const response = await ExpensesController.getForm(propExpense.id)

      const { expense, categories, currencies, custom_fields } = response

      this.setState({
        expense: {
          ...expense,
          ...propExpense,
        },
        categories: categories,
        currencies: currencies,
        customFields: custom_fields,
        didInitialLoad: true
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  async fetchMetadata() {
    const { expense } = this.state
    try {
      const metadata = await ExpensesController.getMetadata({ supplier_id: expense.supplier_id })

      const metadataExpense = metadata.previous_supplier_expense

      this.setState({
        expenseMetadata: metadata,
        expense: {
          ...expense,
          category_id: metadataExpense && metadataExpense.category_id ? metadataExpense.category_id : expense.category_id,
        }
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async fetchExtractionData(fileName: string, file: string | ArrayBuffer) {
    const { extractingData } = this.state
    try {
      const { expense } = this.state

      if (!extractingData && !expense.extraction_data) {
        this.setState({ extractingData: true })

        const extractionData = await ExpensesController.extractData(fileName, file)

        this.setState({
          expense: {
            ...expense,
            extraction_data: extractionData
          }
        }, this.processExtractionData)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ extractingData: false })
    }
  }

  getCurrencyOptions() {
    return this.state.currencies.map((currency) => ({ label: currency.label, value: currency.value, symbol: currency.symbol }))
  }

  async processExtractionData() {
    const { expense } = this.state

    let updatedExpense = { ...expense }

    try {
      const extractionData = expense.extraction_data

      if (extractionData) {
        const {
          extractionDocumentType,
          extractionNetAmountField,
          extractionAmountField,
          extractionSupplierNameField,
          extractionSupplierVATNumberField,
          extractionIssueDateField
        } = this.getExtractionFields()
        const currencyOption = this.getExtractionCurrencyOption()

        if (extractionDocumentType) {
          updatedExpense = { ...updatedExpense, type: (extractionDocumentType as ExpenseType) }
        }

        // If no amount set yet, set the amount to the extracted value
        // Note: a prmpt will be shown if user data and extraction data do not match
        if (extractionNetAmountField && (expense.net_total === null || expense.net_total == 0)) {
          updatedExpense = { ...updatedExpense, net_total: extractionNetAmountField }
          if (this.netAmountInput.current) this.netAmountInput.current.value(extractionNetAmountField)
        }

        if (extractionAmountField && (expense.amount === null || Number(expense.amount) === 0)) {
          updatedExpense = { ...updatedExpense, amount: extractionAmountField }
          if (this.amountInput.current) this.amountInput.current.value(extractionAmountField)
        }

        // If no supplier selected yet, check if a supplier exists and set it else create and set it.
        // Note: a prompt will be shown if user data and extraction data do not match
        if ((extractionSupplierNameField || extractionSupplierVATNumberField) && !expense.supplier_id) {
          const suppliers = await ExpensesController.searchSuppliers({
            name: extractionSupplierNameField ? extractionSupplierNameField : null,
            vat_number: extractionSupplierVATNumberField && VATHelper.isValidEUVAT(extractionSupplierVATNumberField) ? extractionSupplierVATNumberField : null
          })

          if (suppliers.length > 0) {
            // Supplier found
            const supplier = suppliers[0]

            updatedExpense = { ...updatedExpense, supplier_id: supplier.id, supplier: supplier }
          }
        }

        // Note: a prompt will be shown if user data and extraction data do not match
        if (extractionIssueDateField) {
          const momentIssueDate = moment(extractionIssueDateField)

          updatedExpense = { ...updatedExpense, invoiced_on: momentIssueDate.isValid() ? momentIssueDate.format('YYYY-MM-DD') : null }
        }

        // Note: a prompt will be shown if expense currency data and extraction data do not match
        if (currencyOption) updatedExpense = { ...updatedExpense, currency: currencyOption.value }

        // Attempt to set the category based on a previous supplier
        this.setState({ expense: updatedExpense }, this.fetchMetadata)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  getExtractionFields() {
    const { expense } = this.state

    let extractionDocumentType = 'invoice'
    let extractionNetAmountField = null
    let extractionAmountField = null
    let extractionSupplierNameField = null
    let extractionSupplierVATNumberField = null
    let extractionSupplierIbanField = null
    let extractionCurrencyCodeField = null
    let extractionCurrencyOptionField = null
    let extractionIssueDateField = null
    let extractionDueDateField = null

    if (expense && expense.extraction_data) {
      switch (expense.extraction_data.type) {
        case 'invoice':
        case 'receipt':
          extractionNetAmountField = expense.extraction_data.total_exclusive
          extractionAmountField = expense.extraction_data.total_inclusive
          extractionIssueDateField = expense.extraction_data.issue_date
          extractionDueDateField = expense.extraction_data.type === 'invoice' ? expense.extraction_data?.due_date : null
          extractionSupplierNameField = expense.extraction_data.supplier_name
          extractionSupplierVATNumberField = expense.extraction_data.supplier_vat

          extractionSupplierIbanField = expense.extraction_data.type === 'invoice' ? expense.extraction_data.supplier_iban : null
          extractionCurrencyCodeField = expense.extraction_data.currency
          extractionCurrencyOptionField = this.getExtractionCurrencyOption(extractionCurrencyCodeField ? extractionCurrencyCodeField : null)
          break
        case 'ubl':
          const ublData = expense.extraction_data.data
          extractionDocumentType = ublData.type
          extractionNetAmountField = ublData.total_exclusive
          extractionAmountField = ublData.total_inclusive
          extractionIssueDateField = ublData.issue_date
          extractionDueDateField = ublData?.type === 'invoice' ? ublData.due_date : null
          extractionSupplierNameField = ublData?.supplier?.name || ''
          extractionSupplierVATNumberField = ublData?.supplier?.company_id || ''
          extractionSupplierIbanField = ''
          extractionCurrencyCodeField = ublData?.currency
          extractionCurrencyOptionField = this.getExtractionCurrencyOption(extractionCurrencyCodeField ? extractionCurrencyCodeField : null)

          break
      }
    }

    return {
      extractionDocumentType: extractionDocumentType,
      extractionNetAmountField: extractionNetAmountField,
      extractionAmountField: extractionAmountField,
      extractionIssueDateField: extractionIssueDateField,
      extractionDueDateField: extractionDueDateField,
      extractionSupplierNameField: extractionSupplierNameField,
      extractionSupplierVATNumberField: extractionSupplierVATNumberField,
      extractionSupplierIbanField: extractionSupplierIbanField,
      extractionCurrencyCodeField: extractionCurrencyCodeField,
      extractionCurrencyOptionField: extractionCurrencyOptionField,
    }
  }

  onNameChange(e) {
    const { expense } = this.state;

    const newName = e.currentTarget.value;

    this.setState({
      expense: {
        ...expense,
        name: newName,
      }
    });
  }

  onTypeChange(option) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        type: option ? option.value : null,
      }
    })
  }

  onSupplierChange(value?: string, supplier?: Contact) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        supplier_id: value,
        supplier: supplier,
      },
      expenseMetadata: null,
    }, this.fetchMetadata);
  }

  onRemittanceInformationChange(remittanceInformation: string) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        remittance_information: remittanceInformation,
      }
    })
  }

  onRemittanceInformationTypeChange(remittanceInformationType: RemittanceInformationType, remittanceInformation: string) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        remittance_information_type: remittanceInformationType,
        remittance_information: remittanceInformation,
      }
    })
  }

  onCategoryChange(categoryOption) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        category_id: categoryOption ? categoryOption.value : null,
      }
    })
  }

  onCategoryCreate(categoryName: string) {
    requestAnimationFrame(() => {
      this.props.showExpenseCategoryModal({
        category: { name: categoryName },
        onSubmit: this.onExpenseCategoryModalSubmit
      })
    })
  }

  onCategoryEditClick(id: string) {
    requestAnimationFrame(() => {
      this.props.showExpenseCategoryModal({
        category: { id: id },
        onSubmit: this.onExpenseCategoryModalSubmit
      })
    })
  }

  onCurrencyChange(currencyOption) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        currency: currencyOption ? currencyOption.value : null,
      }
    })
  }

  onNotesChange(e) {
    const { expense } = this.state;

    const notes = e.currentTarget.value

    this.setState({
      expense: {
        ...expense,
        notes: notes,
      }
    })
  }

  onBookedOnChange(value) {
    const { expense } = this.state;

    const bookedOn = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      expense: {
        ...expense,
        booked_on: bookedOn,
      },
    })
  }

  onInvoicedOnChange(paidOn) {
    const { expense } = this.state;

    const newInvoiceOn = moment(paidOn)

    this.setState({
      expense: {
        ...expense,
        invoiced_on: newInvoiceOn.isValid() ? newInvoiceOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onDueOnChange(dueOn) {
    const { expense } = this.state;

    const newDueOn = moment(dueOn)

    this.setState({
      expense: {
        ...expense,
        due_on: newDueOn.isValid() ? newDueOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onPaidOnChange(paidOn) {
    const { expense } = this.state;

    const newPaidOn = moment(paidOn)

    this.setState({
      expense: {
        ...expense,
        paid_on: newPaidOn.isValid() ? newPaidOn.format('YYYY-MM-DD') : null
      }
    });
  }


  onNetAmountChange(netAmount) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        net_total: netAmount,
      }
    });
  }

  onAmountChange(amount) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        amount: amount,
      }
    });
  }

  onContactChange(value?: string, contact?: Contact) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        contact_id: value,
        contact: contact,
        project_id: null,
      }
    })
  }

  onProjectChange(value?: string, project?: Project) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        project_id: project?.id || null,
        project: project || null,
        billable: ProjectHelper.isBillable(project),
      }
    });
  }

  onCustomFieldValueChange(key: string, value: any) {
    const { expense } = this.state

    expense.custom_fields[key] = value

    this.setState({
      expense: {
        ...expense,
      },
    })
  }

  onBillableChange(billable: boolean) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        billable: billable,
        markup: billable ? expense.markup : null,
      }
    })
  }

  onBilledChange(billed: boolean) {
    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        billed: billed,
      },
    });
  }

  onRecurringChange(recurring: boolean) {
    const { currentUser: { workspace } } = this.props
    const { expense } = this.state;

    let recurringSchedule: RecurringSchedule = null

    if (recurring) {
      recurringSchedule = {
        ...RecurringScheduleHelper.getDefaultSettingsForType(RecurrenceUnit.MONTHLY, workspace),
      }

      if (expense.invoiced_on) recurringSchedule.start_date = expense.invoiced_on
    }

    this.setState({
      expense: {
        ...expense,
        recurring_schedule: recurringSchedule,
      },
    });
  }

  onRecurringScheduleUnitChange(option) {
    const { currentUser: { workspace } } = this.props
    const { expense } = this.state

    const recurrenceUnit: RecurrenceUnit = option.value

    this.setState({
      expense: {
        ...expense,
        recurring_schedule: {
          ...RecurringScheduleHelper.getDefaultSettingsForType(recurrenceUnit, workspace),
        },
      },
    })
  }

  onRecurringScheduleDayOfWeekChange(option) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        recurring_schedule: {
          ...expense.recurring_schedule,
          repeat_on_day_of_week: option ? [Number(option.value)] : [],
        },
      },
    })
  }

  onRecurringScheduleDayOfMonthChange(option) {
    const { expense } = this.state

    // If last repeat at end of month
    if (option.value === 'last') {
      this.setState({
        expense: {
          ...expense,
          recurring_schedule: {
            ...expense.recurring_schedule,
            // Clear day of month
            repeat_on_day_of_month: null,
            // Set boolean flag so server can calculate
            repeat_at_end_of_month: true
          },
        },
      })
    } else {
      this.setState({
        expense: {
          ...expense,
          recurring_schedule: {
            ...expense.recurring_schedule,
            repeat_on_day_of_month: Number(option.value),
            repeat_at_end_of_month: false,
          },
        },
      })
    }
  }

  onRecurringScheduleMonthOfYearChange(option) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        recurring_schedule: {
          ...expense.recurring_schedule,
          repeat_on_month_of_year: Number(option.value),
        },
      },
    })
  }

  onRecurringScheduleStartDateChange(value) {
    const { expense } = this.state;

    const newStartDate = moment(value)

    if (newStartDate.isValid()) {
      this.setState({
        expense: {
          ...expense,
          recurring_schedule: {
            ...expense.recurring_schedule,
            start_date: newStartDate.format('YYYY-MM-DD'),
          },
        },
      })
    }
  }

  onRecurringScheduleEndedOptionChange(option) {
    const { expense } = this.state

    let scheduleSettings: RecurringSchedule = {}

    if (option.value === 'on') {
      scheduleSettings = {
        end_date: moment().add(1, 'month').format('YYYY-MM-DD')
      }
    } else if (option.value === 'never') {
      scheduleSettings = {
        end_date: null,
      }
    }

    this.setState({
      expense: {
        ...expense,
        recurring_schedule: {
          ...expense.recurring_schedule,
          ...scheduleSettings,
        }
      }
    })
  }

  onRecurringScheduleEndDateChange(value) {
    const { expense } = this.state;

    const newEndDate = moment(value)

    if (newEndDate.isValid()) {
      this.setState({
        expense: {
          ...expense,
          recurring_schedule: {
            ...expense.recurring_schedule,
            end_date: newEndDate.format('YYYY-MM-DD'),
          },
        },
      })
    }
  }

  onMarkupChange(value) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        markup: value,
      }
    })
  }

  async onFileChange(files) {
    const { expense } = this.state;

    const file = files[0];

    ActiveStorageController.upload(file, async (error, blob) => {
      if (error) { console.error(error) }
      if (blob) {
        const { url } = await ActiveStorageController.getBlobUrl(blob)

        this.setState({
          expense: {
            ...expense,
            name: expense.name || file.name,
            attachment: blob.signed_id,
            attachment_file_name: blob.filename,
            attachment_content_type: blob.content_type,
            attachment_file_size: blob.byte_size,
            attachment_url: url,
          }
        })

        const reader = new FileReader();

        // Read the file as base64 to try to extract data in real time for the user
        reader.onload = (e) => {
          // @ts-ignore
          const readerFile = e.target.result
          // @ts-ignore
          this.fetchExtractionData(file.name, readerFile.split("base64,")[1])
        }

        reader.readAsDataURL(file);
      }
    })
  }

  onFileButtonClick(e) {
    e.preventDefault();
    this.fileUploadInput.current.click();
  }

  onFileDownloadClick() {
    const { expense } = this.state

    if (expense.attachment_download_url) {
      saveAs(expense.attachment_download_url, expense.attachment_file_name)
    }
  }

  onFileDeleteClick(e) {
    e.preventDefault()
    e.stopPropagation()

    const { expense } = this.state;

    this.setState({
      expense: {
        ...expense,
        attachment: null,
        attachment_url: null,
        extraction_data: null,
      }
    });
  }

  onFileExpandClick(e) {
    e.preventDefault()
    const { t } = this.props
    const { expense } = this.state

    const attachments: PreviewAttachment[] = [
      {
        id: expense.id,
        name: expense.attachment_file_name,
        url: expense.attachment_url,
        file_size: expense.attachment_file_size,
        content_type: expense.attachment_content_type,
        created_at: expense.created_at
      }
    ]

    this.props.showAttachmentsViewerModal({
      attachments: attachments,
      actions: [{
        icon: 'external-link', text: t('ExpenseModal::Open in new tab'), onClick: (selectedIndex: number) => {
          const selectedAttachment = attachments[selectedIndex]

          if (selectedAttachment) window.open(selectedAttachment.url, "_blank");
        }
      }]
    })
  }

  onFormSubmit(e?: any) {
    if (e) e.preventDefault();
    const { expense } = this.state;
    const { close, onSubmit, t } = this.props

    this.setState({ isSubmitting: true })
    if (expense.id) { // Do update
      ExpensesController
        .update(expense)
        .then(response => {
          const { errors, error_full_messages } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ExpenseModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ExpenseModal::Expense successfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(error => console.error(error))
    }
    else {
      ExpensesController
        .create(expense)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ExpenseModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ExpenseModal::Expense successfully created'))
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(console.error)
    }
  }

  onExpenseModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onExpenseCategoryModalSubmit(category: ExpenseCategory, deleted?: boolean) {
    const { expense, categories } = this.state

    if (deleted) {
      this.setState({
        categories: categories.filter(c => c.id !== category.id),
        expense: {
          ...expense,
          // Check if category id is same and update accordingly
          category_id: expense.category_id !== category.id ? expense.category_id : null
        }
      })
    } else {
      // Created or updated
      const categoryIndex = categories.findIndex(c => c.id === category.id)

      if (categoryIndex !== -1) {
        // Update entry at specific index
        categories[categoryIndex] = category

        // Set new array of categories
        this.setState({
          categories: [...categories]
        })
      } else {
        this.setState({
          categories: [
            ...categories,
            category,
          ],
          expense: {
            ...expense,
            // When not a parent categorie set the newly create category as the selected category
            category_id: category.parent_id ? category.id : expense.category_id
          }
        })
      }
    }
  }

  onApplyDocumentTypeExtraction(documentType: ExpenseType) {
    const { expense } = this.state
    this.setState({
      expense: {
        ...expense,
        type: documentType
      }
    })
  }

  onApplyNetAmountExtraction(netAmount: number) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        net_total: netAmount
      }
    }, () => {
      if (this.netAmountInput.current) {
        this.netAmountInput.current.value(netAmount)
      }
    })
  }

  onApplyAmountExtraction(amount: number) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        amount: amount
      }
    }, () => {
      if (this.amountInput.current) {
        this.amountInput.current.value(amount)
      }
    })
  }

  async onApplySupplierExtraction(supplierName?: string, supplierVatNumber?: string, creationEnabled: boolean = true) {
    const { expense } = this.state

    try {
      // Supplier VAT number found attempt to fetch existing supplier
      if ((supplierName || supplierVatNumber)) {
        const suppliers = await ExpensesController.searchSuppliers({
          name: supplierName,
          vat_number: supplierVatNumber && VATHelper.isValidEUVAT(supplierVatNumber) ? supplierVatNumber : null
        })

        if (suppliers.length > 0) {
          // Supplier found
          const supplier = suppliers[0]

          if (supplier) {
            this.setState({
              expense: {
                ...expense,
                supplier_id: supplier.id,
                supplier: supplier,
              }
            }, this.fetchMetadata)
            return
          }
        }
      }

      if (creationEnabled) {
        // No supplier found ask to create
        requestAnimationFrame(() => {
          this.props.showContactTypeModal({
            onSubmit: (contactType) => {
              this.props.showContactModal({
                contact: {
                  name: supplierName || null,
                  type: contactType,
                  vat_number: supplierVatNumber || null
                },
                onSubmit: (supplier) => {
                  this.setState({
                    expense: {
                      ...expense,
                      supplier_id: supplier.id
                    }
                  })
                }
              })
            }
          })
        })
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onApplySupplierIbanExtraction(supplierIban?: string, creationEnabled: boolean = true) {
    const { expense } = this.state

    this.props.showContactModal({
      contact: {
        id: expense.supplier_id,
        account_numbers: [{ value: supplierIban?.replace(/\s/g, '') || '', }]
      },
      onSubmit: (supplier) => {
        this.setState({
          expense: {
            ...expense,
            supplier_id: supplier.id,
            supplier: supplier,
          }
        })
      }
    })
  }

  onApplyIssueDateExtraction(issueDate: string) {
    const { expense } = this.state

    const momentIssueDate = moment(issueDate)
    const isValid = momentIssueDate.isValid()

    this.setState({
      expense: {
        ...expense,
        invoiced_on: isValid ? momentIssueDate.format('YYYY-MM-DD') : null
      }
    }, () => {
      if (isValid && this.invoicedOnInput.current) this.invoicedOnInput.current.setSelectedDate(momentIssueDate)
    })
  }

  onApplyDueDateExtraction(dueDate: string) {
    const { expense } = this.state

    const momentDueDate = moment(dueDate)
    const isValid = momentDueDate.isValid()

    this.setState({
      expense: {
        ...expense,
        due_on: isValid ? momentDueDate.format('YYYY-MM-DD') : null
      }
    }, () => {
      if (isValid && this.dueOnInput.current) this.dueOnInput.current.setSelectedDate(momentDueDate)
    })
  }

  getExtractionCurrencyOption(currencyCode?: string, currencySymbol?: string) {
    return this.getCurrencyOptions().find(option => option.value === currencyCode || option.symbol === currencySymbol)
  }

  onApplyCurrencyExtraction(currencyCode: string) {
    const { expense } = this.state

    if (currencyCode) {
      this.setState({
        expense: {
          ...expense,
          currency: currencyCode
        }
      })
    }

  }
  onApplyCategoryExtraction(categoryId: string) {
    const { expense } = this.state

    this.setState({
      expense: {
        ...expense,
        category_id: categoryId,
      }
    })
  }

  onQRCodeClick() {
    const { expense } = this.state

    this.props.showPaymentQRModal({
      name: expense?.supplier?.name,
      iban: expense?.supplier?.account_numbers?.length > 0 ? expense.supplier.account_numbers[0].value : '',
      amount: expense.amount ? Number(expense.amount) : 0,
      currency: expense.currency,
      remittanceInformation: expense.remittance_information || '',
      remittanceInformationType: expense.remittance_information_type,
      onSubmit: (paid) => {
        if (paid) {
          const paidOnMoment = moment()
          this.setState({
            expense: {
              ...expense,
              paid_on: paidOnMoment.format('YYYY-MM-DD')
            }
          }, () => {
            if (this.paidOnInput.current) this.paidOnInput.current.setSelectedDate(paidOnMoment)
          })
        }
      }
    })
  }

  onPaymentClick() {
    const { t } = this.props
    const { expense } = this.state

    let paymentProps: Payment = {}

    if (!expense.payment_id) paymentProps = ExpenseHelper.buildPaymentFromExpense(expense)

    this.props.showPaymentModal({
      payment: { id: expense.payment_id, ...paymentProps },
      onSubmit: (payment) => {
        const updatedExpense = { ...expense, payment_id: payment.id }
        this.setState({ expense: updatedExpense }, async () => {
          if (updatedExpense.id) {
            try {
              // If expense already exists attempt to save the expense automatically
              const response = await ExpensesController.update(updatedExpense)
              if (response.errors) {
                this.setState({ errors: response.errors });
                Notification.notifyError(t('ExpenseModal::Oops something went wrong'))
              }
              else {
                Notification.notifySuccess(t('ExpenseModal::Expense successfully updated'))
              }

              this.setState({ isSubmitting: false })
            } catch (ex) {
              console.error(ex)
            }
          }

        })
      }
    })
  }

  renderSupplierExtractionMetadata() {
    const { expense } = this.state
    const { t } = this.props
    const { extractionSupplierNameField, extractionSupplierVATNumberField, extractionSupplierIbanField } = this.getExtractionFields()

    let label = ''
    let extractionSupplierName = extractionSupplierNameField
    let extractionSupplierVATNumber = extractionSupplierVATNumberField
    let extractionSupplierIban = extractionSupplierIbanField
    let ibanPresentOnSupplier = extractionSupplierIbanField?.length > 0 && (expense?.supplier?.account_numbers?.find(account => account.value === extractionSupplierIbanField))

    if (extractionSupplierNameField && extractionSupplierVATNumberField) {
      label = `${extractionSupplierName} (${extractionSupplierVATNumber})`
    } else if (extractionSupplierNameField) {
      label = `${extractionSupplierName}`
    } else if (extractionSupplierVATNumberField) {
      label = `${extractionSupplierVATNumber}`
    }

    return (
      <>
        {!expense?.supplier_id && (extractionSupplierNameField || extractionSupplierVATNumberField) && <ExtractionMetadata>
          <Alert
            type='warning'
            text={<ExtractionMetadataWrapper>
              <ExtractionMetadataContent>
                <Tooltip
                  content={t('ExpenseModal::We\'ve detected a supplier for this expense.')}
                  containerStyle={{ marginRight: 4 }}
                />
                {label}
              </ExtractionMetadataContent>
              <a
                href='javascript://'
                onClick={() => this.onApplySupplierExtraction(extractionSupplierName, extractionSupplierVATNumber)}
              >
                {`${t('ExpenseModal::Apply')}`}
              </a>
            </ExtractionMetadataWrapper>}
          />
        </ExtractionMetadata>}

        {expense.supplier_id && extractionSupplierIban?.length > 0 && !ibanPresentOnSupplier && <ExtractionMetadata>
          <Alert
            type='warning'
            text={<ExtractionMetadataWrapper>
              <ExtractionMetadataContent>
                <Tooltip
                  content={t('ExpenseModal::We\'ve detected an IBAN for your supplier.')}
                  containerStyle={{ marginRight: 4 }}
                />
                {t('ExpenseModal::Add account ({{iban}})', { iban: extractionSupplierIban })}
              </ExtractionMetadataContent>
              <a href='javascript://' onClick={() => this.onApplySupplierIbanExtraction(extractionSupplierIban)}>
                {`${t('ExpenseModal::Apply')}`}
              </a>
            </ExtractionMetadataWrapper>}
          />
        </ExtractionMetadata>}
      </>
    )
  }

  renderCategoryExtractionMetadata() {
    const { t } = this.props
    const { expenseMetadata, expense } = this.state

    if (
      expenseMetadata &&
      expenseMetadata.previous_supplier_expense &&
      expenseMetadata.previous_supplier_expense.category &&
      expenseMetadata.previous_supplier_expense.category_id !== expense.category_id
    ) {
      const { previous_supplier_expense } = expenseMetadata
      const { category } = previous_supplier_expense
      return (
        <ExtractionMetadata>
          <Alert
            type='warning'
            text={<ExtractionMetadataWrapper>
              <ExtractionMetadataContent>
                <Tooltip
                  content={t('ExpenseModal::A previous expense was created for this supplier with the category: {{category}}', { category: category.name })}
                  containerStyle={{ marginRight: 4 }}
                />
                {category.name}
              </ExtractionMetadataContent>
              <a
                href='javascript://'
                onClick={() => this.onApplyCategoryExtraction(previous_supplier_expense.category_id)}
              >
                {`${t('ExpenseModal::Apply')}`}
              </a>
            </ExtractionMetadataWrapper>}
          />
        </ExtractionMetadata>
      )
    }

    return null
  }

  renderDetails() {
    const { currentUser: { workspace: { setting } }, supplierDisabled, t } = this.props
    const { expense, categories } = this.state

    const expenseCategoryOptions = ExpenseCategoryHelper.getCategoryOptions(categories)
    const selectedCategoryOption = expense ? ExpenseCategoryHelper.getCategoryOption(categories, expense.category_id) : null

    const currencyOptions = this.getCurrencyOptions()
    const selectedCurrencyOption = currencyOptions.find(option => option.value === expense.currency)

    const typeOptions = Object.keys(ExpenseType).map(key => (
      { label: t(`ExpenseType::${ExpenseType[key]}`), value: ExpenseType[key] }
    ))
    const selectedTypeOption = typeOptions.find(option => option.value === expense.type)

    const {
      extractionDocumentType,
      extractionNetAmountField,
      extractionAmountField,
      extractionIssueDateField,
      extractionDueDateField,
      extractionCurrencyOptionField,
    } = this.getExtractionFields()

    return (
      <>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Name')}<span>*</span>
              </label>
              <input
                type='text'
                name='name'
                value={expense.name}
                onChange={this.onNameChange}
                placeholder={t('ExpenseModal::Name')}
                autoComplete='off'
                required
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Type')}<span>*</span>
              </label>
              <PowerSelect
                options={typeOptions}
                value={selectedTypeOption}
                onChange={this.onTypeChange}
                noOptionsMessage={() => t('ExpenseModal::No type found')}
                theme={ReactSelectTheme}
              />

              {extractionDocumentType && extractionDocumentType !== expense.type && <ExtractionMetadata>
                <Alert
                  type='warning'
                  text={<ExtractionMetadataWrapper>
                    <ExtractionMetadataContent>
                      {t(`ExpenseType::${extractionDocumentType}`)}
                    </ExtractionMetadataContent>

                    <a href='javascript://' onClick={() => this.onApplyDocumentTypeExtraction(extractionDocumentType as ExpenseType)}>
                      {`${t('ExpenseModal::Apply')}`}
                    </a>
                  </ExtractionMetadataWrapper>}
                />
              </ExtractionMetadata>}
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Amount (excl. VAT)')}<span>*</span>
              </label>
              <MoneyInput
                ref={this.netAmountInput}
                name='amount'
                currency={expense.currency}
                numberFormat={setting.number_format}
                placeholderValue={0}
                value={expense.net_total}
                onBlur={this.onNetAmountChange}
              />

              {extractionNetAmountField && Number(extractionNetAmountField) != Number(expense.net_total) && <ExtractionMetadata>
                <Alert
                  type='warning'
                  text={<ExtractionMetadataWrapper>
                    <ExtractionMetadataContent>
                      {`${NumberFormatter.formatCurrency(expense.currency, setting.number_format, extractionNetAmountField)}`}
                    </ExtractionMetadataContent>

                    <a href='javascript://' onClick={() => this.onApplyNetAmountExtraction(Number(extractionNetAmountField))}>
                      {`${t('ExpenseModal::Apply')}`}
                    </a>
                  </ExtractionMetadataWrapper>}
                />
              </ExtractionMetadata>}
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Amount')}<span>*</span>

                <Tooltip
                  content={t('ExpenseModal::We\'ll automatically calculate your VAT returns based on the amount you enter.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <MoneyInput
                ref={this.amountInput}
                name='amount'
                currency={expense.currency}
                numberFormat={setting.number_format}
                placeholderValue={0}
                value={expense.amount}
                onBlur={this.onAmountChange}
              />

              {extractionAmountField && Number(extractionAmountField) != Number(expense.amount) && <ExtractionMetadata>
                <Alert
                  type='warning'
                  text={<ExtractionMetadataWrapper>
                    <ExtractionMetadataContent>
                      {`${NumberFormatter.formatCurrency(expense.currency, setting.number_format, extractionAmountField)}`}
                    </ExtractionMetadataContent>

                    <a href='javascript://' onClick={() => this.onApplyAmountExtraction(Number(extractionAmountField))}>
                      {`${t('ExpenseModal::Apply')}`}
                    </a>
                  </ExtractionMetadataWrapper>}
                />
              </ExtractionMetadata>}
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseModal::Currency')} <span>*</span></label>

              <PowerSelect
                options={currencyOptions}
                value={selectedCurrencyOption}
                onChange={this.onCurrencyChange}
                noOptionsMessage={() => t('ExpenseModal::No currency found')}
                theme={ReactSelectTheme}
              />

              {extractionCurrencyOptionField && expense.currency != extractionCurrencyOptionField.value && <ExtractionMetadata>
                <Alert
                  type='warning'
                  text={<ExtractionMetadataWrapper>
                    <ExtractionMetadataContent>
                      <Tooltip
                        content={t('ExpenseModal::Currency "{{currency}}" detected', { currency: extractionCurrencyOptionField.label })}
                        containerStyle={{ marginRight: 4 }}
                      />
                      {extractionCurrencyOptionField.label}
                    </ExtractionMetadataContent>
                    <a
                      href='javascript://'
                      onClick={() => this.onApplyCurrencyExtraction(extractionCurrencyOptionField.value)}
                    >
                      {`${t('ExpenseModal::Apply')}`}
                    </a>
                  </ExtractionMetadataWrapper>}
                />
              </ExtractionMetadata>}
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseModal::Invoice date')}<span>*</span></label>
              <DateInput
                ref={this.invoicedOnInput}
                name='invoiced_on'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(expense.invoiced_on)}
                onChange={this.onInvoicedOnChange}
                inputProps={{ placeholder: setting.date_format }}
                closeOnSelect={true}
              />

              {extractionIssueDateField && extractionIssueDateField != expense.invoiced_on && <ExtractionMetadata>
                <Alert
                  type='warning'
                  text={<ExtractionMetadataWrapper>
                    <ExtractionMetadataContent>
                      {`${moment(extractionIssueDateField).format(setting.date_format)}`}
                    </ExtractionMetadataContent>
                    <a href='javascript://' onClick={() => this.onApplyIssueDateExtraction(extractionIssueDateField)}>{`${t('ExpenseModal::Apply')}`}</a>
                  </ExtractionMetadataWrapper>}
                />
              </ExtractionMetadata>}
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseModal::Due date')}</label>
              <DateInput
                ref={this.dueOnInput}
                name='due_on'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(expense.due_on)}
                onChange={this.onDueOnChange}
                inputProps={{ placeholder: setting.date_format }}
                closeOnSelect={true}
              />

              {extractionDueDateField && extractionDueDateField != expense.due_on && <ExtractionMetadata>
                <Alert
                  type='warning'
                  text={<ExtractionMetadataWrapper>
                    <ExtractionMetadataContent>
                      {`${moment(extractionDueDateField).format(setting.date_format)}`}
                    </ExtractionMetadataContent>
                    <a href='javascript://' onClick={() => this.onApplyDueDateExtraction(extractionDueDateField)}>{`${t('ExpenseModal::Apply')}`}</a>
                  </ExtractionMetadataWrapper>}
                />
              </ExtractionMetadata>}
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Supplier')}
              </label>

              <ResourceCreatablePowerSelect
                type='contact'
                value={expense.supplier_id}
                onChange={this.onSupplierChange}
                params={{ archived: false }}
                isDisabled={supplierDisabled}
                isClearable={true}
                placeholder={t('ExpenseModal::Select a supplier...')}
              />

              {this.renderSupplierExtractionMetadata()}
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Remittance information')}
              </label>

              <RemittanceInformationInput
                value={expense.remittance_information}
                type={expense.remittance_information_type}
                onRemittanceInformationChange={this.onRemittanceInformationChange}
                onRemittanceInformationTypeChange={this.onRemittanceInformationTypeChange}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseModal::Payment date')}</label>
              <DateInput
                ref={this.paidOnInput}
                name='paid_on'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(expense.paid_on)}
                onChange={this.onPaidOnChange}
                inputProps={{ placeholder: setting.date_format }}
                closeOnSelect={true}
              />

              {!expense.paid_on && <PayWithQRCodeLink onClick={this.onQRCodeClick}>
                <Icon icon='qr-code' />
                {t('ExpenseModal::Pay with QR code')}
              </PayWithQRCodeLink>}
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseModal::Category')}</label>

              <CreatablePowerSelect
                options={expenseCategoryOptions}
                value={selectedCategoryOption || ''}
                onChange={this.onCategoryChange}
                onCreateOption={this.onCategoryCreate}
                isClearable={true}
                formatCreateLabel={(inputValue) => t('ExpenseModal::Create category "{{input}}"', { input: inputValue })}
                components={{
                  GroupHeading: (props, test) => {
                    const { children: label } = props

                    let icon = null
                    let color = 'transparent'
                    let editable = false
                    const headerCategory = categories.find(category => category.name === label)

                    if (headerCategory) {
                      color = headerCategory.color
                      icon = ExpenseCategoryHelper.getIcon(headerCategory, color)
                      editable = headerCategory.editable
                    }

                    return (
                      <GroupHeading>
                        <div style={{ marginBottom: 4 }}>
                          {icon}
                        </div>
                        <components.GroupHeading {...props} />
                        {editable && <EditCategoryImage onClick={() => this.onCategoryEditClick(headerCategory.id)}>
                          <Icon icon='pencil' />
                        </EditCategoryImage>}
                      </GroupHeading>
                    )
                  },
                  Option: (props) => {
                    const { data: { label, value, editable, color, __isNew__, placeholder } } = props

                    if (placeholder) return null

                    if (!__isNew__) {
                      let editable = false

                      const category = categories.find(category => category.name === label)

                      if (category) {
                        editable = category.editable
                      }

                      return (
                        <components.Option {...props}>
                          <div className='powerselect-expense-category-option-container'>
                            <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" >
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Group" stroke="#D8D8D8">
                                  <rect id="Rectangle" fill="#D8D8D8" x="0.5" y="0.5" width="1" height="3"></rect>
                                  <rect id="Rectangle" fill="#D8D8D8" x="0.5" y="7.5" width="1" height="4"></rect>
                                  <rect id="Rectangle" fill="#D8D8D8" x="4.5" y="10.5" width="3" height="1"></rect>
                                </g>
                              </g>
                            </svg>
                            {label}

                            {editable && <EditCategoryImage style={{ marginLeft: 8 }} onClick={() => this.onCategoryEditClick(category.id)}>
                              <Icon icon='pencil' />
                            </EditCategoryImage>}
                          </div>
                        </components.Option>
                      )
                    } else {
                      return (
                        <components.Option {...props}>
                          {label}
                        </components.Option>
                      )
                    }
                  },
                  SingleValue: (props) => {
                    const { data: { label, value, editable, color } } = props

                    let icon = null
                    const selectedCategory = categories.find(category => category.id === value)

                    if (selectedCategory) {
                      icon = ExpenseCategoryHelper.getIcon(selectedCategory, color)
                    }

                    return (
                      <components.SingleValue {...props}>
                        <div className='powerselect-expense-category-selected-value-container'>
                          {icon}
                          {label}
                        </div>
                      </components.SingleValue>
                    )
                  },
                }}
                theme={ReactSelectTheme}
                placeholder={t('ExpenseModal::Select a category...')}
              />

              {this.renderCategoryExtractionMetadata()}
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseModal::Notes')}</label>
              <textarea
                placeholder={t('ExpenseModal::Add notes for this expense (only visible to you)')}
                value={expense.notes}
                onChange={this.onNotesChange}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  renderCustomFields() {
    const { expense, customFields } = this.state;

    return (
      <CustomFieldModalInputs
        customFields={customFields}
        modelCustomFields={expense.custom_fields}
        onCustomFieldValueChange={this.onCustomFieldValueChange}
        onCustomFieldsChange={(customFields: CustomField[]) => this.setState({ customFields: customFields })}
      />
    )
  }

  renderBilling() {
    const { t, currentUser: { workspace: { setting } }, contactDisabled, projectDisabled } = this.props
    const { expense } = this.state

    const projectSelectDisabled = !Boolean(expense.contact_id)

    return (
      <>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Client')}
              </label>
              <ResourceCreatablePowerSelect
                type='contact'
                params={{ archived: false }}
                value={expense.contact_id}
                onChange={this.onContactChange}
                isDisabled={contactDisabled}
                isClearable={true}
                placeholder={t('ExpenseModal::Select a client...')}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseModal::Project')}</label>
              <ResourceCreatablePowerSelect
                type='project'
                value={expense.project_id}
                onChange={this.onProjectChange}
                isDisabled={projectDisabled || projectSelectDisabled}
                params={{ 'contact_id': expense.contact_id, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
                createParams={{ contact_id: expense.contact_id }}
                isClearable={true}
                placeholder={t('ExpenseModal::Select a project...')}
              />
            </div>
          </div>

          {expense?.project?.billable_type !== ProjectBillableType.NON_BILLABLE && <>
            <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>
                  {t('ExpenseModal::Billable')}
                  <Tooltip
                    content={t('ExpenseModal::You\'ll see a reminder to include this expense on your next invoice.')}
                    containerStyle={{ marginLeft: 8 }}
                  />
                </label>
                <CheckboxInput
                  onChange={this.onBillableChange}
                  checked={expense.billable}
                  label={t('ExpenseModal::Billable')}
                />
              </div>
            </div>

            {expense.billable && <>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>
                    {t('ExpenseModal::Markup')}
                    <Tooltip
                      content={t('ExpenseModal::Earn a percentage on the cost of the expense when billing to a contact')}
                      containerStyle={{ marginLeft: 8 }}
                    />
                  </label>

                  <PercentInput
                    name='markup'
                    defaultValue={expense.markup || ''}
                    placeholder='50%'
                    onChange={this.onMarkupChange}
                  />
                </div>
              </div>

              {expense.net_total !== 0 && Number(expense.net_total) > 0 && expense.markup !== 0 && Number(expense.markup) > 0 && <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ExpenseModal::Invoiceable amount')}</label>
                  <input
                    type='text'
                    value={NumberFormatter.formatCurrency(expense.currency, setting.number_format, ExpenseHelper.getBillableAmount(expense))}
                    disabled={true}
                    style={{ textAlign: 'right' }}
                  />
                </div>
              </div>}
            </>}

            <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>{t('ExpenseModal::Billed')}</label>
                <CheckboxInput
                  onChange={this.onBilledChange}
                  checked={expense.billed}
                  label={t('ExpenseModal::Billed')}
                />
              </div>
            </div>
          </>}
        </div>
      </>
    )
  }

  renderRecurringSchedule() {
    const { t, currentUser: { workspace: { setting } }, contactDisabled, projectDisabled } = this.props
    const { expense } = this.state
    const { recurring_schedule } = expense

    const scheduleUnitOptions = RecurringScheduleHelper.unitOptions()
    const endOptions = [{ label: t('LedgerItemEditor::On'), value: 'on' }, { label: t('LedgerItemEditor::Never'), value: 'never' }]
    const weeklyOptions = RecurringScheduleHelper.dayOfWeekOptions()
    const dayOfMonthOptions = RecurringScheduleHelper.dayOfMonthOptions()
    const monthOfYearOptions = RecurringScheduleHelper.monthOfYearOptions()
    let endDate = null
    let selectedScheduleUnitOption = null
    let selectedEndOption = null
    let selectedWeeklyOption = null
    let selectedDayOfMonthOption = null
    let selectedMonthOption = null

    if (recurring_schedule) {
      const {
        end_date,
        recurrence_unit,
        repeat_on_day_of_week,
        repeat_on_day_of_month,
        repeat_on_month_of_year,
        repeat_at_end_of_month,
      } = recurring_schedule

      endDate = end_date
      selectedScheduleUnitOption = scheduleUnitOptions.find(option => option.value === recurrence_unit)
      selectedEndOption = endDate ? endOptions[0] : endOptions[1]

      if (recurrence_unit === RecurrenceUnit.WEEKLY) {
        selectedWeeklyOption = weeklyOptions.find(weeklyOption => repeat_on_day_of_week.includes(Number(weeklyOption.value)))
      } else if ([RecurrenceUnit.MONTHLY, RecurrenceUnit.BIMONTHLY, RecurrenceUnit.QUARTERLY, RecurrenceUnit.SEMESTERLY].includes(recurring_schedule.recurrence_unit)) {
        selectedDayOfMonthOption = repeat_at_end_of_month ? dayOfMonthOptions.find(dayOfMonthOption => dayOfMonthOption.value === 'last') : dayOfMonthOptions.find(dayOfMonthOption => Number(dayOfMonthOption.value) === repeat_on_day_of_month)
      } else if (recurrence_unit === RecurrenceUnit.YEARLY) {
        selectedMonthOption = monthOfYearOptions.find(monthOption => Number(monthOption.value) === repeat_on_month_of_year)
        selectedDayOfMonthOption = repeat_at_end_of_month ? dayOfMonthOptions.find(dayOfMonthOption => dayOfMonthOption.value === 'last') : dayOfMonthOptions.find(dayOfMonthOption => Number(dayOfMonthOption.value) === repeat_on_day_of_month)
      }
    }

    return (
      <>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ExpenseModal::Recurring')}
              </label>
              <CheckboxInput
                onChange={this.onRecurringChange}
                checked={Boolean(expense.recurring_schedule)}
                label={t('ExpenseModal::Recurring')}
              />
            </div>
          </div>

          {recurring_schedule && <>
            <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>{t('ExpenseModal::Repeats')}</label>
                <PowerSelect
                  options={scheduleUnitOptions}
                  value={selectedScheduleUnitOption}
                  onChange={this.onRecurringScheduleUnitChange}
                  theme={ReactSelectTheme}
                />
              </div>
            </div>

            {recurring_schedule.recurrence_unit === RecurrenceUnit.WEEKLY && <>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ExpenseModal::Every')}</label>
                  <PowerSelect
                    options={weeklyOptions}
                    value={selectedWeeklyOption}
                    onChange={this.onRecurringScheduleDayOfWeekChange}
                    theme={ReactSelectTheme}
                  />
                </div>
              </div>
            </>}
            {[
              RecurrenceUnit.MONTHLY,
              RecurrenceUnit.BIMONTHLY,
              RecurrenceUnit.QUARTERLY,
              RecurrenceUnit.SEMESTERLY
            ].includes(recurring_schedule.recurrence_unit) && <>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('ExpenseModal::Every')}</label>
                    <PowerSelect
                      options={dayOfMonthOptions}
                      value={selectedDayOfMonthOption}
                      onChange={this.onRecurringScheduleDayOfMonthChange}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>
              </>}
            {recurring_schedule.recurrence_unit === RecurrenceUnit.YEARLY && <>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ExpenseModal::Every')}</label>
                  <PowerSelect
                    options={monthOfYearOptions}
                    value={selectedMonthOption}
                    onChange={this.onRecurringScheduleMonthOfYearChange}
                    theme={ReactSelectTheme}
                  />
                </div>
              </div>

              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ExpenseModal::On the')}</label>
                  <PowerSelect
                    options={dayOfMonthOptions}
                    value={selectedDayOfMonthOption}
                    onChange={this.onRecurringScheduleDayOfMonthChange}
                    theme={ReactSelectTheme}
                  />
                </div>
              </div>
            </>}

            <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>{t('ExpenseModal::Starts on')}</label>
                <DateInput
                  name='start_date'
                  dateFormat={setting.date_format}
                  timeFormat={false}
                  initialValue={moment(recurring_schedule.start_date)}
                  inputProps={{ placeholder: setting.date_format }}
                  onChange={this.onRecurringScheduleStartDateChange}
                  closeOnSelect
                />
              </div>
            </div>

            <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>{t('ExpenseModal::End on')}</label>
                <PowerSelect
                  options={endOptions}
                  value={selectedEndOption}
                  onChange={this.onRecurringScheduleEndedOptionChange}
                  theme={ReactSelectTheme}
                />
              </div>
            </div>

            {endDate && <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>{t('ExpenseModal::Ends on')}</label>
                <DateInput
                  name='end_date'
                  dateFormat={setting.date_format}
                  timeFormat={false}
                  initialValue={moment(endDate)}
                  onChange={this.onRecurringScheduleEndDateChange}
                  closeOnSelect
                />
              </div>
            </div>}
          </>}
        </div>
      </>
    )
  }

  renderBookkeeping() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { expense } = this.state;

    return (
      <div className='grid'>
        <div className='grid-cell with-12col'>
          <div className='form-item'>
            <label>{t('ExpenseModal::Booked on')}</label>
            <DateInput
              name='booked_on'
              dateFormat={setting.date_format}
              timeFormat={false}
              initialValue={moment(expense.booked_on)}
              inputProps={{ placeholder: setting.date_format }}
              onChange={this.onBookedOnChange}
            />
          </div>
        </div>
      </div>
    )
  }

  renderAttachment() {
    const { t } = this.props
    const { expense } = this.state

    return (
      <>
        {!expense.attachment_url && <MobileExpenseModalDropzone>
          <ResourceModalDropzone
            onDrop={this.onFileChange}
            accept={ExpenseHelper.getExpenseDropzoneMimeTypes()}
          />
        </MobileExpenseModalDropzone>}

        {expense.attachment_url && <AttachmentPreviewContainer onClick={this.onFileExpandClick}>
          <AttachmentPreviewer
            filename={expense.attachment_file_name}
            source={expense.attachment_url}
            contentType={expense.attachment_content_type}
          />
          {!UrlHelper.isDataUrl(expense.attachment_url) && <AttachmentExpand>
            <Icon icon='expand' />
          </AttachmentExpand>}

          <AttachmentPreviewDelete href='javascript://' onClick={this.onFileDeleteClick}>
            {t('ExpenseModal::Delete')}
          </AttachmentPreviewDelete>
        </AttachmentPreviewContainer>}
      </>
    )

  }

  render() {
    const { t } = this.props
    const { expense, didInitialLoad, errors, isSubmitting } = this.state

    return (
      <Container>
        {!didInitialLoad && <LoaderContainer>
          <Loader
            color='white'
            size='large'
          />
        </LoaderContainer>}
        {didInitialLoad && <>
          <PreviewContainer>
            {!expense.attachment_url && <PreviewDropzoneContainer>
              <ResourceModalDropzone
                onDrop={this.onFileChange}
                accept={ExpenseHelper.getExpenseDropzoneMimeTypes()}
              />
            </PreviewDropzoneContainer>}
            {expense.attachment_url && <PreviewWrapper noPadding={['application/pdf', 'text/xml', 'application/xml'].includes(expense.attachment_content_type)}>
              <AttachmentPreviewer
                filename={expense.attachment_file_name}
                source={expense.attachment_url}
                contentType={expense.attachment_content_type}
              />
            </PreviewWrapper>}


            {expense.attachment_url && <PreviewActions>
              {expense.paid_on && <>
                <PreviewAction onClick={this.onQRCodeClick} data-tip={t('ExpenseModal::Pay with QR code')}>
                  <Icon icon='qr-code' />
                </PreviewAction>
                <PreviewAction onClick={this.onPaymentClick} data-tip={expense?.payment_id ? t('ExpenseModal::View payment') : t('ExpenseModal::Queue payment')}>
                  <Icon icon='money-bill' />
                </PreviewAction>
              </>}
              <PreviewAction onClick={this.onFileDownloadClick} data-tip={t('ExpenseModal::Download')}>
                <Icon icon='download-circle' />
              </PreviewAction>
              <PreviewAction onClick={this.onFileDeleteClick} destructive data-tip={t('ExpenseModal::Remove attachment')}>
                <Icon icon='trash' />
              </PreviewAction>
            </PreviewActions>}
          </PreviewContainer>

          <SidebarContainer>
            <SidebarHeader>
              {expense.id ? t('ExpenseModal::Edit expense') : t('ExpenseModal::Create expense')}
              <SidebarClose onClick={this.onExpenseModalClose} />
            </SidebarHeader>

            <SidebarDivider />

            <SidebarContent>
              <form onSubmit={this.onFormSubmit}>
                {this.renderDetails()}
                {this.renderCustomFields()}
                {this.renderBilling()}
                {this.renderRecurringSchedule()}
                {this.renderBookkeeping()}
                {this.renderAttachment()}
                <input type='submit' style={{ display: 'none' }} />
              </form>
            </SidebarContent>

            <SidebarDivider />

            <SidebarFooter>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />

                <Button
                  type='success'
                  text={t('ExpenseModal::Save')}
                  isLoading={isSubmitting}
                  onClick={this.onFormSubmit}
                  style={{ width: '100%' }}
                />
              </div>
            </SidebarFooter>
          </SidebarContainer>
        </>}
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      expenseModal: {
        expense,
        supplierDisabled,
        contactDisabled,
        projectDisabled,
        onSubmit,
      }
    }
  } = state

  return {
    expense: expense,
    supplierDisabled: supplierDisabled,
    contactDisabled: contactDisabled,
    projectDisabled: projectDisabled,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeExpenseModal()),
    showExpenseCategoryModal: (options) => dispatch(showExpenseCategoryModal(options)),
    showAttachmentsViewerModal: (options) => dispatch(showAttachmentsViewerModal(options)),
    showContactTypeModal: (options) => dispatch(showContactTypeModal(options)),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showPaymentModal: (options) => dispatch(showPaymentModal(options)),
    showPaymentQRModal: (options) => dispatch(showPaymentQRModal(options)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExpenseModal)))