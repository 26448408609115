import Api from "./Api";
import Utils from "../utilities/Utils";
import { Email, SendEmailParams } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class EmailsController extends Api {
  static getEmail(id: string): Promise<Email> {
    return fetch(`/api/emails/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getEmailForm(contact_id: string) {
    return fetch(`/api/emails/send_email_form?contact_id=${contact_id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static sendEmail(email: SendEmailParams) {
    return fetch(`/api/emails/send_email`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ email: email }),
    }).then((res) => res.json());
  }

  static getEmails(params: object) {
    return fetch(`/api/emails?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/emails/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
