import Api from "./Api";
import Utils from "../utilities/Utils";
import { Sequence } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class SequencesController extends Api {
  static getSequence(id: string) {
    return fetch(`/api/sequences/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getSequences(params: object) {
    return fetch(`/api/sequences?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(sequence: Sequence) {
    return fetch("/api/sequences", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ sequence: sequence }),
    }).then((res) => res.json());
  }

  static update(sequence: Sequence) {
    return fetch(`/api/sequences/${sequence.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ sequence: sequence }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/sequences/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getSequenceForm(params: { id?: string }) {
    return fetch(`/api/sequences/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static enroll(
    id: string,
    contactIds: string[] = [],
    groupIds: string[] = []
  ) {
    return fetch(`/api/sequences/${id}/enroll`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact_ids: contactIds, group_ids: groupIds }),
    }).then((res) => res.json());
  }

  static unenroll(id: string, contactIds: string[]) {
    return fetch(`/api/sequences/${id}/unenroll`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ enrollment_ids: contactIds }),
    }).then((res) => res.json());
  }
}
