import QueryString from "qs";

export default class SearchHelper {
  static getSearch(queryString: string, filterWhiteList: string[]) {
    const parameters = QueryString.parse(queryString, { ignoreQueryPrefix: true });

    const search = String(parameters.search || "");
    const page = Number(parameters.page || 1);

    const filters = {}

    for (const key in parameters) {
      if (filterWhiteList.includes(key)) {
        filters[key] = parameters[key];
      }
    }

    return {
      search: search,
      page: page,
      filters: filters,
    }
  }

  // static getSearchParams(page: string, search: string, filters: any) {
  //   return {
  //     page: number,
  //     search: search,
  //     filters: filters,
  //   }
  // }

  static getFilterParams(search: string, filters: any) {
    return {
      search: search,
      filters: filters
    }
  }

  static getQueryString(params = {}) {
    return QueryString.stringify(params, {
      arrayFormat: "brackets",
      indices: false,
      skipNulls: true
    });
  }
}

