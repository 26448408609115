import * as React from 'react'
import { closeSettingsEmailTemplateModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import EmailPreview from '../EmailPreview/EmailPreview'
import { SettingsController } from '../../controllers'
import { ISettingsForm } from '../../controllers/SettingsController'
import Notification from '../../utilities/Notification'
import ModalWindowWithPreview from './Parts/ModalWindowWithPreview'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { EMAIL_EDITOR_CONFIG } from '../Editor/Editor'
import EditorHelper from '../../helpers/EditorHelper'
import PowerSelect from '../Form/PowerSelect'
import LocaleHelper from '../../helpers/LocaleHelper'
import { DEFAULT_LOCALE } from '../../Constants'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import MobilityHelper from '../../helpers/MobilityHelper'
import { CurrentUser, Settings, Locale, SettingsEmailTemplate } from '../../types'
import EmailTemplateHelper from '../../helpers/EmailTemplateHelper'

const LedgerItemModalMiddle = styled(ModalMiddle)`
  height: calc(100% - 56px);
`

const LedgerItemModalContent = styled(ModalContent)`
  overflow-y: auto;
`

const Content = styled.div`
  flex: 1;
  max-width: 500px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: 100%;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  activeTemplate: SettingsEmailTemplate
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  close: typeof closeSettingsEmailTemplateModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  resetEditor: boolean
  activeTemplate: SettingsEmailTemplate | string
  settings: Settings | null
  form: ISettingsForm | null
  activeTranslationsLocale: Locale
  errors: any
}

class SettingsEmailTemplateModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser } = props

    this.state = {
      didInitialLoad: false,
      resetEditor: false,
      activeTemplate: SettingsEmailTemplate.INVOICE,
      settings: props.currentUser.workspace.setting,
      form: null,
      activeTranslationsLocale: currentUser.locale || DEFAULT_LOCALE,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onLedgerItemEmailTemplateModalClick = this.onLedgerItemEmailTemplateModalClick.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onTemplateChange = this.onTemplateChange.bind(this)
    this.onLocaleChange = this.onLocaleChange.bind(this)
    this.onSubjectChange = this.onSubjectChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm().catch(console.error)
  }

  async fetchForm() {
    const { activeTemplate: propsActiveTemplate } = this.props
    const { activeTemplate } = this.state
    try {
      const form = await SettingsController.getForm()

      this.setState({
        didInitialLoad: true,
        form: form,
        activeTemplate: propsActiveTemplate ? propsActiveTemplate : activeTemplate,
      })
    }
    catch (ex) {
      console.error(ex)
    }
  }

  onTemplateChange(option) {
    this.setState({ activeTemplate: option.value, })
  }

  onLocaleChange(option) {
    this.setState({ activeTranslationsLocale: option.value });
  }

  onSubjectChange(e) {
    e.preventDefault();

    const { activeTemplate, settings, activeTranslationsLocale } = this.state

    const newSubject = e.currentTarget.value;

    let property = null

    switch (activeTemplate) {
      case SettingsEmailTemplate.ORDER_FORM:
        property = 'order_form_email_subject'
        break
      case SettingsEmailTemplate.DELIVERY_NOTE:
        property = 'delivery_note_email_subject'
        break
      case SettingsEmailTemplate.QUOTE:
        property = 'quotation_email_subject'
        break
      case SettingsEmailTemplate.PRO_FORMA_INVOICE:
        property = 'pro_forma_invoice_email_subject'
        break
      case SettingsEmailTemplate.INVOICE:
        property = 'invoice_email_subject'
        break
      case SettingsEmailTemplate.INVOICE_REMINDER:
        property = 'invoice_reminder_email_subject'
        break
      case SettingsEmailTemplate.INVOICE_PAID:
        property = 'invoice_paid_email_subject'
        break
      case SettingsEmailTemplate.CREDIT_NOTE:
        property = 'credit_note_email_subject'
        break
      case SettingsEmailTemplate.PROPOSAL:
        property = 'proposal_email_subject'
        break
      case SettingsEmailTemplate.CONTRACT:
        property = 'contract_email_subject'
        break
      default: throw new Error(`[onSubjectChange] activeTemplate ${activeTemplate} not supported `)
    }

    if (!property) throw new Error('[onSubjectChange] unknown property')

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationsLocale, settings.translations, property, newSubject)

    this.setState({
      settings: {
        ...settings,
        translations: updatedTranslations
      }
    })
  }

  onBodyChange(body: string) {
    const { activeTemplate, settings, activeTranslationsLocale } = this.state

    let property = null

    switch (activeTemplate) {
      case SettingsEmailTemplate.ORDER_FORM:
        property = 'order_form_email_body'
        break
      case SettingsEmailTemplate.DELIVERY_NOTE:
        property = 'delivery_note_email_body'
        break
      case SettingsEmailTemplate.QUOTE:
        property = 'quotation_email_body'
        break
      case SettingsEmailTemplate.PRO_FORMA_INVOICE:
        property = 'pro_forma_invoice_email_body'
        break
      case SettingsEmailTemplate.INVOICE:
        property = 'invoice_email_body'
        break
      case SettingsEmailTemplate.INVOICE_REMINDER:
        property = 'invoice_reminder_email_body'
        break
      case SettingsEmailTemplate.INVOICE_PAID:
        property = 'invoice_paid_email_body'
        break
      case SettingsEmailTemplate.CREDIT_NOTE:
        property = 'credit_note_email_body'
        break
      case SettingsEmailTemplate.PROPOSAL:
        property = 'proposal_email_body'
        break
      case SettingsEmailTemplate.CONTRACT:
        property = 'contract_email_body'
        break
      default: throw new Error(`[onBodyChange] activeTemplate ${activeTemplate} not supported `)
    }

    if (!property) throw new Error('[onBodyChange] unknown property')

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationsLocale, settings.translations, property, body)

    this.setState({
      settings: {
        ...settings,
        translations: updatedTranslations
      }
    })
  }


  async onFormSubmit(e) {
    e.preventDefault();

    const { settings } = this.state
    const { updateSettings, t } = this.props

    try {
      const response = await SettingsController.update(settings)

      if (response.errors) {
        Notification.notifyError(t('LedgerItemEmailTemplateModal::Oops something went wrong'));
      } else {
        // Cast to settings
        const settings = response as Settings
        // Update local
        this.setState({ settings: settings })
        // Update global
        updateSettings(settings)
        // Success message
        Notification.notifySuccess(t('LedgerItemEmailTemplateModal::Email templates succesfully updated'));

        // Close modal
        this.props.close()
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onLedgerItemEmailTemplateModalClick() {
    this.props.close()
  }

  onNavigationItemClick(e) {
    const activeTemplate = e.currentTarget.getAttribute('data-tab');

    this.setState({ resetEditor: true, activeTemplate: activeTemplate }, () => {
      this.setState({ resetEditor: false })
    });
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  getEmailPreview(): { subject: string, body: string } {
    const { activeTemplate, settings, activeTranslationsLocale } = this.state

    if (settings) {
      switch (activeTemplate) {
        case SettingsEmailTemplate.ORDER_FORM:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'order_form_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'order_form_email_body')
          }
        case SettingsEmailTemplate.DELIVERY_NOTE:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'delivery_note_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'delivery_note_email_body')
          }
        case SettingsEmailTemplate.QUOTE:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'quotation_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'quotation_email_body')
          }
        case SettingsEmailTemplate.PRO_FORMA_INVOICE:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'pro_forma_invoice_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'pro_forma_invoice_email_body')
          }
        case SettingsEmailTemplate.INVOICE:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'invoice_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'invoice_email_body')
          }
        case SettingsEmailTemplate.INVOICE_REMINDER:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'invoice_reminder_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'invoice_reminder_email_body')
          }
        case SettingsEmailTemplate.INVOICE_PAID:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'invoice_paid_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'invoice_paid_email_body')
          }
        case SettingsEmailTemplate.CREDIT_NOTE:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'credit_note_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'credit_note_email_body')
          }
        case SettingsEmailTemplate.PROPOSAL:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'proposal_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'proposal_email_body')
          }
        case SettingsEmailTemplate.CONTRACT:
          return {
            subject: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'contract_email_subject'),
            body: MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'contract_email_body')
          }
        default: throw new Error(`[getEmailPreview] activeTemplate ${activeTemplate} not supported `)
      }
    }

    return { subject: '', body: '' }
  }

  getFormVariables() {
    const { activeTemplate, form } = this.state

    if (!form) return {}

    if (activeTemplate === 'contract') return form.contract_variables

    // Return an empty array until new variable system is implemented
    return []
    // return form.ledger_item_variables
  }

  render() {
    const { t } = this.props
    const {
      didInitialLoad,
      resetEditor,
      activeTranslationsLocale,
      errors,
      activeTemplate,
    } = this.state

    const { subject, body } = this.getEmailPreview()
    const variables = this.getFormVariables()

    const templateOptions = EmailTemplateHelper.getTemplateOptions()
    const selectedTemplateOption = templateOptions.find(option => option.value === activeTemplate)

    const localeOptions = LocaleHelper.getLocaleOptions()
    const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

    return (
      <ModalWindowWithPreview>
        <Content>
          <ModalHeader
            title={t('LedgerItemEmailTemplateModal::Email templates')}
            onCloseClick={this.onLedgerItemEmailTemplateModalClick}
          />

          {!didInitialLoad && <ModalLoader />}
          {didInitialLoad && <LedgerItemModalMiddle>
            <LedgerItemModalContent>
              <form onSubmit={this.onFormSubmit}>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendLedgerItemModal::Template')}</label>
                      <PowerSelect
                        options={templateOptions}
                        value={selectedTemplateOption}
                        onChange={this.onTemplateChange}
                        noOptionsMessage={(value) => t('LedgerItemEmailTemplateModal::No template found.')}
                        theme={ReactSelectTheme}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendLedgerItemModal::Language')}</label>
                      <PowerSelect
                        options={localeOptions}
                        value={selectedLocaleOption}
                        onChange={this.onLocaleChange}
                        noOptionsMessage={(value) => t('LedgerItemEmailTemplateModal::No language found.')}
                        theme={ReactSelectTheme}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendLedgerItemModal::Subject')} <span>*</span></label>

                      <input
                        type='text'
                        value={subject}
                        onChange={this.onSubjectChange}
                        name='subject'
                        required
                        placeholder={t('SendLedgerItemModal::Subject')}
                      />
                    </div>
                  </div>
                </div>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendLedgerItemModal::Content')} <span>*</span></label>
                      {!resetEditor &&
                        <EditorContainer>
                          <Editor
                            model={body}
                            onModelChange={this.onBodyChange}
                            config={{
                              ...EMAIL_EDITOR_CONFIG,
                              editorClass: 'branded-email',
                              variableOptions: EditorHelper.getVariableOptions(variables),
                              placeholderText: t('EmailTemplateModal::Type here...'),
                              heightMin: 200,
                              heightMax: 200
                            }}
                          />
                        </EditorContainer>}
                    </div>
                  </div>
                </div>
              </form>
            </LedgerItemModalContent>

            <div className='modal-footer'>
              <div />
              <div className='modal-footer-actions'>
                <div key='main-action' className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={this.onErrorsDismiss}
                  />
                  <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                    {t('LedgerItemEmailTemplateModal::Save')}
                  </a>
                </div>
              </div>
            </div>

          </LedgerItemModalMiddle>}
        </Content>

        <EmailPreview
          subject={subject}
          body={body}
        />
      </ModalWindowWithPreview>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      settingsEmailTemplateModal: {
        template,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    activeTemplate: template,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    close: () => dispatch(closeSettingsEmailTemplateModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SettingsEmailTemplateModal))