import Api from "./Api";
import Utils from "../utilities/Utils";
import { DocumentTag } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class DocumentTagsController extends Api {
  static getDocumentTags(params: object) {
    return fetch(`/api/document_tags?${SearchHelper.getQueryString(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(documentTag: DocumentTag) {
    return fetch("/api/document_tags", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ document_tag: documentTag }),
    }).then((res) => res.json());
  }

  static update(documentTag: DocumentTag) {
    return fetch(`/api/document_tags/${documentTag.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ document_tag: documentTag }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/document_tags/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(id?: string) {
    const params = { id: id };

    return fetch(`/api/document_tags/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
