import {
  SHOW_NAVIGATION,
  HIDE_NAVIGATION,
  NavigationActionTypes,
  NavigationState,
  TOGGLE_NAVIGATION,
  SET_HISTORY,
} from "./types";

const initialState: NavigationState = {
  active: false,
  history: null,
};

export default (
  state = initialState,
  action: NavigationActionTypes
): NavigationState => {
  switch (action.type) {
    case SHOW_NAVIGATION:
      return {
        ...state,
        active: true,
      };
    case HIDE_NAVIGATION:
      return {
        ...state,
        active: false,
      };
    case TOGGLE_NAVIGATION:
      return {
        ...state,
        active: !state.active,
      };
    case SET_HISTORY:
      return {
        ...state,
        history: action.history,
      };
    default:
      return state;
  }
};
