import Api from "./Api";
import Utils from "../utilities/Utils";
import { CalendarViewEvent } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class CalendarController extends Api {
  static getEvents(params): Promise<{ events: CalendarViewEvent[] }> {
    return fetch(`/api/calendar?${SearchHelper.getQueryString(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
