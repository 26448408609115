import Api from "./Api";
import Utils from "../utilities/Utils";
import SearchHelper from "../helpers/SearchHelper";

export default class CalendarsController extends Api {
  static getCalendars(params: object) {
    return fetch(`/api/calendars?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/calendars/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
