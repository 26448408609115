import * as React from 'react'
import { closeUserWorkspaceSettingModal, showConfirmModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import { UserWorkspaceSettingsController } from '../../controllers'
import Notification from '../../utilities/Notification'
import styled from 'styled-components'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import PowerSelect from '../Form/PowerSelect'
import MoneyInput from '../Form/MoneyInput'
import CheckboxInput from '../Form/CheckboxInput'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import { CurrentUser, UserWorkspaceSetting, UserWorkspaceSettingRole, UserWorkspaceSettingScope, WeekdaySchedule } from '../../types'
import Switch from '../Form/Switch'
import MaskedInput from 'react-text-mask'
import moment from '../../utilities/Moment'
import { Style } from '../../styles'

const WeekdayLabel = styled.label`
  width: 100%;
`

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    padding-bottom: 22px;
    border-bottom: 1px solid ${Style.color.border};
  }
`

const WeekdayLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const TimeInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  width: 100%;
  gap: 10px;

  input {
    width: 100%;
  }
`

interface DisplayableUserWorkspaceSettingScope {
  scope: UserWorkspaceSettingScope
  description: string
  childScopes?: { scope: UserWorkspaceSettingScope, description: string }[]
}

const ScopesContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  list-style: none;
  background: white;
`

const Scopes = styled.ul`
  list-style: none;
  margin: 0;
  padding: 5px 10px;
`

const Scope = styled.li`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  line-height: 23px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 10px;

  &:last-child {
    border-bottom: none;
  }
`

const ScopeLabel = styled.label`
  color: #24292e;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin: 0;
  padding: 2px 0;
  width: 100%;
  text-transform: none;
  flex-wrap: wrap;

  div {
    color: #24292e;
    display: inline-flex;
    font-size: 15px;
    margin: 0;
    padding: 2px 0;
    width: 250px;
    flex-direction: row;
    align-items: center;
  }

  p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #586069;
    text-transform: none;
  }
`

const ChildScopes = styled.ul`
  list-style: none;
  margin-left: 20px;

  ${Scope} {
    border: none;
  }

  ${ScopeLabel} {
    div {
      width: 230px;
      font-weight: 500;
    }
  }
`

const CapacityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface IStateToProps {
  userWorkspaceSetting: UserWorkspaceSetting
  activeTab?: UserWorkspaceSettingModalTab
  onSubmit?: (userWorkspaceSetting: UserWorkspaceSetting) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeUserWorkspaceSettingModal,
  showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export type UserWorkspaceSettingModalTab = 'details' | 'working-days' | 'permissions'

interface IState {
  didInitialLoad: boolean
  activeTab: UserWorkspaceSettingModalTab
  userWorkspaceSetting: UserWorkspaceSetting | null
  errors: any
}

class UserWorkspaceSettingModal extends React.Component<IProps, IState> {
  private startMaskedInputs: MaskedInput[] = []
  private endMaskedInputs: MaskedInput[] = []
  private breakStartMaskedInputs: MaskedInput[] = []
  private breakEndMaskedInputs: MaskedInput[] = []

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: 'details',
      userWorkspaceSetting: null,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onUserWorkspaceSettingModalCloseClick = this.onUserWorkspaceSettingModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

    this.onEmailChange = this.onEmailChange.bind(this)
    this.onRoleChange = this.onRoleChange.bind(this)
    this.onHourlyCostChange = this.onHourlyCostChange.bind(this)
    this.onHourlyRateChange = this.onHourlyRateChange.bind(this)
    this.onDayRateChange = this.onDayRateChange.bind(this)
    this.onToggleSelectAllScopes = this.onToggleSelectAllScopes.bind(this)
    this.onToggleScope = this.onToggleScope.bind(this)

    this.onWeekdayEnabledToggle = this.onWeekdayEnabledToggle.bind(this)
    this.onWeekdayStartTimeBlur = this.onWeekdayStartTimeBlur.bind(this)
    this.onWeekdayEndTimeBlur = this.onWeekdayEndTimeBlur.bind(this)
    this.onWeekdayLunchEnabledToggle = this.onWeekdayLunchEnabledToggle.bind(this)
    this.onWeekdayLunchStartTimeBlur = this.onWeekdayLunchStartTimeBlur.bind(this)
    this.onWeekdayLunchEndTimeBlur = this.onWeekdayLunchEndTimeBlur.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { userWorkspaceSetting: propsUserWorkspaceSeting, activeTab: propActiveTab } = this.props
    const { activeTab } = this.state

    try {
      const response = await UserWorkspaceSettingsController.getForm({ id: propsUserWorkspaceSeting.id })

      const { user_workspace_setting } = response

      this.setState({
        userWorkspaceSetting: {
          ...propsUserWorkspaceSeting,
          ...user_workspace_setting,
        },
        didInitialLoad: true,
        activeTab: propActiveTab ? propActiveTab : activeTab,
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  syncInputs() {
    const { userWorkspaceSetting } = this.state

    userWorkspaceSetting.week_schedule.forEach((weekday, index) => {
      const [startTimeHour, startTimeMinutes] = weekday.start_time.split(':').map(Number)
      const [endTimeHour, endTimeMinutes] = weekday.end_time.split(':').map(Number)
      const [breakStartTimeHour, breakStartTimeMinutes] = weekday.break_start_time.split(':').map(Number)
      const [breakEndTimeHour, breakEndTimeMinutes] = weekday.break_end_time.split(':').map(Number)

      if (this.startMaskedInputs[index]) {
        const startTimeValue: string = moment()
          .set('hour', startTimeHour)
          .set('minutes', startTimeMinutes)
          .format('HH:mm')

        // @ts-ignores
        this.startMaskedInputs[index].inputElement.value = startTimeValue
      }

      if (this.endMaskedInputs[index]) {
        const endTimeValue: string = moment()
          .set('hour', endTimeHour)
          .set('minutes', endTimeMinutes)
          .format('HH:mm')

        // @ts-ignores
        this.endMaskedInputs[index].inputElement.value = endTimeValue
      }

      if (this.breakStartMaskedInputs[index]) {
        const breakBtartTimeValue: string = moment()
          .set('hour', breakStartTimeHour)
          .set('minutes', breakStartTimeMinutes)
          .format('HH:mm')

        // @ts-ignores
        this.breakStartMaskedInputs[index].inputElement.value = breakBtartTimeValue
      }

      if (this.breakEndMaskedInputs[index]) {
        const breakEndTimeValue: string = moment()
          .set('hour', breakEndTimeHour)
          .set('minutes', breakEndTimeMinutes)
          .format('HH:mm')

        // @ts-ignore
        this.breakEndMaskedInputs[index].inputElement.value = breakEndTimeValue
      }
    })
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { userWorkspaceSetting } = this.state;
    const { close, onSubmit, t } = this.props

    if (userWorkspaceSetting.id) {
      UserWorkspaceSettingsController
        .update(userWorkspaceSetting)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('UserWorkspaceSettingModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('UserWorkspaceSettingModal::Team member successfully updated.'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(error => console.error(error))
    }
    else {
      this.props.showConfirmModal({
        title: t('UserWorkspaceSettingModal::Confirm user'),
        description: t('UserWorkspaceSettingModal::Adding this user to your workspace will add an additional 15 euros to your subscription. Are you sure you want add this user to your workspace?',),
        action: { label: t('UserWorkspaceSettingModal::Add user') },
        onConfirm: async () => {
          try {
            const response = await UserWorkspaceSettingsController.create(userWorkspaceSetting)
            const { errors } = response;

            if (errors) {
              this.setState({ errors: errors });
              Notification.notifyError(t('UserWorkspaceSettingModal::Oops something went wrong'))
            }
            else {
              Notification.notifySuccess(t('UserWorkspaceSettingModal::Team member successfully invited.'))
              if (onSubmit) onSubmit(response)
              close()
            }

          } catch (ex) {
            console.error(ex)
          }
        }
      })
    }
  }

  onUserWorkspaceSettingModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onEmailChange(e) {
    const { userWorkspaceSetting } = this.state
    const email = e.currentTarget.value

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
        email: email,
      }
    })
  }

  onRoleChange(option) {
    const { userWorkspaceSetting } = this.state

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
        role: option.value
      }
    })
  }

  onHourlyCostChange(hourlyCost: number) {
    const { userWorkspaceSetting } = this.state

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
        hourly_cost: hourlyCost
      }
    })
  }

  onHourlyRateChange(hourlyRate) {
    const { userWorkspaceSetting } = this.state

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
        hourly_rate: hourlyRate
      }
    })
  }

  onDayRateChange(dayRate) {
    const { userWorkspaceSetting } = this.state

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
        day_rate: dayRate
      }
    })
  }

  onToggleSelectAllScopes() {
    const { userWorkspaceSetting } = this.state

    const currentScopes = userWorkspaceSetting.scopes

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
        scopes: currentScopes.length === UserWorkspaceSettingHelper.SCOPES.length ? [] : UserWorkspaceSettingHelper.SCOPES,
      }
    })
  }

  onToggleScope(selectedScope: string) {
    const { userWorkspaceSetting } = this.state

    let newScopes = userWorkspaceSetting.scopes

    if (newScopes.find(scope => scope === selectedScope)) {
      newScopes = newScopes.filter(scope => scope !== selectedScope)
    } else {
      newScopes = [
        ...newScopes,
        selectedScope as UserWorkspaceSettingScope,
      ]
    }

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
        scopes: newScopes,
      }
    })
  }

  onWeekdayEnabledToggle(index: number) {
    const { userWorkspaceSetting } = this.state

    userWorkspaceSetting.week_schedule[index].enabled = !userWorkspaceSetting.week_schedule[index].enabled

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
      }
    })
  }

  onWeekdayStartTimeBlur(index, event) {
    const { userWorkspaceSetting } = this.state
    const newValue = event.currentTarget.value

    const valueParts = newValue.split(':').filter(v => v !== '')

    const hour = valueParts[0]
    const minutes = valueParts[1] || '00'

    if (hour && minutes) {
      const newTime = moment()
        .set('hour', hour)
        .set('minutes', minutes)

      userWorkspaceSetting.week_schedule[index].start_time = newTime.isValid() ? newTime.format('HH:mm') : userWorkspaceSetting.week_schedule[index].start_time

      this.setState({ userWorkspaceSetting: { ...userWorkspaceSetting, } }, this.syncInputs)
    } else {
      this.syncInputs()
    }
  }

  onWeekdayEndTimeBlur(index, event) {
    const { userWorkspaceSetting } = this.state
    const newValue = event.currentTarget.value

    const valueParts = newValue.split(':').filter(v => v !== '')

    const hour = valueParts[0]
    const minutes = valueParts[1] || '00'

    if (hour && minutes) {
      const newTime = moment()
        .set('hour', hour)
        .set('minutes', minutes)

      userWorkspaceSetting.week_schedule[index].end_time = newTime.isValid() ? newTime.format('HH:mm') : userWorkspaceSetting.week_schedule[index].end_time

      this.setState({ userWorkspaceSetting: { ...userWorkspaceSetting, } }, this.syncInputs)
    } else {
      this.syncInputs()
    }
  }

  onWeekdayLunchEnabledToggle(index) {
    const { userWorkspaceSetting } = this.state

    userWorkspaceSetting.week_schedule[index].break_enabled = !userWorkspaceSetting.week_schedule[index].break_enabled

    this.setState({
      userWorkspaceSetting: {
        ...userWorkspaceSetting,
      }
    })
  }

  onWeekdayLunchStartTimeBlur(index, event) {
    const { userWorkspaceSetting } = this.state
    const newValue = event.currentTarget.value

    const valueParts = newValue.split(':').filter(v => v !== '')

    const hour = valueParts[0]
    const minutes = valueParts[1] || '00'

    const newTime = moment()
      .set('hour', hour)
      .set('minutes', minutes)

    if (hour && minutes) {
      userWorkspaceSetting.week_schedule[index].break_start_time = newTime.isValid() ? newTime.format('HH:mm') : userWorkspaceSetting.week_schedule[index].break_start_time

      this.setState({ userWorkspaceSetting: { ...userWorkspaceSetting, } }, this.syncInputs)
    } else {
      this.syncInputs()
    }
  }

  onWeekdayLunchEndTimeBlur(index, event) {
    const { userWorkspaceSetting } = this.state
    const newValue = event.currentTarget.value

    const valueParts = newValue.split(':').filter(v => v !== '')

    const hour = valueParts[0]
    const minutes = valueParts[1] || '00'

    if (hour && minutes) {
      const newTime = moment()
        .set('hour', hour)
        .set('minutes', minutes)

      userWorkspaceSetting.week_schedule[index].break_end_time = newTime.isValid() ? newTime.format('HH:mm') : userWorkspaceSetting.week_schedule[index].break_end_time

      this.setState({ userWorkspaceSetting: { ...userWorkspaceSetting, } }, this.syncInputs)
    } else {
      this.syncInputs()
    }
  }

  renderNavigation(): JSX.Element {
    const { t } = this.props
    const { activeTab, userWorkspaceSetting } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === 'details'} data-tab='details' onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            {t('UserWorkspaceSettingModal::Details')}
          </span>
        </ModalNavigationItem>

        <ModalNavigationItem active={activeTab === 'working-days'} data-tab='working-days' onClick={this.onNavigationItemClick}>
          <Icon icon='calendar-day' />
          <span>
            {t('UserWorkspaceSettingModal::Working days')}
          </span>
        </ModalNavigationItem>

        {userWorkspaceSetting && userWorkspaceSetting.role !== UserWorkspaceSettingRole.OWNER && <ModalNavigationItem active={activeTab === 'permissions'} data-tab='permissions' onClick={this.onNavigationItemClick}>
          <Icon icon='lock' />
          <span>
            {t('UserWorkspaceSettingModal::Permissions')}
          </span>
        </ModalNavigationItem>}
      </ModalNavigation>
    )
  }

  renderDetails() {
    const { t, currentUser } = this.props
    const { activeTab, userWorkspaceSetting } = this.state;

    if (activeTab !== 'details') return null;

    const { workspace: { setting } } = currentUser

    const roleOptions = [
      { label: t(`UserWorkspaceSettingRole::${UserWorkspaceSettingRole.OWNER}`), value: String(UserWorkspaceSettingRole.OWNER) },
      { label: t(`UserWorkspaceSettingRole::${UserWorkspaceSettingRole.MANAGER}`), value: String(UserWorkspaceSettingRole.MANAGER) },
      { label: t(`UserWorkspaceSettingRole::${UserWorkspaceSettingRole.EMPLOYEE}`), value: String(UserWorkspaceSettingRole.EMPLOYEE) },
    ]

    const selectedRoleOption = roleOptions.find(option => option.value === String(userWorkspaceSetting.role))

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('UserWorkspaceSettingModal::Email')}<span>*</span>
              </label>
              <input
                type='email'
                name='email'
                onChange={this.onEmailChange}
                value={userWorkspaceSetting.email}
                placeholder='info@mycompany.be'
                required
                disabled={Boolean(userWorkspaceSetting.id)}
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('UserWorkspaceSettingModal::Role')}
              </label>
              <PowerSelect
                options={roleOptions}
                value={selectedRoleOption}
                onChange={this.onRoleChange}
                theme={ReactSelectTheme}
                isClearable={false}
                isDisabled={userWorkspaceSetting.admin}
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('UserWorkspaceSettingModal::External hourly rate')}
              </label>
              <MoneyInput
                name='amount'
                currency={setting.default_currency}
                numberFormat={setting.number_format}
                value={userWorkspaceSetting.hourly_rate}
                placeholderValue={setting.default_hourly_rate}
                onBlur={this.onHourlyRateChange}
                optional
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('UserWorkspaceSettingModal::External day rate')}
              </label>
              <MoneyInput
                name='amount'
                currency={setting.default_currency}
                numberFormat={setting.number_format}
                value={userWorkspaceSetting.day_rate}
                placeholderValue={setting.default_day_rate}
                onBlur={this.onDayRateChange}
                optional
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('UserWorkspaceSettingModal::Internal cost')}
              </label>
              <MoneyInput
                name='Internal cost'
                currency={setting.default_currency}
                numberFormat={setting.number_format}
                value={userWorkspaceSetting.hourly_cost}
                onBlur={this.onHourlyCostChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderWorkingDays() {
    const { t, currentUser } = this.props
    const { activeTab, userWorkspaceSetting } = this.state;

    if (activeTab !== 'working-days') return null;

    return (
      <div data-tab='details'>
        {userWorkspaceSetting.week_schedule.map((weekday, index) => {
          const {
            enabled,
            start_time,
            end_time,
            break_enabled,
            break_start_time,
            break_end_time
          }: WeekdaySchedule = weekday

          return (
            <div className='form-item' key={index}>
              <TimeContainer>
                <WeekdayLabelContainer>
                  <WeekdayLabel>
                    {t(`WeekdaySchedule::${index}`)}
                  </WeekdayLabel>

                  <Switch
                    name='enabled'
                    onClick={() => this.onWeekdayEnabledToggle(index)}
                    checked={enabled}
                  />
                </WeekdayLabelContainer>

                <TimeInputsContainer>
                  <MaskedInput
                    ref={input => this.startMaskedInputs[index] = input}
                    type='text'
                    defaultValue={start_time}
                    placeholder='00:00'
                    guide={false}
                    showMask={false}
                    mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                    onBlur={(event) => this.onWeekdayStartTimeBlur(index, event)}
                  />
                  <MaskedInput
                    ref={input => this.endMaskedInputs[index] = input}
                    type='text'
                    defaultValue={end_time}
                    placeholder='00:00'
                    guide={false}
                    showMask={false}
                    mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                    onBlur={(event) => this.onWeekdayEndTimeBlur(index, event)}
                  />
                </TimeInputsContainer>

                <WeekdayLabelContainer>
                  <WeekdayLabel>
                    {t('UserWorkspaceSettingModal::Lunch')}
                  </WeekdayLabel>

                  <Switch
                    name='enabled'
                    onClick={() => this.onWeekdayLunchEnabledToggle(index)}
                    checked={break_enabled}
                  />
                </WeekdayLabelContainer>

                <TimeInputsContainer>
                  {break_enabled && <MaskedInput
                    ref={input => this.breakStartMaskedInputs[index] = input}
                    type='text'
                    defaultValue={break_start_time}
                    placeholder='00:00'
                    guide={false}
                    showMask={false}
                    mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                    onBlur={(event) => this.onWeekdayLunchStartTimeBlur(index, event)}
                  />}
                  {break_enabled && <MaskedInput
                    ref={input => this.breakEndMaskedInputs[index] = input}
                    type='text'
                    defaultValue={break_end_time}
                    placeholder='00:00'
                    guide={false}
                    showMask={false}
                    mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                    onBlur={(event) => this.onWeekdayLunchEndTimeBlur(index, event)}
                  />}
                </TimeInputsContainer>
              </TimeContainer>
            </div>
          )
        })}
      </div >
    );
  }

  renderPermissions() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { activeTab, userWorkspaceSetting } = this.state;

    if (activeTab !== 'permissions') return null;

    const displayableScopes: DisplayableUserWorkspaceSettingScope[] = [
      { scope: UserWorkspaceSettingScope.DASHBOARD, description: t('AddTeamMember::Access dashboard') },
      { scope: UserWorkspaceSettingScope.CALENDAR, description: t('AddTeamMember::Access calendar') },
      { scope: UserWorkspaceSettingScope.TASK, description: t('AddTeamMember::Access boards') },
      { scope: UserWorkspaceSettingScope.CONTACT, description: t('AddTeamMember::Access contacts') },
      { scope: UserWorkspaceSettingScope.SEQUENCE, description: t('AddTeamMember::Access automated sequence campaigns') },
      { scope: UserWorkspaceSettingScope.PLAYBOOK, description: t('AddTeamMember::Access playbooks') },
      { scope: UserWorkspaceSettingScope.PROJECT, description: t('AddTeamMember::Access projects') },
      {
        scope: UserWorkspaceSettingScope.TIME_TRACKING,
        description: t('AddTeamMember::Access time tracking'),
        childScopes: [
          { scope: UserWorkspaceSettingScope.TIME_TRACKING_EXPORT, description: t('AddTeamMember::Export time tracking data') }
        ]
      },
      {
        scope: UserWorkspaceSettingScope.INVOICE,
        description: t('AddTeamMember::Access invoices'),
        childScopes: [
          { scope: UserWorkspaceSettingScope.INVOICE_EXPORT, description: t('AddTeamMember::Export invoice data') }
        ]
      },
      {
        scope: UserWorkspaceSettingScope.EXPENSE,
        description: t('AddTeamMember::Access expenses'),
        childScopes: [
          { scope: UserWorkspaceSettingScope.EXPENSE_EXPORT, description: t('AddTeamMember::Export expense data') }
        ]
      },
      {
        scope: UserWorkspaceSettingScope.DOCUMENT,
        description: t('AddTeamMember::Access documents'),
        childScopes: [
          { scope: UserWorkspaceSettingScope.DOCUMENT_EXPORT, description: t('AddTeamMember::Export document data') }
        ]
      },
      {
        scope: UserWorkspaceSettingScope.BANK,
        description: t('AddTeamMember::Access bank transactions'),
        childScopes: [
          { scope: UserWorkspaceSettingScope.BANK_PAYMENT, description: t('AddTeamMember::Execute payments') }
        ]
      },
      {
        scope: UserWorkspaceSettingScope.ACCOUNTANT,
        description: t('AddTeamMember::Allow sending financial documents to an accountant'),
      },
      {
        scope: UserWorkspaceSettingScope.PRODUCT,
        description: t('AddTeamMember::Access products'),
        childScopes: [
          { scope: UserWorkspaceSettingScope.PRODUCT_EXPORT, description: t('AddTeamMember::Export product data') },
          { scope: UserWorkspaceSettingScope.PRODUCT_VIEW_PURCHASE_PRICE, description: t('AddTeamMember::View purchase prices') }
        ]
      },
      { scope: UserWorkspaceSettingScope.REPORTING, description: t('AddTeamMember::Access reporting') },
      { scope: UserWorkspaceSettingScope.SETTING, description: t('AddTeamMember::Manage workspace settings') },
      { scope: UserWorkspaceSettingScope.FILE, description: t('AddTeamMember::Manage files') },
    ]

    return (
      <div data-tab='permissions'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <CheckboxInput
                onChange={this.onToggleSelectAllScopes}
                checked={userWorkspaceSetting.scopes.length === UserWorkspaceSettingHelper.SCOPES.length}
                label={t('UserWorkspaceSettingModal::Select/Deselect all')}
              />
            </div>
          </div>
        </div>

        <ScopesContainer>
          <Scopes>
            {displayableScopes.map(displayableScope => {
              return (
                <Scope key={displayableScope.scope}>
                  <ScopeLabel>
                    <div>
                      <input
                        type='checkbox'
                        name={displayableScope.scope}
                        checked={userWorkspaceSetting.scopes.includes(displayableScope.scope)}
                        onChange={() => this.onToggleScope(displayableScope.scope)}
                      />
                      {displayableScope.scope}
                    </div>
                    <p>{displayableScope.description}</p>
                  </ScopeLabel>

                  {displayableScope.childScopes && <ChildScopes>
                    {displayableScope.childScopes.map(childDisplayableScope => {
                      return (
                        <Scope key={childDisplayableScope.scope}>
                          <ScopeLabel>
                            <div>
                              <input
                                type='checkbox'
                                name={childDisplayableScope.scope}
                                checked={userWorkspaceSetting.scopes.includes(childDisplayableScope.scope)}
                                onChange={() => this.onToggleScope(childDisplayableScope.scope)}
                              />
                              {childDisplayableScope.scope}
                            </div>
                            <p>{childDisplayableScope.description}</p>
                          </ScopeLabel>
                        </Scope>
                      )
                    })}
                  </ChildScopes>}
                </Scope>
              )
            })}
          </Scopes>
        </ScopesContainer>
      </div>
    )
  }

  render() {
    const { userWorkspaceSetting, t } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={userWorkspaceSetting && userWorkspaceSetting.id ? t('UserWorkspaceSettingModal::Edit team member settings') : t('UserWorkspaceSettingModal::Add team member')}
          onCloseClick={this.onUserWorkspaceSettingModalCloseClick}
          navigation={this.renderNavigation()}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              {this.renderWorkingDays()}
              {this.renderPermissions()}

              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {userWorkspaceSetting && userWorkspaceSetting.id ? t('UserWorkspaceSettingModal::Save') : t('UserWorkspaceSettingModal::Invite')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      userWorkspaceSettingModal: {
        activeTab,
        userWorkspaceSetting,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    activeTab: activeTab,
    userWorkspaceSetting: userWorkspaceSetting,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeUserWorkspaceSettingModal()),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserWorkspaceSettingModal))