import Api from "./Api";
import Utils from "../utilities/Utils";
import {
  Contact,
  ItemsBlock,
  Proposal,
  SendProposalEmailParams,
  SignatureResult,
} from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class ProposalsController extends Api {
  static getProposal(id: string) {
    return fetch(`/api/proposals/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getProposals(params: object) {
    return fetch(`/api/proposals?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(proposal: Proposal) {
    return fetch("/api/proposals", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ proposal: proposal }),
    }).then((res) => res.json());
  }

  static update(proposal: Proposal) {
    return fetch(`/api/proposals/${proposal.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ proposal: proposal }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/proposals/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static duplicate(proposalId: string) {
    return fetch(`/api/proposals/${proposalId}/duplicate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/proposals/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getEmailForm(emailFormParams: { id: string }) {
    return fetch(
      `/api/proposals/${emailFormParams.id}/email_form?${Utils.encodeQueryData(
        emailFormParams
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static send(proposalId: string, email: SendProposalEmailParams) {
    return fetch(`/api/proposals/${proposalId}/send`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ email: email }),
    }).then((res) => res.json());
  }

  static getSigneeLinks(
    id: string
  ): Promise<{ signee_links: { signee: Contact; url: string }[] }> {
    return fetch(`/api/proposals/${id}/signee_links`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  // Contact facing api
  static sign(proposalId: string, signature: SignatureResult, signKey: string) {
    var formData = new FormData();

    formData.append("key", signKey);

    for (var key in signature) {
      if (!["id"].includes(key))
        formData.append(`signature[${key}]`, signature[key]);
    }

    return fetch(`/api/proposals/${proposalId}/sign`, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    }).then((res) => res.json());
  }

  static contactItemsBlockUpdate(
    proposalId: string,
    signKey: string,
    itemsBlock: ItemsBlock
  ): Promise<Proposal> {
    return fetch(`/api/proposals/${proposalId}/contact_update`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({ key: signKey, block: itemsBlock }),
    }).then((res) => res.json());
  }
}
