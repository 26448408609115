import Api from "./Api";
import Utils from "../utilities/Utils";
import {
  BlobResponse,
  ExportLedgerItemParams,
  InvoiceMetadata,
  LedgerItem,
  LedgerItemExportType,
  PaymentForm,
  SendLedgerItemEmailParams,
  SignatureResult,
} from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class InvoicesController extends Api {
  static getInvoices(page: number = 1, parameters = {}) {
    return fetch(
      `/api/invoices?page=${page}&${SearchHelper.getQueryString(parameters)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static getForm(params: any) {
    return fetch(`/api/invoices/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(ledgerItem: any) {
    return fetch("/api/invoices", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ invoice: ledgerItem }),
    }).then((res) => res.json());
  }

  static update(ledgerItem: LedgerItem) {
    return fetch(`/api/invoices/${ledgerItem.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ invoice: ledgerItem }),
    }).then((res) => res.json());
  }

  static updateLogo(formData: FormData) {
    return fetch("/api/workspace/settings", {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        Authorization: this.token,
      },
      body: formData,
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/invoices/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getShowView(id: string) {
    return fetch(`/api/invoices/${id}/view`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getEmailForm(emailFormParams: { id: string; reminder: boolean }) {
    return fetch(
      `/api/invoices/${emailFormParams.id}/email_form?${Utils.encodeQueryData(
        emailFormParams
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static sendEmail(email: SendLedgerItemEmailParams) {
    return fetch(`/api/invoices/${email.ledger_item_id}/send_email`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        email: email,
      }),
    }).then((res) => res.json());
  }

  static sendEmailReminder(email: SendLedgerItemEmailParams) {
    return fetch(`/api/invoices/${email.ledger_item_id}/send_email_reminder`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        email: email,
      }),
    }).then((res) => res.json());
  }

  static sendPeppol(ledgerItemId: string): Promise<LedgerItem> {
    return fetch(`/api/invoices/${ledgerItemId}/send_peppol`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static approveQuotation(id: string, signature: SignatureResult) {
    return fetch(`/api/invoices/${id}/approve`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({ signature: signature }),
    }).then((res) => res.json());
  }

  static getPaymentForm(id: string): Promise<PaymentForm> {
    return fetch(`/api/invoices/${id}/payment_form`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static duplicate(id: string) {
    return fetch(`/api/invoices/${id}/duplicate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static createCreditNote(id: string) {
    return fetch(`/api/invoices/${id}/create_credit_note`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static importMetadata(params: {
    contact_id?: string;
    project_id?: string;
    quotation_id?: string;
  }): Promise<InvoiceMetadata> {
    return fetch(
      `/api/invoices/import_metadata?${Utils.encodeQueryData(params)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static exportLedgerItems(
    params: ExportLedgerItemParams
  ): Promise<BlobResponse | { success: true }> {
    if (
      [LedgerItemExportType.PDF, LedgerItemExportType.UBL].includes(params.type)
    ) {
      return fetch(`/api/invoices/export?${Utils.encodeQueryData(params)}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }).then((res) => res.json());
    } else {
      return fetch(`/api/invoices/export?${Utils.encodeQueryData(params)}`, {
        method: "POST",
        headers: {
          Authorization: this.token,
        },
        credentials: "same-origin",
      }).then((res) => {
        return new Promise((resolve, reject) => {
          res.blob().then((blob) => {
            console.log(blob);
            resolve({
              filename: res.headers
                .get("content-disposition")
                .match(/filename="(.+)"/)[1],
              blob: blob,
            });
          });
        });
      });
    }
  }

  static claimForm(id: string): Promise<{
    ledgerItem: LedgerItem;
    integration_enabled: boolean;
    claimed: boolean;
    claim: any;
  }> {
    return fetch(`/api/invoices/${id}/claim_form`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static startClaim(id: string) {
    return fetch(`/api/invoices/${id}/claim`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
