import {
  NavigationActionTypes,
  SHOW_NAVIGATION,
  HIDE_NAVIGATION,
  TOGGLE_NAVIGATION,
  SET_HISTORY,
} from "./types";

export function showNavigation(): NavigationActionTypes {
  return { type: SHOW_NAVIGATION };
}

export function closeNavigation(): NavigationActionTypes {
  return { type: HIDE_NAVIGATION };
}

export function toggleNavigation(): NavigationActionTypes {
  return { type: TOGGLE_NAVIGATION };
}

export function setHistory(history: any): NavigationActionTypes {
  return { type: SET_HISTORY, history: history };
}
