import SearchHelper from "../../helpers/SearchHelper";
import { LedgerItem } from "../../types";
import Utils from "../../utilities/Utils";

export default class InvoicesController {
  static getInvoices(page: number = 1, parameters = {}) {
    return fetch(
      `/accountants/invoices?page=${page}&${SearchHelper.getQueryString(
        parameters
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static requestExport(
    type: "pdf" | "ubl",
    year: number,
    quarter: number,
    bookingStatus: "all" | "booked" | "unbooked"
  ) {
    return fetch(
      `/accountants/invoices/export?${Utils.encodeQueryData({
        type: type,
        year: year,
        quarter: quarter,
        booking_status: bookingStatus,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static update(ledgerItem: LedgerItem) {
    return fetch(`/accountants/invoices/${ledgerItem.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({ invoice: ledgerItem }),
    }).then((res) => res.json());
  }
}
