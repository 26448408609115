import Api from "./Api";
import Utils from "../utilities/Utils";
import { Board } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class BoardsController extends Api {
  static getBoards(params: object) {
    return fetch(`/api/boards?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static get(params: any) {
    return fetch(`/api/boards/${params.id}?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(board: Board, templateId?: string) {
    return fetch("/api/boards", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ board: board, template_id: templateId }),
    }).then((res) => res.json());
  }

  static update(board: Board) {
    return fetch(`/api/boards/${board.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ board: board }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/boards/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(id?: string) {
    const params = { id: id };

    return fetch(`/api/boards/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
