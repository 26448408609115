import Api from "./Api";
import Utils from "../utilities/Utils";
import { BlobResponse, Contact, ExportContactParams } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class ContactsController extends Api {
  static getContact(id: string) {
    return fetch(`/api/contacts/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getContacts(params: object) {
    return fetch(`/api/contacts?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(contact: Contact) {
    return fetch("/api/contacts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact: contact }),
    }).then((res) => res.json());
  }

  static update(contact: Contact) {
    return fetch(`/api/contacts/${contact.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact: contact }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/contacts/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/contacts/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static contactView(id: string) {
    return fetch(`/api/contacts/${id}/view`, {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
    }).then((res) => res.json());
  }

  static bulkDelete(contactIds: string[]) {
    return fetch(`/api/contacts/bulk_delete`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact_ids: contactIds }),
    }).then((res) => res.json());
  }

  static bulkAddGroups(contactIds: string[], groupIds: string[]) {
    return fetch(`/api/contacts/bulk_add_groups`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact_ids: contactIds, group_ids: groupIds }),
    }).then((res) => res.json());
  }

  static bulkRemoveGroups(contactIds: string[], groupIds: string[]) {
    return fetch(`/api/contacts/bulk_remove_groups`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact_ids: contactIds, group_ids: groupIds }),
    }).then((res) => res.json());
  }

  static export(
    params: ExportContactParams
  ): Promise<BlobResponse | { success: true }> {
    return fetch(`/api/contacts/export?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => {
      return new Promise((resolve, reject) => {
        res.blob().then((blob) => {
          resolve({
            filename: res.headers
              .get("content-disposition")
              .match(/filename="(.+)"/)[1],
            blob: blob,
          });
        });
      });
    });
  }
}
