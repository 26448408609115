import Api from "./Api";
import Utils from "../utilities/Utils";
import { ContactGroup } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class ContactGroupController extends Api {
  static getContactGroups(params: object) {
    return fetch(`/api/contact_groups?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(contactGroup: ContactGroup) {
    return fetch("/api/contact_groups", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact_group: contactGroup }),
    }).then((res) => res.json());
  }

  static update(contactGroup: ContactGroup) {
    return fetch(`/api/contact_groups/${contactGroup.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ contact_group: contactGroup }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/contact_groups/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: object) {
    return fetch(`/api/contact_groups/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
