import Api from "./Api";
import Utils from "../utilities/Utils";
import { Playbook, PlaybookSubmission, PlaybookableType } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class PlaybookSubmissionsController extends Api {
  static getPlaybookSubmission(playbookId: string, id: string) {
    return fetch(`/api/playbooks/${playbookId}/submissions/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getPlaybookSubmissions(playbookId: string, params: object) {
    return fetch(
      `/api/playbooks/${[playbookId]}/submissions?${SearchHelper.getQueryString(
        params
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static create(playbookSubmission: PlaybookSubmission) {
    return fetch(
      `/api/playbooks/${playbookSubmission.playbook_id}/submissions`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
        body: JSON.stringify({ submission: playbookSubmission }),
      }
    ).then((res) => res.json());
  }

  static update(playbookSubmission: PlaybookSubmission) {
    return fetch(
      `/api/playbooks/${playbookSubmission.playbook_id}/submissions/${playbookSubmission.id}}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
        body: JSON.stringify({ submission: playbookSubmission }),
      }
    ).then((res) => res.json());
  }

  static delete(playbookId: string, id: string) {
    return fetch(`/api/playbooks/${playbookId}/submissions/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: {
    id?: string;
    playbook_id: string;
    playbookable_type: PlaybookableType;
    playbookable_id: string;
  }): Promise<{
    playbook: Playbook;
    playbook_submission: PlaybookSubmission;
  }> {
    return fetch(
      `/api/playbooks/${
        params.playbook_id
      }/submissions/form?${Utils.encodeQueryData(params)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }
}
