import Api from "./Api";
import Utils from "../utilities/Utils";
import { Playbook } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class PlaybooksController extends Api {
  static getPlaybook(id: string) {
    return fetch(`/api/playbooks/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getPlaybooks(params: object) {
    return fetch(`/api/playbooks?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(playbook: Playbook) {
    return fetch("/api/playbooks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ playbook: playbook }),
    }).then((res) => res.json());
  }

  static update(playbook: Playbook) {
    return fetch(`/api/playbooks/${playbook.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ playbook: playbook }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/playbooks/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/playbooks/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
