import * as React from 'react';
import i18n from "../translations"
import { ContentBlock, ContentBlockType, Form } from '../types';
import Images from '../images';

export interface FormTemplate {
  icon: string
  title: string
  description: string
  contentBlocks: ContentBlock[]
}

export interface FormTemplateCategory {
  title: string
  templates: FormTemplate[]
}

export default class FormHelper {
  static showCurrencyOption(form: Form) {
    return form
      .content_blocks
      .some(block => [ContentBlockType.ITEMS, ContentBlockType.SUMMARY].includes(block.type))
  }

  static getFormTemplateCategories(): FormTemplateCategory[] {
    return [
      {
        title: i18n.t('FormTemplate::Build your own'),
        templates: [
          {
            icon: Images.START_PLAYBOOK_TEMPLATE,
            title: i18n.t('FormTemplate::Start from scratch'),
            description: i18n.t('FormTemplate::Start with a blank form. Add your own questions and fields.'),
            contentBlocks: [
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::First name'), placeholder: 'Jane', required: true },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::Last name'), placeholder: 'Doe', required: true },
              { type: ContentBlockType.EMAIL_INPUT, name: i18n.t('FormTemplate::Email'), required: true },
              { type: ContentBlockType.PHONE_NUMBER_INPUT, name: i18n.t('FormTemplate::Phone number'), required: true },
              { type: ContentBlockType.SUBMIT, text: i18n.t('FormTemplate::Submit') },
            ],
          }
        ]
      },
      {
        title: i18n.t('FormTemplate::Templates'),
        templates: [
          {
            icon: Images.CONTACT_FORM_TEMPLATE,
            title: i18n.t('FormTemplate::Contact form'),
            description: i18n.t('FormTemplate::Start with an existing contact form.'),
            contentBlocks: [
              { type: ContentBlockType.TEXT, content: i18n.t('FormTemplate::<h1>Contact form</h1><p>Hi there, please fill out this quick form and I\'ll be in touch shortly.</p>') },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::First name'), placeholder: 'Jane', required: true },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::Last name'), placeholder: 'Doe', required: true },
              { type: ContentBlockType.EMAIL_INPUT, name: i18n.t('FormTemplate::Email'), required: true },
              { type: ContentBlockType.PHONE_NUMBER_INPUT, name: i18n.t('FormTemplate::Phone number'), required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::What is your preferred form of communication'), options: [i18n.t('FormTemplate::Email'), i18n.t('FormTemplate::Phone')] },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::Please tell me a bit more about why you\'re reaching out.'), required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::How did you find out about us?'), options: [i18n.t('FormTemplate::Word of mouth'), i18n.t('FormTemplate::Google Search'), i18n.t('FormTemplate::Social')] },
              { type: ContentBlockType.SUBMIT, text: i18n.t('FormTemplate::Submit') },
            ],
          },
          {
            icon: Images.SATISFACTION_FORM_TEMPLATE,
            title: i18n.t('FormTemplate::Client Satisfaction survey'),
            description: i18n.t('FormTemplate::Improve your customer relationship with a follow-up client satisfaction survey.'),
            contentBlocks: [
              { type: ContentBlockType.TEXT, content: i18n.t('FormTemplate::<h1>Client Satisfaction Survey</h1><p>It\'s been a pleasure working together. Give us your impressions below!</p>') },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::First name'), placeholder: 'Jane', required: true },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::Last name'), placeholder: 'Doe', required: true },
              { type: ContentBlockType.EMAIL_INPUT, name: i18n.t('FormTemplate::Email'), required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::How would you rank your experience? (1 - 5)'), options: ['1', '2', '3', '4', '5'], required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::How would you rank the product / service we offered? (1 - 5)'), options: ['1', '2', '3', '4', '5'], required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::How did you find the onboarding process?'), options: [i18n.t('FormTemplate::Perfect'), i18n.t('FormTemplate::Easy'), i18n.t('FormTemplate::Okay, not too troublesome'), i18n.t('FormTemplate::Poor'), i18n.t('FormTemplate::Very poor')], required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::Please expand on your onboarding experience, if possible.'), required: false },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::Please expand on your ranking above, if possible.'), required: false },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::What could we do better?'), required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::Would you wish to be our client again?'), options: [i18n.t('FormTemplate::Absolutely!'), i18n.t('FormTemplate::Maybe'), i18n.t('FormTemplate::No')], required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::Is there anything else you would like to add regarding your experience with us, so that we may improve our business? It would very much be appreciated, if possible. Thank you!'), required: false },
              { type: ContentBlockType.SUBMIT, text: i18n.t('FormTemplate::Submit') },
            ],
          },
          {
            icon: Images.CLIENT_FORM_TEMPLATE,
            title: i18n.t('FormTemplate::Contact info'),
            description: i18n.t('FormTemplate::Get to know your customer with an intake form.'),
            contentBlocks: [
              { type: ContentBlockType.TEXT, content: i18n.t('FormTemplate::<h1>Contact info</h1><p>We are pleased that you contacted us. We would love to get to know more about you so that we can better service you as a client.</p>') },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::First name'), placeholder: 'Jane', required: true },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::Last name'), placeholder: 'Doe', required: true },
              { type: ContentBlockType.EMAIL_INPUT, name: i18n.t('FormTemplate::Email'), required: true },
              { type: ContentBlockType.PHONE_NUMBER_INPUT, name: i18n.t('FormTemplate::What is your primary contact phone number'), required: true },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::What is the URL (address) for your business website, if available?'), placeholder: 'https://www.mywebsite.com', required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::What is your official business address?'), required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::What would be the best or most convenient way of contacting you?'), required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::Please provide any social media handles related to your business or you as a professional.'), required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::What specific product or service do you want from us?'), required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::What is the primary nature of your business?'), options: [i18n.t('FormTemplate::Education'), i18n.t('FormTemplate::Engineering'), i18n.t('FormTemplate::Software'), i18n.t('FormTemplate::Manufacturing'), i18n.t('FormTemplate::Marketing'), i18n.t('FormTemplate::Non-profit'), i18n.t('FormTemplate::Professional services'), i18n.t('FormTemplate::Research and development'), i18n.t('FormTemplate::Retail'), i18n.t('FormTemplate::Other')], required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::What was your principal reason for contacting us and wishing to be our client?'), required: true },
              { type: ContentBlockType.DROPDOWN_INPUT, name: i18n.t('FormTemplate::Do you have a specific or maximum budget in mind for what we offer?'), options: [i18n.t('FormTemplate::$5,000-$10,000'), i18n.t('FormTemplate::$10,000-$50,000'), i18n.t('FormTemplate::$50,000-$100,000'), i18n.t('FormTemplate::Other')], required: true },
              { type: ContentBlockType.DATE_INPUT, name: i18n.t('FormTemplate::When would you like to start this project?'), required: true },
              { type: ContentBlockType.SUBMIT, text: i18n.t('FormTemplate::Submit') },
            ],
          },
          {
            icon: Images.AGENDA_FORM_TEMPLATE,
            title: i18n.t('FormTemplate::Meeting Agenda'),
            description: i18n.t('FormTemplate::Prepare for you meeting with a client with a pre-intake questionnaire.'),
            contentBlocks: [
              { type: ContentBlockType.TEXT, content: i18n.t('FormTemplate::<h1>Meeting Agenda</h1><p>Please answer these quick questions ahead of our meeting so I can tailor the agenda.</p>') },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::First name'), placeholder: 'Jane', required: true },
              { type: ContentBlockType.TEXT_INPUT, name: i18n.t('FormTemplate::Last name'), placeholder: 'Doe', required: true },
              { type: ContentBlockType.EMAIL_INPUT, name: i18n.t('FormTemplate::Email'), required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::Which topics would you like to discuss?'), required: true },
              { type: ContentBlockType.LONG_TEXT_INPUT, name: i18n.t('FormTemplate::What is your number one expectation from the meeting?'), required: true },
              { type: ContentBlockType.SUBMIT, text: i18n.t('FormTemplate::Submit') },
            ],
          }
        ]
      }
    ]
  }
}