import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { setHistory } from "../../store/navigation/actions"
import { Dispatch } from "redux"
import { AppState } from "../../store"
import { connect } from "react-redux"

interface IStateToProps { }
interface IDispatchToProps {
  setHistory: typeof setHistory
}

type IProps = {
  onRouteChange?: () => void
} & IStateToProps & IDispatchToProps

const NavigationDetection = (props: IProps) => {
  const history = useHistory<any>()
  const location = useLocation<any>()

  useEffect(() => {
    props.setHistory(history)
  }, [])

  useEffect(() => {
    if (props.onRouteChange) props.onRouteChange()
  }, [location])

  return null
}


const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    setHistory: (options) => dispatch(setHistory(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDetection)