import * as React from 'react'
import copy from 'copy-to-clipboard';
import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { showConfirmModal, showSendProposalModal, showShareLinksModal } from '../../store/modals/actions';
import { AppState } from '../../store';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import { ProposalsController } from '../../controllers';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import ProposalHelper from '../../helpers/ProposalHelper';
import Notification from '../../utilities/Notification';
import { ShareableLink } from '../Modals/ShareLinksModal';
import { IActionListItem } from '../ActionList/ActionList';
import PageLoader from '../Page/PageLoader';
import ResourceTable from '../Resource/ResourceTable';
import ResourceTableRow from '../Resource/ResourceTableRow';
import ResourceTableRowData from '../Resource/ResourceTableRowData';
import moment from '../../utilities/Moment';
import ResourceTableRowActions from '../Resource/ResourceTableRowActions';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import ButtonPanel from '../Button/ButtonPanel';
import { CurrentUser, Proposal, ProposalStatus, ResourceListFilterType } from '../../types';
import { useDebouncedCallback } from 'use-debounce';

interface IState {
	proposals: Proposal[],
	currentPage: number,
	totalPages: number
	didInitialLoad: boolean
	isFetching: boolean
	sortValue: string
	filters: any
	searchValue: string
}


interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showConfirmModal: typeof showConfirmModal
	showSendProposalModal: typeof showSendProposalModal
	showShareLinksModal: typeof showShareLinksModal
}

type IProps = { contactId?: string, projectId?: string, dealId?: string, primaryActionEnabled?: boolean } & IStateToProps & IDispatchToProps & RouteComponentProps<any>

const ProposalsTable = (props: IProps) => {
	const { showConfirmModal, primaryActionEnabled } = props
	const { t } = useTranslation()
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
	const { workspace: { setting } } = currentUser

	const [state, setState] = React.useState<IState>({
		proposals: [],
		currentPage: 0,
		totalPages: 0,
		didInitialLoad: false,
		isFetching: false,
		sortValue: LocalStorage.get(LocalStorageKey.PROPOSAL_SORT_VALUE, 'created_at_desc'),
		filters: {},
		searchValue: ''
	})

	const {
		proposals,
		currentPage,
		totalPages,
		didInitialLoad,
		filters,
		isFetching,
		sortValue,
		searchValue
	} = state

	const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

	React.useEffect(() => {
		debouncedFetchProposals(1)
	}, [filters, sortValue, searchValue])

	const fetchProposals = async (page: number) => {
		try {
			let params: any = {
				page: page,
				search: searchValue,
				order: `${sortValue}`,
				...filters
			}

			if (props.contactId) params = { ...params, contact_id: props.contactId }
			if (props.projectId) params = { ...params, project_id: props.projectId }
			if (props.dealId) params = { ...params, deal_id: props.dealId }

			setState({ ...state, isFetching: true })
			const response = await ProposalsController.getProposals(params)

			const { proposals: responseProposals, current_page, total_pages, total_entries } = response;

			setState({
				...state,
				proposals: [...responseProposals],
				currentPage: current_page,
				totalPages: total_pages,
				didInitialLoad: true,
				isFetching: false
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	const debouncedFetchProposals = useDebouncedCallback((page) => fetchProposals(page), 250);

	const onNewProposalClick = async () => {
		try {
			const proposal = await ProposalsController.create({
				id: null,
				contact_id: props.contactId,
				project_id: props.projectId,
				deal_id: props.dealId,
			})

			props.history.push(RouteHelper.process(ERoute.PATH_PROPOSAL, { id: proposal.id }))
		} catch (ex) {
			console.error(ex)
		}
	}

	const onFiltersChange = (newFilters: any) => {
		setState({ ...state, filters: newFilters })
	}

	const onSortChange = (newSortValue: string) => {
		LocalStorage.set(LocalStorageKey.PROPOSAL_SORT_VALUE, newSortValue)
		setState({ ...state, sortValue: newSortValue })
	}

	const onRowClick = (proposal: Proposal) => {
		props.history.push(RouteHelper.process(ERoute.PATH_PROPOSAL, { id: proposal.id }))
	}

	const onRowActionClick = (key: string, proposal: Proposal) => {
		switch (key) {
			case 'send-proposal':
				onSendProposalClick(proposal)
				break
			case 'copy-link':
				onCopyLinksClick(proposal)
				break
			case 'download':
				ProposalHelper.download(proposal.id)
				break
			case 'edit':
				props.history.push(RouteHelper.process(ERoute.PATH_PROPOSAL, { id: proposal.id }))
				break
			case 'duplicate':
				onDuplicateClick(proposal)
				break
			case 'delete':
				showConfirmModal({
					title: t('Proposal::Delete proposal'),
					description: t('Proposal::You are about to delete proposal <b>{{name}}</b>. Are you sure?', { name: proposal.name }),
					action: { label: t('Proposal::Delete'), isDestructive: true },
					onConfirm: async () => {
						try {
							const response = await ProposalsController.delete(proposal.id)

							if (response.errors) { }
							else {
								const proposalIndex = proposals.findIndex(c => c.id === proposal.id);

								proposals.splice(proposalIndex, 1);

								setState({ ...state, proposals: [...proposals] })

								Notification.notifySuccess(t('Proposal::Proposal successfully deleted'))
							}
						} catch (ex) {
							console.error(ex)
						}
					}
				})
		}
	}

	const onSendProposalClick = (proposal: Proposal) => {
		requestAnimationFrame(() => {
			props.showSendProposalModal({
				id: proposal.id,
			})
		})
	}

	const onCopyLinksClick = async (proposal: Proposal) => {
		try {
			const response = await ProposalsController.getSigneeLinks(proposal.id)

			const shareableLinks: ShareableLink[] = response.signee_links.map(signeeLink => {
				return {
					content: signeeLink.signee.name,
					url: signeeLink.url
				}
			})

			if (shareableLinks.length > 1) {
				requestAnimationFrame(() => {
					props.showShareLinksModal({
						title: t('Proposal::Share proposal'),
						shareableLinks: shareableLinks
					})
				})
			} else if (shareableLinks.length === 1) {
				const shareableLink = shareableLinks[0]
				copy(shareableLink.url)
				Notification.notifySuccess(t('ShareLinksModal::Copied to clipboard'))
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	const onDuplicateClick = async (proposal: Proposal) => {
		try {
			const response = await ProposalsController.duplicate(proposal.id)
			const duplicatedProposal = response

			props.history.push(RouteHelper.process(ERoute.PATH_PROPOSAL, { id: duplicatedProposal.id }))
		} catch (ex) {
			console.error(ex)
		}
	}
	const onSearchChange = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onSearchSubmit = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onClearFilters = () => {
		setState({ ...state, searchValue: '', filters: {} })
	}


	return (
		<>
			{!didInitialLoad && <PageLoader />}
			{didInitialLoad && <ResourceTable
				data={proposals}
				actionsLeft={primaryActionEnabled ? [
					<ButtonPanel
						icon='plus'
						text={t('Proposals::New proposal')}
						onClick={onNewProposalClick}
					/>
				] : null}
				headers={[
					{ title: t('Proposals::Name'), colSpan: 2 },
					{ title: t('Proposals::Contact') },
					{ title: t('Proposals::Project') },
					{ title: t('Proposals::Expires on'), align: 'right' },
					{ title: t('Proposals::Status'), align: 'right' },
					{ title: t('Proposals::Creation date'), align: 'right' },
					{ title: '', stickyRight: '0px' },
				]}
				renderRow={(proposal: Proposal) => {
					const sendable = ProposalHelper.sendable(proposal)

					let actions: IActionListItem[] = [
						{ key: 'send-proposal', icon: 'send', content: t('Proposal::Send proposal'), visible: sendable },
						{ key: 'copy-link', icon: 'link', content: t('Proposal::Copy link'), visible: sendable },
						{ key: 'download', icon: 'download-circle', content: t('Proposals::Download') },
						{ key: 'duplicate', icon: 'duplicate', content: t('Proposals::Duplicate') },
						{ key: 'edit', icon: 'edit-solid', content: t('Proposals::Edit') },
						{ key: 'delete', icon: 'trash-alt-solid', content: t('Proposals::Delete'), destructive: true },
					]

					return (
						<ResourceTableRow key={proposal.id}>
							<ResourceTableRowData onClick={(): void => onRowClick(proposal)} colSpan={2} maxWidth='150px' ellipse>
								<b>{proposal.name ? proposal.name : '-'}</b>
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(proposal)} maxWidth='150px' ellipse>
								{proposal.contact ? proposal.contact.name : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(proposal)} maxWidth='150px' ellipse>
								{proposal.project ? proposal.project.name : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(proposal)} textAlign='right'>
								{proposal.expires_on ? proposal.expires_on : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(proposal)} textAlign='right'>
								{ProposalHelper.getBadge(proposal)}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(proposal)} textAlign='right'>
								{moment(proposal.created_at).format(setting.date_format)}
							</ResourceTableRowData>
							<ResourceTableRowActions
								actions={actions}
								onActionClick={(key) => onRowActionClick(key, proposal)}
								sticky={true}
								stickyRight='0px'
							/>
						</ResourceTableRow>
					)
				}}
				renderEmpty={<CardEmptyInfo
					icon={filtersActive ? 'search' : 'proposal'}
					description={filtersActive ? t('Proposals::No proposals found') : t('Proposals::No proposals have been created yet')}
					descriptionActionText={filtersActive ? t('Proposals::Clear filters') : t('Proposals::Add proposal')}
					onDescriptionActionClick={filtersActive ? onClearFilters : onNewProposalClick}
				/>}
				filters={[
					{ name: 'name', label: t('Proposals::Name'), type: ResourceListFilterType.STRING },
					{ name: 'contact_id', label: t('Proposals::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
					{ name: 'project_id', label: t('Proposals::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
					{
						name: 'status',
						label: t('Proposals::Status'), type: ResourceListFilterType.MULTIPLE_OPTION,
						options: [
							{ label: t(`ProposalStatus::${ProposalStatus.DRAFT}`), value: ProposalStatus.DRAFT },
							{ label: t(`ProposalStatus::${ProposalStatus.PENDING}`), value: ProposalStatus.PENDING },
							{ label: t(`ProposalStatus::${ProposalStatus.ACCEPTED}`), value: ProposalStatus.ACCEPTED },
							{ label: t(`ProposalStatus::${ProposalStatus.DECLINED}`), value: ProposalStatus.DECLINED },
							{ label: t(`ProposalStatus::${ProposalStatus.CANCELLED}`), value: ProposalStatus.CANCELLED }]
					},
					{ name: 'created_at', label: t('Proposals::Created date'), type: ResourceListFilterType.DATE },
				]}
				onFiltersChange={onFiltersChange}
				sortOptions={[
					{ label: '-', value: '-' },
					{ label: t('Proposals::Name (A-Z)'), value: 'name_asc' },
					{ label: t('Proposals::Name (Z-A)'), value: 'name_desc' },
					{ label: t('Proposals::Created at ↑'), value: 'created_at_asc' },
					{ label: t('Proposals::Created at ↓'), value: 'created_at_desc' },
				]}
				sortValue={sortValue}
				onSortChange={onSortChange}
				pagination={{ page: currentPage, pageCount: totalPages }}
				onPageChange={(page) => fetchProposals(page)}
				isLoading={isFetching}
				stickyHeader={true}
				searchValue={searchValue}
				onSearchChange={onSearchChange}
				onSearchSubmit={onSearchSubmit}
				maxHeight='65vh'
			/>}
		</>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showConfirmModal,
	showSendProposalModal,
	showShareLinksModal
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProposalsTable))