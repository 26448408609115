import Api from "./Api";
import Utils from "../utilities/Utils";
import SearchHelper from "../helpers/SearchHelper";

export default class BizzeyInvoicesController extends Api {
  static getInvoices(page: number = 1, parameters = {}) {
    return fetch(
      `/api/bizzey_invoices?page=${page}&${SearchHelper.getQueryString(
        parameters
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static download(id: string) {
    return fetch(`/api/bizzey_invoices/${id}/download`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
