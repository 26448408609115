import * as React from 'react'
import ScrollToTopOnMount from '../Effects/ScrollToTopOnMount'
import TopNavigation from '../Navigation/TopNavigation'
import Icon from '../Icons/Icon'
import { RouteComponentProps } from 'react-router-dom'
import { showConfirmModal, showExportProductsModal, showProductImportModal, showProductModal } from '../../store/modals/actions'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ProductsController } from '../../controllers'
import PageLoader from '../Page/PageLoader'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import { IActionListItem } from '../ActionList/ActionList'
import Notification from '../../utilities/Notification'
import UrlHelper from '../../helpers/UrlHelper'
import ERoute from '../../ERoute'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import PageContent from '../Page/PageContent'
import { Helmet } from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'
import PageHeader from '../Page/PageHeader'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import ResourceTable, { ResourceTableAction } from '../Resource/ResourceTable'
import ResourceTableRow from '../Resource/ResourceTableRow'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import ResourceTableRowActions from '../Resource/ResourceTableRowActions'
import Badge from '../Badge/Badge'
import NumberFormatter from '../../utilities/NumberFormatter'
import { CurrentUser, Product, ProductFilter, ProductFilters, ResourceListFilterType, SpreadsheetExportType, UserWorkspaceSettingScope } from '../../types'
import RouteHelper from '../../helpers/RouteHelper'
import NavigationDetection from '../Effects/NavigationDetection'
import SearchHelper from '../../helpers/SearchHelper'

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showProductModal: typeof showProductModal
  showConfirmModal: typeof showConfirmModal
  showProductImportModal: typeof showProductImportModal
  showExportProductsModal: typeof showExportProductsModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation
interface IState {
  products: Product[]
  page: number
  totalPages: number
  didInitialLoad: boolean
  sortValue: string
  isFetching: boolean
  activeFilters: any
  selectedProductIds: string[]
  search: string
}


class Products extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { search, page, filters } = SearchHelper.getSearch(this.props.location.search, ProductFilters)

    this.state = {
      products: [],
      page: page,
      totalPages: 1,
      didInitialLoad: false,
      sortValue: LocalStorage.get(LocalStorageKey.PRODUCT_SORT_VALUE, '-'),
      isFetching: false,
      activeFilters: filters,
      selectedProductIds: [],
      search: search,
    }

    this.onNewProductClick = this.onNewProductClick.bind(this)
    this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)
    this.onExportProductsClick = this.onExportProductsClick.bind(this)
    this.onTableProductEditClick = this.onTableProductEditClick.bind(this);
    this.onTableProductDeleteClick = this.onTableProductDeleteClick.bind(this);
    this.onTableProductActionClick = this.onTableProductActionClick.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onProductFormSubmit = this.onProductFormSubmit.bind(this)
    this.onProductUpdateFormSubmit = this.onProductUpdateFormSubmit.bind(this)
    this.onProductFiltersChange = this.onProductFiltersChange.bind(this)
    this.onProductSortValueChange = this.onProductSortValueChange.bind(this)
    this.onProductSearchChange = this.onProductSearchChange.bind(this)
    this.onProductSearchSubmit = this.onProductSearchSubmit.bind(this)
    this.onProductFiltersClear = this.onProductFiltersClear.bind(this)
    this.onBulkDeleteClick = this.onBulkDeleteClick.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onRouteChange = this.onRouteChange.bind(this)
  }

  componentDidUpdate(prevProps: IProps) {
    const { showProductModal } = this.props
    const params = UrlHelper.getParams(this.props.location.search)
    const previousParams = UrlHelper.getParams(prevProps.location.search)

    if (params.id !== previousParams.id) {
      this.props.history.replace(ERoute.PATH_PRODUCTS)
      requestAnimationFrame(() => {
        showProductModal({
          product: { id: params.id },
          onSubmit: this.onProductUpdateFormSubmit
        })
      })
    }
  }

  componentDidMount() {
    const { page } = this.state
    this.fetchProducts(page)
  }

  onRouteChange() {
    const { search, page, filters } = SearchHelper.getSearch(this.props.location.search, ProductFilters)

    this.setState({
      search: search,
      page: page,
      activeFilters: filters
    }, () => {
      this.fetchProducts(page)
    })
  }

  onPageHeaderActionClick(key: string) {
    switch (key) {
      case 'import_products': this.onImportProductsClick()
        break
      case 'export_products': this.onExportProductsClick()
        break
    }
  }

  fetchProducts(page = 1) {
    const { search, sortValue, activeFilters } = this.state

    this.setState({ isFetching: true })

    ProductsController
      .getProducts({ page: page, search: search, order: sortValue, ...activeFilters })
      .then(response => {
        const { current_page, total_pages, products } = response

        this.setState({
          products: products,
          page: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
        });
      })
      .catch(console.error)
      .finally(() => {
        this.setState({ isFetching: false })
      })
  }

  onNewProductClick(e) {
    e.preventDefault();

    const { showProductModal } = this.props

    showProductModal({
      onSubmit: this.onProductFormSubmit
    })
  }

  onImportProductsClick() {
    this.props.showProductImportModal({})
  }

  async onExportProductsClick() {
    try {
      // Get blob result
      const result: any = await ProductsController.export({
        spreadsheet_type: LocalStorage.get(LocalStorageKey.PREFERRED_EXPORT_FORMAT, SpreadsheetExportType.CSV),
      })

      // Blog destructering
      const { blob, filename } = result

      // Use save as library
      // @ts-ignore
      saveAs(blob, filename)
    } catch (ex) {
      console.error(ex)
    }
  }

  onTableProductEditClick(product) {
    const { showProductModal } = this.props

    showProductModal({
      product: { id: product.id },
      onSubmit: this.onProductUpdateFormSubmit
    })
  }

  onTableProductDeleteClick(product: Product) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Products::Delete product'),
      description: t('Products::You are about to delete product <b>{{name}}</b>. Are you sure?', { name: product.name }),
      action: { label: t('Products::Delete'), isDestructive: true },
      onConfirm: () => {
        ProductsController
          .delete(product.id)
          .then(response => {
            Notification.notifySuccess(t('Products::Product removed successfully'))
            const { products } = this.state

            const productIndex = products.findIndex(i => i.id === product.id);
            products.splice(productIndex, 1);

            this.setState({
              products
            });
          })
      }
    })
  }

  onTableProductActionClick(key: string, product: Product) {
    switch (key) {
      case 'edit': this.onTableProductEditClick(product)
        break
      case 'delete': this.onTableProductDeleteClick(product)
        break
      default:
        throw Error('[ProductsTable] Unimplemented onTableProductActionClick')
    }
  }

  onTableSelectionChange(selectedProductIds: string[]) {
    this.setState({ selectedProductIds: selectedProductIds })
  }

  onTableRowSelectionChange(selected: boolean, transactionId: string) {
    const { selectedProductIds } = this.state

    if (selected) {
      this.setState({ selectedProductIds: [...selectedProductIds, transactionId] })
    } else {
      this.setState({ selectedProductIds: selectedProductIds.filter(selectedTransactionId => selectedTransactionId !== transactionId) })
    }
  }

  onProductFormSubmit(product: Product) {
    const { products } = this.state;

    this.setState({
      products: [
        product,
        ...products
      ]
    });
  }

  onProductUpdateFormSubmit(product: Product) {
    const { products } = this.state

    const productIndex = products.findIndex(c => c.id === product.id);

    if (productIndex !== -1) {
      products[productIndex] = product
    }

    this.setState({
      products: [
        ...products,
      ]
    })
  }

  onProductFiltersChange(activeFilters: any) {
    this.setState({ page: 1, activeFilters: activeFilters }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onProductSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.PRODUCT_SORT_VALUE, value)

    this.setState({ page: 1, sortValue: value, }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onProductSearchChange(search) {
    this.setState({ search: search })
  }

  onProductSearchSubmit(search) {
    this.setState({ page: 1, search: search }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onProductFiltersClear() {
    this.setState({ page: 1, search: '', activeFilters: {} }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onBulkDeleteClick() {
    const { showConfirmModal, t } = this.props
    const { products, selectedProductIds } = this.state

    showConfirmModal({
      title: t('Product::Delete products'),
      description: t('Product::You are about to delete {{count}} products. By deleting these products you are also deleting all their associated data. Are you sure?', { count: selectedProductIds.length }),
      action: { label: t('Product::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          await ProductsController.bulkDelete(selectedProductIds)

          this.setState({
            selectedProductIds: [],
            products: products.filter(product => !selectedProductIds.includes(product.id))
          })
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onPageChange(page: number) {
    this.setState({ page: page }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  render(): JSX.Element {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const {
      didInitialLoad,
      products,
      page,
      totalPages,
      activeFilters,
      search,
      sortValue,
      isFetching,
      selectedProductIds
    } = this.state;

    const filtersActive = search?.length > 0 || Object.keys(activeFilters).length > 0

    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'trash-alt-solid', content: t('Contacts::Delete'), onAction: this.onBulkDeleteClick, destructive: true }
    ]

    const bulkActions: ResourceTableAction[] = []
    const filters: ProductFilter[] = [
      { name: 'name', label: t('Products::Name'), type: ResourceListFilterType.STRING },
      { name: 'price', label: t('Products::Price'), type: ResourceListFilterType.NUMBER },
      { name: 'purchase_price', label: t('Products::Purchase price'), type: ResourceListFilterType.NUMBER },
      { name: 'gross_price', label: t('Products::Gross price'), type: ResourceListFilterType.NUMBER },
      { name: 'supplier_id', label: t('Products::Supplier'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'company', isValidNewOption: () => false },
      { name: 'sku', label: t('Products::SKU'), type: ResourceListFilterType.STRING },
      { name: 'barcode', label: t('Products::Barcode'), type: ResourceListFilterType.STRING },
      { name: 'stock', label: t('Products::Stock'), type: ResourceListFilterType.NUMBER },
      { name: 'minimum_stock', label: t('Products::Minimum stock'), type: ResourceListFilterType.NUMBER },
      { name: 'physical', label: t('Products::Physical'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Products::Yes'), value: String(true) }, { label: t('Products::No'), value: String(false) }] },
      { name: 'created_at', label: t('Products::Created date'), type: ResourceListFilterType.DATE },
    ]

    return (
      <>
        <Helmet>
          <title>{t('Products::{{__appName}} | Products')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <NavigationDetection onRouteChange={this.onRouteChange} />
        <TopNavigation
          icon='product-navigation'
          title={t('Products::Products')}
          action={
            <a key='new-product' href='javascript://' className='button button-primary page-action' onClick={this.onNewProductClick}>
              <Icon icon='plus' />
              {t('Products::New product')}
            </a>
          }
        />

        <PageContent>
          <PageHeader
            title={t('Products::Products')}
            mainActions={[
              { key: 'import_products', content: t('Products::Import products'), icon: 'file-import' },
              { key: 'export_products', content: t('Products::Export products'), icon: 'download-circle', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PRODUCT_EXPORT) },
            ]}
            onMainActionClick={this.onPageHeaderActionClick}
          />

          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ResourceTable
            style={{ marginBottom: 75 }}
            data={products}
            headers={[
              { title: t('Products::Name'), colSpan: 2 },
              { title: t('Products::Supplier') },
              { title: t('Products::Physical') },
              { title: t('Products::Stock'), align: 'right' },
              { title: t('Products::Price'), align: 'right' },
              { title: '', stickyRight: '0px' },
            ]}
            renderRow={(product: Product) => {
              let actions: IActionListItem[] = [
                { key: 'edit', icon: 'edit-solid', content: t('Products::Edit') },
                { key: 'delete', icon: 'trash-alt-solid', content: t('Products::Delete'), destructive: true },
              ]

              return (
                <ResourceTableRow
                  key={product.id}
                  selected={selectedProductIds.includes(product.id)}
                  onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, product.id)}
                >
                  <ResourceTableRowData onClick={(): void => this.onTableProductEditClick(product)} colSpan={2} maxWidth='150px' ellipse>
                    <b>{product.name}</b>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={(): void => this.onTableProductEditClick(product)} maxWidth='150px' ellipse>
                    {product.supplier ? product.supplier.name : '-'}
                  </ResourceTableRowData>

                  <ResourceTableRowData onClick={(): void => this.onTableProductEditClick(product)}>
                    <Badge
                      type='grey'
                      text={product.physical ? t('Products::Yes') : t('Products::No')}
                    />
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={(): void => this.onTableProductEditClick(product)} textAlign='right'>
                    <div>{product.stock ? product.stock : '∞'}</div>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={(): void => this.onTableProductEditClick(product)} textAlign='right'>
                    {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, product.price)}
                  </ResourceTableRowData>
                  <ResourceTableRowActions
                    actions={actions}
                    onActionClick={(key) => this.onTableProductActionClick(key, product)}
                    sticky={true}
                    stickyRight='0px'
                  />
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'box'}
              description={filtersActive ? t('Products::No products found') : t('Products::No products have been created yet')}
              descriptionActionText={filtersActive ? t('Products::Clear filters') : t('Products::Add product')}
              onDescriptionActionClick={filtersActive ? this.onProductFiltersClear : this.onNewProductClick}
            />}
            filters={filters}
            activeFilters={activeFilters}
            onFiltersChange={this.onProductFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('Products::Name (A-Z)'), value: 'name_asc' },
              { label: t('Products::Name (Z-A)'), value: 'name_desc' },
              { label: t('Products::Price ↑'), value: 'price_asc' },
              { label: t('Products::Price ↓'), value: 'price_desc' },
              { label: t('Products::Stock ↑'), value: 'stock_asc' },
              { label: t('Products::Stock ↓'), value: 'stock_desc' },
              { label: t('Products::Created at ↑'), value: 'created_at_asc' },
              { label: t('Products::Created at ↓'), value: 'created_at_desc' },
            ]}
            sortValue={sortValue}
            onSortChange={this.onProductSortValueChange}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            pagination={{ page: page, pageCount: totalPages }}
            onPageChange={this.onPageChange}
            isLoading={isFetching}
            stickyHeader={true}
            selectedItems={selectedProductIds}
            onSelectionChange={this.onTableSelectionChange}
            searchValue={search}
            onSearchChange={this.onProductSearchChange}
            onSearchSubmit={this.onProductSearchSubmit}
            maxHeight='55vh'
          />}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showProductModal: (options) => dispatch(showProductModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showProductImportModal: (options) => dispatch(showProductImportModal(options)),
    showExportProductsModal: (options) => dispatch(showExportProductsModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Products))