import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux'
import { AppState } from '../store'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import Notification from '../utilities/Notification'
import { showPaymentModal, showConfirmModal, showPaymentInitiationModal } from '../store/modals/actions'
import { PaymentsController } from '../controllers'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import LocalStorage, { LocalStorageKey } from '../LocalStorage';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import ResourceTable, { ResourceTableAction } from '../components/Resource/ResourceTable';
import ResourceTableRow from '../components/Resource/ResourceTableRow';
import ResourceTableRowData from '../components/Resource/ResourceTableRowData';
import ResourceTableRowActions from '../components/Resource/ResourceTableRowActions';
import { RouteComponentProps } from 'react-router';
import { Payment, CurrentUser, ResourceListFilterType, RemittanceInformationType, PaymentInitiationStatus, PaymentFilter, PaymentFilters } from '../types';
import PageContent from '../components/Page/PageContent';
import SummaryContainer from '../components/Summary/SummaryContainer';
import SummaryItem from '../components/Summary/SummaryItem';
import NumberFormatter from '../utilities/NumberFormatter';
import { Style } from '../styles';
import OgmHelper from '../helpers/OgmHelper';
import moment from '../utilities/Moment';
import PaymentHelper from '../helpers/PaymentHelper';
import TopNavigation from '../components/Navigation/TopNavigation';
import PageHeader from '../components/Page/PageHeader';
import Icon from '../components/Icons/Icon';
import ResourceTableRowDataLink from '../components/Resource/ResourceTableRowDataLink';
import UrlHelper from '../helpers/UrlHelper';
import SearchHelper from '../helpers/SearchHelper';
import RouteHelper from '../helpers/RouteHelper';
import ERoute from '../ERoute';
import NavigationDetection from '../components/Effects/NavigationDetection';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showPaymentModal: typeof showPaymentModal
  showConfirmModal: typeof showConfirmModal
  showPaymentInitiationModal: typeof showPaymentInitiationModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  payments: Payment[],
  page: number,
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
  activeFilters: any
  summaryDidInitialLoad: boolean
  summary: {
    draft_amount: number
    unsigned_amount: number
    month_amount: number
  },
  selectedPaymentIds: string[]
  search: string
}

class Payments extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    const { search, page, filters } = SearchHelper.getSearch(this.props.location.search, PaymentFilters)

    this.state = {
      payments: [],
      selectedPaymentIds: [],
      page: page,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.PAYMENT_SORT_VALUE, '-'),
      summary: null,
      summaryDidInitialLoad: false,
      activeFilters: filters,
      search: search
    }

    this.onRouteChange = this.onRouteChange.bind(this)
    this.onNewPaymentClick = this.onNewPaymentClick.bind(this);
    this.onTablePaymentClick = this.onTablePaymentClick.bind(this)
    this.onTableActionClick = this.onTableActionClick.bind(this)
    this.onTablePaymentInitiationClick = this.onTablePaymentInitiationClick.bind(this)
    this.onTablePaymentUpdateClick = this.onTablePaymentUpdateClick.bind(this)
    this.onTablePaymentDeleteClick = this.onTablePaymentDeleteClick.bind(this);
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onPaymentFormSubmit = this.onPaymentFormSubmit.bind(this)
    this.onPaymentUpdateFormSubmit = this.onPaymentUpdateFormSubmit.bind(this)
    this.onPaymentFiltersChange = this.onPaymentFiltersChange.bind(this)
    this.onPaymentSortValueChange = this.onPaymentSortValueChange.bind(this)
    this.onPaymentSearchChange = this.onPaymentSearchChange.bind(this)
    this.onPaymentSearchSubmit = this.onPaymentSearchSubmit.bind(this)
    this.onPaymentClearFilters = this.onPaymentClearFilters.bind(this)
    this.onResourceBulkPaymentActionClick = this.onResourceBulkPaymentActionClick.bind(this)
    this.onResourceBulkDeleteActionClick = this.onResourceBulkDeleteActionClick.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
  }

  componentWillMount() {
    const { page } = this.state

    this.fetchPayments(page)
    this.fetchSummary()
    this.parseQueryParams()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.location.search !== this.props.location.search) {
      this.parseQueryParams()
    }
  }

  parseQueryParams() {
    const { showPaymentModal, location: { search } } = this.props
    const params = UrlHelper.getParams(search)

    if (params.id) {
      showPaymentModal({
        payment: { id: params.id },
        onSubmit: this.onPaymentFormSubmit
      })
    }
  }

  onRouteChange() {
    const { search, page, filters } = SearchHelper.getSearch(this.props.location.search, PaymentFilters)

    this.setState({ search: search, page: page, activeFilters: filters }, () => {
      this.fetchPayments(page)
    })
  }

  fetchPayments(page: number) {
    const { search, sortValue, activeFilters } = this.state
    this.setState({
      isFetching: true
    }, () => {
      PaymentsController
        .getPayments({ page: page, search: search, order: `${sortValue}`, ...activeFilters })
        .then(response => {
          const { payments, current_page, total_pages, total_entries } = response;

          this.setState({
            payments: [...payments],
            page: current_page,
            totalPages: total_pages,
            didInitialLoad: true,
            isFetching: false
          });
        })
        .catch(console.error)
    });
  }


  async fetchSummary() {
    try {
      this.setState({ isFetching: true })
      const summary = await PaymentsController.getSummary()

      this.setState({
        summary: summary,
        summaryDidInitialLoad: true
      });
    } catch (ex) {
      console.error(ex)
    }
  }

  onNewPaymentClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    const { showPaymentModal } = this.props

    showPaymentModal({
      payment: {},
      onSubmit: this.onPaymentFormSubmit,
    })
  }

  onTablePaymentClick(payment: Payment) {
    this.props.showPaymentModal({
      payment: { id: payment.id },
      onSubmit: this.onPaymentUpdateFormSubmit,
    })
  }

  onTableActionClick(key: string, payment: Payment) {
    switch (key) {
      case 'view_payment':
      case 'schedule_payment': this.onTablePaymentInitiationClick(payment)
        break
      case 'sign_payment':
        if (payment?.payment_initiation?.sign_redirect_url) window.location.assign(payment?.payment_initiation?.sign_redirect_url)
        break
      case 'update': this.onTablePaymentUpdateClick(payment)
        break
      case 'delete': this.onTablePaymentDeleteClick(payment)
        break
      default:
        throw Error('[Payments] Unimplemented onTableActionClick')
    }
  }

  onTablePaymentInitiationClick(payment: Payment) {
    const { payments } = this.state

    this.props.showPaymentInitiationModal({
      paymentInitiation: {
        id: payment?.payment_initiation?.id,
        payments: [payment]
      },
      onDelete: (paymentInitiation) => {
        payments.forEach(payment => {
          if (payment.payment_initiation_id === paymentInitiation.id) {
            payment.execution_date = null
            payment.payment_initiation = null
            payment.payment_initiation_id = null
          }
        })

        this.setState({ payments: [...payments] })
      }
    })
  }

  onTablePaymentUpdateClick(payment: Payment) {
    const { showPaymentModal } = this.props

    showPaymentModal({
      payment: { id: payment.id },
      onSubmit: this.onPaymentUpdateFormSubmit,
    })
  }

  onTablePaymentDeleteClick(payment: Payment) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Payments::Delete payment'),
      description: t('Payments::You are about to delete this payment. By deleting this payment you are also deleting all its associated data. Are you sure?'),
      action: { label: t('Payments::Delete'), isDestructive: true },
      onConfirm: () => {
        PaymentsController
          .delete(payment.id)
          .then((response) => {
            if (response.errors) {

            } else {
              const { payments } = this.state;

              const paymentIndex = payments.findIndex(c => c.id === payment.id);

              payments.splice(paymentIndex, 1);

              this.setState({
                payments: payments
              });

              Notification.notifySuccess(t('Payments::Payment successfully deleted'))
            }
          })
          .catch(console.error)
      }
    })
  }

  onTableSelectionChange(selectedPaymentIds: string[]) {
    this.setState({ selectedPaymentIds: selectedPaymentIds })
  }

  onTableRowSelectionChange(selected: boolean, paymentId: string) {
    const { selectedPaymentIds } = this.state

    if (selected) {
      this.setState({ selectedPaymentIds: [...selectedPaymentIds, paymentId] })
    } else {
      this.setState({ selectedPaymentIds: selectedPaymentIds.filter(selectedPaymentId => selectedPaymentId !== paymentId) })
    }
  }

  onPaymentFormSubmit(payment: Payment) {
    const { payments } = this.state;

    const newPayments = [payment, ...payments];

    this.setState({ payments: newPayments });
  }

  onPaymentUpdateFormSubmit(payment: Payment) {
    const { payments } = this.state

    const paymentIndex = payments.findIndex(c => c.id === payment.id);

    if (paymentIndex !== -1) {
      payments[paymentIndex] = payment
    }

    this.setState({
      payments: [
        ...payments,
      ]
    })
  }

  onPaymentFiltersChange(activeFilters: any) {
    this.setState({ page: 1, activeFilters: activeFilters }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onPaymentSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.PAYMENT_SORT_VALUE, value)
    this.setState({ page: 1, sortValue: value }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onPaymentSearchChange(search) {
    this.setState({ search: search })
  }

  onPaymentSearchSubmit(search) {
    this.setState({ search: search }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onPaymentClearFilters() {
    this.setState({ page: 1, search: '', activeFilters: {} }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  onResourceBulkPaymentActionClick() {
    const { t } = this.props
    const { payments, selectedPaymentIds } = this.state

    const selectedPayments = payments
      .filter(payment => selectedPaymentIds.includes(payment.id) && !payment.payment_initiation)

    if (selectedPayments.length === 0) {
      this.props.showConfirmModal({
        title: t('Payments::No payable payments selected'),
        description: t('Payments::The payments you selected are already paid or queued for payment'),
        action: {
          label: t('Payments::I understand'),
        },
        onConfirm: () => { }
      })
    } else {
      this.props.showPaymentInitiationModal({
        paymentInitiation: {
          payments: selectedPayments
        },
        onDelete: (paymentInitiation) => {
          payments.forEach(payment => {
            if (payment.payment_initiation_id === paymentInitiation.id) {
              payment.execution_date = null
              payment.payment_initiation = null
              payment.payment_initiation_id = null
            }
          })

          this.setState({ payments: [...payments] })
        }
      })
    }
  }

  onResourceBulkDeleteActionClick() {
    const { t } = this.props
    const { selectedPaymentIds, payments } = this.state

    this.props.showConfirmModal({
      title: t('Payments::Bulk payment deletion'),
      description: t('Payments::You are about to delete these payments. By deleting these payments you are also deleting all its associated data. Are you sure?'),
      action: { label: t('Payments::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await PaymentsController.deleteAll(selectedPaymentIds)
          if (response.errors) { }
          else {
            const newPayments = payments.filter(p => !selectedPaymentIds.includes(p.id));

            this.setState({ payments: [...newPayments], selectedPaymentIds: [] });

            Notification.notifySuccess(t('Payments::Payments successfully deleted'))
          }

        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onPageChange(page: number) {
    this.setState({ page: page }, () => {
      const { page, activeFilters, search, sortValue } = this.state
      RouteHelper.replace(this.props.location.pathname as ERoute, { page: page, search: search, ...activeFilters, sortValue: sortValue })
    })
  }

  render() {
    const { t, currentUser } = this.props
    const { setting } = currentUser.workspace
    const {
      payments,
      selectedPaymentIds,
      didInitialLoad,
      isFetching,
      activeFilters,
      sortValue,
      summaryDidInitialLoad,
      summary,
      search,
      page,
      totalPages
    } = this.state

    const filtersActive = search?.length > 0 || Object.keys(activeFilters).length > 0
    const filters: PaymentFilter[] = [
      { name: 'creditor_name', label: t('Payments::Name'), type: ResourceListFilterType.STRING },
      { name: 'creditor_account', label: t('Payments::Account'), type: ResourceListFilterType.STRING },
      {
        name: 'status',
        label: t('Payments::Status'), type: ResourceListFilterType.SINGLE_OPTION,
        options: [
          { label: t(`PaymentInitiationStatus::${PaymentInitiationStatus.DRAFT}`), value: PaymentInitiationStatus.DRAFT },
          { label: t(`PaymentInitiationStatus::${PaymentInitiationStatus.UNSIGNED}`), value: PaymentInitiationStatus.UNSIGNED },
          { label: t(`PaymentInitiationStatus::${PaymentInitiationStatus.SIGNED}`), value: PaymentInitiationStatus.SIGNED },
        ]
      },
      { name: 'remittance_information', label: t('Payments::Remittance information'), type: ResourceListFilterType.STRING },
      { name: 'execution_date', label: t('Payments::Execution date'), type: ResourceListFilterType.DATE },
      { name: 'amount', label: t('Payments::Amount'), type: ResourceListFilterType.NUMBER },
      { name: 'created_at', label: t('Payments::Created date'), type: ResourceListFilterType.DATE },
    ]

    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'money-bill', content: t('Payments::Schedule payment'), onAction: this.onResourceBulkPaymentActionClick }
    ]

    const bulkActions: ResourceTableAction[] = [
      { icon: 'trash-alt-solid', content: t('Payments::Delete'), onAction: this.onResourceBulkDeleteActionClick, destructive: true }
    ]

    return (
      <>
        <Helmet>
          <title>{t('Payments::{{__appName}} | Payments')}</title>
        </Helmet>

        <TopNavigation
          icon='money-bill'
          title={t('Payments::Payments')}
          action={
            <a key='new-payment' href='javascript://' className='button button-primary page-action' onClick={this.onNewPaymentClick}>
              <Icon icon='plus' />
              {t('Expenses::New Payment')}
            </a>
          }
        />

        <ScrollToTopOnMount />
        <NavigationDetection onRouteChange={this.onRouteChange} />

        <PageContent className='is-payments'>
          <PageHeader title={t('Payments::Payments')} />

          <SummaryContainer columnCount={3} style={{ marginBottom: Style.spacing.x2 }}>
            <SummaryItem
              title={t('InvoiceSummary::Draft payments')}
              label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, summary?.draft_amount || 0)}
              isLoading={!summaryDidInitialLoad}
            />
            <SummaryItem
              title={t('InvoiceSummary::Unsigned payments')}
              label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, summary?.unsigned_amount || 0)}
              isLoading={!summaryDidInitialLoad}
            />
            <SummaryItem
              title={t('InvoiceSummary::Paid this month')}
              label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, summary?.month_amount || 0)}
              isLoading={!summaryDidInitialLoad}
            />
          </SummaryContainer>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ResourceTable
            data={payments}
            headers={[
              { title: t('Payments::Counterpart name') },
              { title: t('Payments::Account number') },
              { title: t('Payments::Status') },
              { title: t('Payments::Remittance information'), align: 'right' },
              { title: t('Payments::Execution date'), align: 'right' },
              { title: t('Payments::Amount'), align: 'right' },
              { title: '', stickyRight: '0px' },
            ]}
            renderRow={(payment: Payment) => {
              return (
                <ResourceTableRow
                  key={payment.id}
                  selected={selectedPaymentIds.includes(payment.id)}
                  onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, payment.id)}
                >
                  <ResourceTableRowData onClick={() => this.onTablePaymentClick(payment)} maxWidth='150px' ellipse>
                    {payment.creditor_name}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTablePaymentClick(payment)}>
                    {payment.creditor_account}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTablePaymentClick(payment)}>
                    {PaymentHelper.getBadge(payment)}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTablePaymentClick(payment)} textAlign='right'>
                    {payment.remittance_information.length === 0 && '-'}
                    {payment.remittance_information.length > 0 && payment.remittance_information_type === RemittanceInformationType.STRUCTURED ? OgmHelper.format(payment.remittance_information) : payment.remittance_information}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTablePaymentInitiationClick(payment)} textAlign='right'>
                    <ResourceTableRowDataLink>
                      {payment.payment_initiation ? moment(payment.payment_initiation.execution_date).format(setting.date_format) : t('Payments::Schedule')}
                    </ResourceTableRowDataLink>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTablePaymentClick(payment)} textAlign='right'>
                    {NumberFormatter.formatCurrency(payment.currency, setting.number_format, payment.amount)}
                  </ResourceTableRowData>

                  <ResourceTableRowActions
                    actions={[
                      { key: 'view_payment', icon: 'eye', content: t('Payments::View payment'), visible: Boolean(payment.payment_initiation) },
                      { key: 'schedule_payment', icon: 'money-bill', content: t('Payments::Pay now'), visible: !Boolean(payment.payment_initiation) },
                      { key: 'sign_payment', icon: 'file-contract', content: t('Payments::Approve payment'), visible: payment?.payment_initiation?.status === PaymentInitiationStatus.UNSIGNED },
                      { key: 'update', icon: 'edit-solid', content: t('Payments::Edit') },
                      { key: 'delete', icon: 'trash-alt-solid', content: t('Payments::Delete'), destructive: true }
                    ]}
                    onActionClick={(key) => this.onTableActionClick(key, payment)}
                    sticky={true}
                    stickyRight='0px'
                  />
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'money-bill'}
              description={filtersActive ? t('Payments::No payments found') : t('Payments::No payments have been created yet')}
              descriptionActionText={filtersActive ? t('Payments::Clear filters') : t('Payments::Add new payment')}
              onDescriptionActionClick={filtersActive ? this.onPaymentClearFilters : this.onNewPaymentClick}
            />}
            filters={filters}
            activeFilters={activeFilters}
            onFiltersChange={this.onPaymentFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('Payments::Name (A-Z)'), value: 'creditor_name_asc' },
              { label: t('Payments::Name (Z-A)'), value: 'creditor_name_desc' },
              { label: t('Payments::Execution date ↑'), value: 'payment_initiations.execution_date_asc' },
              { label: t('Payments::Execution date ↓'), value: 'payment_initiations.execution_date_desc' },
              { label: t('Payments::Created at ↑'), value: 'created_at_asc' },
              { label: t('Payments::Created at ↓'), value: 'created_at_desc' },
            ]}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            sortValue={sortValue}
            onSortChange={this.onPaymentSortValueChange}
            pagination={{ page: page, pageCount: totalPages }}
            onPageChange={this.onPageChange}
            isLoading={isFetching}
            stickyHeader={true}
            selectedItems={selectedPaymentIds}
            onSelectionChange={this.onTableSelectionChange}
            searchValue={search}
            onSearchChange={this.onPaymentSearchChange}
            onSearchSubmit={this.onPaymentSearchSubmit}
            maxHeight='55vh'
          />}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showPaymentModal: (options) => dispatch(showPaymentModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showPaymentInitiationModal: (options) => dispatch(showPaymentInitiationModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Payments))