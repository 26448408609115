import { Api } from ".";
import SearchHelper from "../helpers/SearchHelper";
import {
  ContentBlockTemplate,
  ContentBlockBase,
  ContentBlockResource,
} from "../types";
import Utils from "../utilities/Utils";

export default class ResourceContentBlocksController extends Api {
  static getContentBlockTemplate(id: string) {
    return fetch(`/api/content_block_templates/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getContentBlockTemplates(params: object) {
    return fetch(
      `/api/content_block_templates?${SearchHelper.getQueryString(params)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static create(
    contentBlockTemplate: ContentBlockTemplate,
    contentBlockId: string
  ) {
    return fetch(`/api/content_block_templates`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        content_block_template: contentBlockTemplate,
        content_block_id: contentBlockId,
      }),
    }).then((res) => res.json());
  }

  static update(contentBlockTemplate: ContentBlockTemplate) {
    return fetch(`/api/content_block_templates/${contentBlockTemplate.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ content_block_template: contentBlockTemplate }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/content_block_templates/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(
      `/api/content_block_templates/form?${Utils.encodeQueryData(params)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }
}
