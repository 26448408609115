import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Icon from '../Icons/Icon'
import { usePopper } from 'react-popper'
import FilterCheckbox from '../Checkbox/FilterCheckbox'
import ResourceFilterInput from './ResourceFilterInput'
import { ResourceFilter, ResourceListFilterPredicate, ResourceListFilterType } from '../../types'

const Container = styled.div`
	position: relative;
	display: flex;
	flex: 1 1 0%;
	align-items: center;
	left: auto;
	flex: 0 1 auto;

	&:only-child {
		margin-left: 0;
		flex: 1;
		display: flex;
    justify-content: flex-end;
	}
`

const Wrapper = styled.div`
	position: relative;
  cursor: pointer;
`

const Content = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;
	letter-spacing: normal;
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 5px 8px 5px 12px;
	min-height: 38px;
`

const FilterInlineLabel = styled.div`
    color: #637381;
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
`

const FilterInlineDivider = styled.div`
	width: 1px;
	height: 100%;
	background-color: ${Style.color.border};
	margin-left: ${Style.spacing.x0_5};
	margin-right: ${Style.spacing.x1};
	min-height: 25px;
`

const FilterInlineValue = styled.div`
	white-space: nowrap;
	overflow: hidden;
	font-weight: bolder;
  margin-right: ${Style.spacing.x0_5};
`

const WrapperIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: ${Style.spacing.x1};

	svg {
		width: 16px;
		height: 16px;
	}
`

const WrapperBackdrop = styled.div`
	background-color: #c4cdd5;
	border: 1px solid transparent;
	box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
	border-radius: 3px;
	transition-property: box-shadow,background-color;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.64,0,.35,1);
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&::after {
		content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background: linear-gradient(180deg, #fff, #f9fafb);
    border-radius: 2px;
	}
`

const FilterContainer = styled.div`
  margin: ${Style.spacing.x1};
  transition: all ${Style.animations.baseTransitionSpeed} linear;
  z-index: 5;
`

const FilterWrapper = styled.form<{ active: boolean }>`
  position: relative;
  z-index: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%);
  min-width: 250px;
  transition: all ${Style.animations.baseTransitionSpeed} linear;
  transform: translateY(-10px);

  ${props => props.active && css`
    transform: translateY(0px);
  `}
`

const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Style.spacing.x1};
  background: #f7fafc;
  justify-content: space-between;
  box-shadow: inset 0 -1px #e3e8ee;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

const FilterHeaderButton = styled.div<{ type?: 'default' | 'primary' }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  cursor: pointer;
  min-height: 24px;
  border-radius: 4px;
  max-width: 100%;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: rgb(60, 66, 87);
  padding: 4px 8px;

  ${props => props.type === 'primary' && css`
    background: ${Style.color.brandPrimary};
    color: white;
    box-shadow: initial;
  `}
`

const FilterContent = styled.div``

const FilterItems = styled.div`
  display: flex;
  flex-direction: column;
`

const FilterItem = styled.div`
  box-shadow: inset 0 -1px #e3e8ee;

  &:last-child {
    box-shadow: none;
  }
`

const FilterItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Style.spacing.x1};
  color: #3c4257;
  font-weight: 500;
  cursor: pointer;
`

const FilterItemContent = styled.div`
  padding: 12px;
  background: #f7fafc;
  box-shadow: inset 0 1px #e3e8ee, inset 0 -1px #e3e8ee;
`

interface IProps<T> {
  filters: ResourceFilter<T>[]
  activeFilters?: any
  onSubmit: (filters: any) => void
}

const ResourceFilterComponent = (props: IProps<any>) => {
  const { t } = useTranslation()
  const container = React.useRef<HTMLDivElement>()
  const [active, setActive] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState({});
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [{ name: 'arrow' }],
  });
  const { filters, activeFilters: propsActiveFilters } = props
  const activeFilterCount = Object.keys(Object(activeFilters))?.length || 0

  React.useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', onOutsideClick);
    } else {
      document.removeEventListener('mousedown', onOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', onOutsideClick);
    }
  }, [active])

  React.useEffect(() => {
    if (propsActiveFilters) { setActiveFilters(propsActiveFilters) }
  }, [propsActiveFilters])

  const onToggleFilter = () => {
    setActive(!active)
  }

  const onOutsideClick = (e) => {
    if (active && container && !container.current.contains(e.target)) {
      setActive(false)
    }
  }

  const onClearClick = () => {
    // Hide filter
    setActive(false)

    // Reset filter values
    setActiveFilters({})

    // Submit
    if (props.onSubmit) props.onSubmit({})
  }

  const onDoneClick = (e) => {
    // Prevent form submission
    e.preventDefault()

    // Hide filter
    setActive(false)

    // Submit filter values to parent
    if (props.onSubmit) props.onSubmit(activeFilters)
  }

  const setPredicateDefaultValues = (index: number) => {
    const selectedFilter = filters[index]
    const selectedFilterName = selectedFilter.name
    const updatedFilterValues = { ...activeFilters }

    switch (selectedFilter.type) {
      case ResourceListFilterType.STRING:
        updatedFilterValues[selectedFilterName] = ''
        break
      case ResourceListFilterType.SINGLE_OPTION:
        updatedFilterValues[selectedFilterName] = selectedFilter.options[0].value
        break
      case ResourceListFilterType.MULTIPLE_OPTION:
        updatedFilterValues[selectedFilterName] = { in: [] }
        break
      case ResourceListFilterType.DATE:
        updatedFilterValues[selectedFilterName] = ''
        break
      case ResourceListFilterType.BOOLEAN:
        updatedFilterValues[selectedFilterName] = false
        break
      case ResourceListFilterType.SINGLE_RESOURCE:
        updatedFilterValues[selectedFilterName] = null
        break
      case ResourceListFilterType.MULTI_RESOURCE:
        updatedFilterValues[selectedFilterName] = []
        break
      case ResourceListFilterType.NUMBER:
        updatedFilterValues[selectedFilterName] = ''
        break
      default: throw new Error('[setPredicateDefaultValues] Invalid filter type')
    }

    setActiveFilters(updatedFilterValues)
  }

  const onToggleFilterItem = (index: number) => {
    const selectedFilter = filters[index]
    const selectedFilterName = selectedFilter.name
    const updatedFilterValues = { ...activeFilters }

    if (activeFilters && selectedFilterName in activeFilters) {
      // Remove filter name and filter name with predicates from filter values on deactivate
      delete updatedFilterValues[selectedFilterName]

      // Set filter values
      setActiveFilters(updatedFilterValues)
    } else {
      // Set default value on active filter toggle
      setPredicateDefaultValues(index)
    }
  }

  const onFilterValueChange = (name: string, value: any) => {
    setActiveFilters({ ...activeFilters, [name]: value, })
  }

  return (
    <Container ref={container}>
      <Wrapper ref={setReferenceElement} onClick={onToggleFilter}>
        <Content>
          <WrapperIcon>
            <Icon icon='filter' />
          </WrapperIcon>
          <FilterInlineLabel>{t('ResourceListFilter::Filter')}</FilterInlineLabel>
          {activeFilterCount > 0 && <>
            <FilterInlineDivider></FilterInlineDivider>
            <FilterInlineValue>{t('ResourceListFilter::{{count}}', { count: activeFilterCount })}</FilterInlineValue>
          </>}
        </Content>
        <WrapperBackdrop />
      </Wrapper>

      <FilterContainer
        ref={setPopperElement}
        style={{
          ...styles.popper,
          visibility: active ? 'visible' : 'hidden',
          pointerEvents: active ? 'all' : 'none',
          opacity: active ? 1 : 0,
          zIndex: 16,
        }}
        {...attributes.popper}
      >
        <FilterWrapper active={active} onSubmit={onDoneClick}>
          <FilterHeader>
            <FilterHeaderButton onClick={onClearClick}>{t('ResourceListFilter::Clear')}</FilterHeaderButton>
            {t('ResourceListFilter::Filters')}
            <FilterHeaderButton type='primary' onClick={onDoneClick}>{t('ResourceListFilter::Done')}</FilterHeaderButton>
          </FilterHeader>
          <FilterContent>
            <FilterItems>
              {filters.map((filter, index) => {
                if (filter.visible === false) return null

                const active = activeFilters && filter.name in activeFilters
                const value = (activeFilters && activeFilters[filter.name])

                return (
                  <FilterItem key={filter.name}>
                    <FilterItemHeader onClick={() => onToggleFilterItem(index)}>
                      <FilterCheckbox checked={active} onChange={() => { }} />
                      {filter.label}
                    </FilterItemHeader>
                    {active && <FilterItemContent>
                      <ResourceFilterInput
                        filter={filter}
                        value={value}
                        onFilterValueChange={onFilterValueChange}
                      />
                    </FilterItemContent>}
                  </FilterItem>
                )
              })}
            </FilterItems>
          </FilterContent>
          <input type='submit' style={{ display: 'none' }} />
        </FilterWrapper>
      </FilterContainer>
    </Container>
  )
}

export default ResourceFilterComponent