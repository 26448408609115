import Api from "./Api";
import Utils from "../utilities/Utils";
import SearchHelper from "../helpers/SearchHelper";

export default class UsersController extends Api {
  static getUser(id: string) {
    return fetch(`/api/users/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getUsers(params: object) {
    return fetch(`/api/users?${SearchHelper.getQueryString(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
