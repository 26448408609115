import Api from "./Api";
import Utils from "../utilities/Utils";
import { SequenceEnrollment } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class SequenceEnrollmentsController extends Api {
  static getSequenceEnrollment(sequenceId: string, id: string) {
    return fetch(`/api/sequences/${sequenceId}/enrollments/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getSequenceEnrollments(sequenceId: string, params: object) {
    return fetch(
      `/api/sequences/${sequenceId}/enrollments?${SearchHelper.getQueryString(
        params
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static getForm(sequenceId: string) {
    return fetch(`/api/sequences/${sequenceId}/enrollments/form`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
