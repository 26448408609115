import Api from "./Api";
import Utils from "../utilities/Utils";
import { CalendarEvent } from "../types";
import SearchHelper from "../helpers/SearchHelper";

export default class CalendarEventsController extends Api {
  static getEvents(params: object) {
    return fetch(
      `/api/calendar_events?${SearchHelper.getQueryString(params)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static create(calendarEvent: CalendarEvent) {
    return fetch("/api/calendar_events", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ calendar_event: calendarEvent }),
    }).then((res) => res.json());
  }

  static update(calendarEvent: CalendarEvent) {
    return fetch(`/api/calendar_events/${calendarEvent.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ calendar_event: calendarEvent }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/calendar_events/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/calendar_events/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
